export class Interest {
  id: number;
  tribeId: number;
  title: string;
  viewOrder: number;
  created: Date;

  constructor() {
  }
}
