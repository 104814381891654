import { Component, OnInit, Input, ViewChild, Renderer2, OnDestroy } from '@angular/core';
import { MessageService } from '../../core/services/message.service';
import { ProfileService } from '../../core/services/profile.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Profile } from '../../models/Profile';
import { Message } from '../../models/Message';
import { TitleEventsEmitter } from '../../core/events/title.events';
import { interval, Observer, Observable } from 'rxjs';
import { take, filter, map } from 'rxjs/operators';
import * as moment from 'moment';

import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Media } from '../../models/Media';
import { HttpRequest, HttpClient, HttpResponse } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { animations } from 'ack-angular-fx';
import { BaseComponent } from '../shared/base-component/base-component.component';
import { MessagesThreadComponent } from './message-thread/message-thread.component';
import { ActivatedRoute } from '@angular/router';
import { FilterSettings } from '../../models/shared/grids/filterSettings';
//import { IPageInfo } from 'ngx-virtual-scroller';

@Component({
  selector: 'app-messages',
  styleUrls: ['./messages.component.css'],
  templateUrl: './messages.component.html',
  animations: [
    animations
  ]
})
export class MessagesComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild('messageThread') messageThread: MessagesThreadComponent;

  public currentCount = 0;
  filterSettings: FilterSettings;
  private messages: Message[] = [];
  private profiles: Profile[] = [];
  public items: any[] = [];
  toProfileId: any;
  selectedItem: any;
  searchPhrase = null;
  profileSearchResults: Profile[];
  nzFilterOption = () => true;

  msg: Message = new Message();
  actionUrl = "https://www.mocky.io/v2/5cc8019d300000980a055e76";

  state$: Observable<object>;
  page: number = 0;

  constructor(
    private messageService: MessageService,
    private profileService: ProfileService,
    private titleEventsEmitter: TitleEventsEmitter,
    private route: ActivatedRoute,
    private renderer: Renderer2
  ) {
    super();
    this.filterSettings = new FilterSettings();
    //this.state$ = this.activatedRoute.paramMap
    //  .pipe(map(() => {
    //    return window.history.state;
    //  }));

    this.route.params.subscribe(params => {
      //this.ngProgress.start();
      this.toProfileId = this.route.snapshot.queryParamMap.get('to');
      if (this.toProfileId?.toString().length > 0) {
        this.nav('/messages');
        // interval(500).pipe(take(1)).subscribe(x => {
        //   this.selectProfile(+this.toProfileId);
        // });
      }
    });
  }

  //_messageHubConnection: signalR.HubConnection;

  ngOnInit() {
    this.titleEventsEmitter.broadcastEventTitleChanged("Messages");
    //window.addEventListener('scroll', this.onScroll, true);
    this.load();
  }

  ngOnDestroy() {
    //window.removeEventListener('scroll', this.onScroll, true);
  }

  onScroll(e) {
    this.load();
    this.page++;

  }



  load() {
    this.isLoading = true;
    this.setLoading(true, 'Loading...')
    const pageSize = 20;
    const excludingProfileIds = this.profiles.map(x => x.id);
    this.subscriptions.push(
      this.messageService.GetInboxDistinct(this.currentProfile.id, this.page, pageSize, excludingProfileIds).subscribe(data => {
        this.messages = [...this.messages, ...data];
        this.bind();
      })
    );
  }


  calculatePageIndex(pageSize) {
    const totalRecordLoaded = this.messages.length;
    if (totalRecordLoaded === 0) {
      return 0;
    }

    const pageIndex = Math.floor(totalRecordLoaded / pageSize);
    return pageIndex;
  }

  bind() {
    this.items = [];
    this.messages = this.sortByCreatedDate(this.messages);
    this.profiles = this.getUniqueProfiles(this.messages);

    if (this.toProfileId?.toString().length > 0) {
      this.profileService.Get(+this.toProfileId).subscribe(profileData => {
        this.profiles.push(profileData);
        this.postBind();
      });
    } else {
      this.postBind();
    }
  }

  postBind() {
    this.profiles = Array.from(new Set(this.profiles.map(item => item.id)))
      .map(id => this.profiles.find(profile => profile.id === id))
      .filter(profile => profile && profile.id !== this.currentProfile.id);

    this.items = this.profiles.map(profile => ({
      profile,
      lastMessage: this.getLastMessage(this.messages, profile.id, this.currentProfile.id),
      messages: this.filterMessages(this.messages, profile.id, this.currentProfile.id),
      unread: this.hasUnreadMessages(this.messages, profile.id, this.currentProfile.id),
    }));

    for (const item of this.items) {
      item.messages = this.setDateTextForMessages(item.messages);
    }

    this.items = this.sortByLastMessageCreatedDate(this.items);

    if (this.selectedItem) {
      this.selectItem(this.selectedItem);
    }

    this.setLoading(false);
  }

  sortByCreatedDate(messages: Message[]) {
    return messages.sort((a, b) => (a.created < b.created ? 1 : -1));
  }

  getUniqueProfiles(messages: Message[]) {
    return [...new Set([...messages.map(msg => msg.fromProfile), ...messages.map(msg => msg.toProfile)])].filter(profile => profile !== null);
  }

  getLastMessage(messages: Message[], profileId: number, currentProfileId: number) {
    return messages
      .sort((a, b) => (a.created < b.created ? 1 : -1))
      .find(msg => +Message.otherId(msg, currentProfileId) === profileId);
  }

  filterMessages(messages: Message[], profileId: number, currentProfileId: number) {
    return messages.filter(
      msg =>
        (msg.fromProfileId === currentProfileId && msg.toProfileId === profileId) ||
        (msg.toProfileId === currentProfileId && msg.fromProfileId === profileId)
    );
  }

  hasUnreadMessages(messages: Message[], profileId: number, currentProfileId: number) {
    return messages.filter(msg => msg.toProfileId === currentProfileId && msg.fromProfileId === profileId && msg.status === 1).length > 0;
  }

  setDateTextForMessages(messages: any[]) {
    const today = moment(new Date());
    const yesterday = moment(new Date()).subtract(1, 'day');
    let curCreated = null;

    for (const msg of messages) {
      const created = moment(msg.created);
      if (!curCreated || !curCreated.isSame(created, 'day')) {
        curCreated = created;
        msg.dateText = today.isSame(curCreated, 'day')
          ? 'TODAY'
          : yesterday.isSame(curCreated, 'day')
            ? 'YESTERDAY'
            : curCreated.format('DD-MM-YYYY');
      }
    }

    return messages;
  }

  sortByLastMessageCreatedDate(items: { profile: any, lastMessage: Message, messages: Message[], unread: boolean }[]) {
    return items.sort((a, b) => (a.lastMessage?.created < b.lastMessage?.created ? 1 : -1));
  }


  searchProfiles(value: string): void {
    if (value.length > 0) {
      this.isLoading = true;
      this.filterSettings.keyword = value;
      this.filterSettings.showApproved = true;
      this.profileService.GetForFilterSettings(this.filterSettings, 0, 10, "", 1)
        .subscribe(data => {
          this.profileSearchResults = data.filter(x => x.id != this.currentProfile.id);
          this.isLoading = false;
        });
    }
  }

  onSearchChanged(value: number) {
    this.selectProfile(value);
  }

  selectProfile(id) {
    this.isLoading = true;
    if (this.profiles == null) this.profiles = [];
    if (this.items == null) this.items = [];
    let item = this.items.find(x => x.profile.id == id);
    if (!item) {
      this.profileService.Get(id).subscribe(data => {
        this.profiles.push(data);
        item = {
          profile: data,
          lastMessage: null,
          messages: []
        };
        this.items = [item].concat(this.items);//.push(item);
        this.selectItem(item);
      });
    } else {
      this.selectItem(item);
    }
  }

  selectItem(item: any) {
    if (!item)
      return;
    this.isLoading = true;
    console.log(item);
    this.messageService.GetThread(item.profile.id, this.currentProfile.id).subscribe(p => {
      let msgs = {
        profile: item.profile,
        lastMessage: p.sort((a, b) => (a.created < b.created) ? 1 : -1).find(y => +Message.otherId(y, this.currentProfile.id) == item.profile.id),
        messages: p.filter(m => (m.fromProfileId == this.currentProfile.id && m.toProfileId == item.profile.id) || (m.toProfileId == this.currentProfile.id && m.fromProfileId == item.profile.id)),
        unread: p.filter(m => (m.toProfileId == this.currentProfile.id && m.fromProfileId == item.profile.id)).filter(x => x.status == 1).length > 0
      }
      interval(250).pipe(take(1)).subscribe(x => {
        this.searchPhrase = null;
        this.selectedItem = msgs;
        //this.messageThread.selectedItem = this.selectedItem;
        //this.messageThread.bindSelectedItem();
        this.isLoading = false;
      });
    });





  }

  update($event) {
    this.load();
  }

  deleteChatHistory() {
    let updateProfileIds = [this.currentProfile.id];
    let count = this.selectedItem.messages.length;
    this.selectedItem.messages.forEach(m => {
      let otherId = (m.fromProfileId == this.currentProfile.id ? m.toProfileId : m.fromProfileId);
      if (!updateProfileIds.find(x => x == otherId)) updateProfileIds.push(otherId);
      this.messageService.Delete(m.id).subscribe(data => {
        count--;
        if (count == 0) {
          this.selectedItem = null;
          this.messageService.sendInboxUpdates(this.messageService._messageHubConnection, updateProfileIds);
          this.load();
        }
      });
    });
  }

  cancel() {
  }

  unselect() {
    // this.selectItem(null);
    console.log(this.selectItem, 'slelecred..')
    this.selectedItem = null;
  }
  //addToInbox(obj: Message) {
  //  this.messages.push(obj);
  //  this.globalService.playAudio('message');
  //}

  goToProfile(p: Profile) {
    this.nav('/members/' + p.id);
  }

  getImageUrl = (p: Profile): string => { return this.globalService.getProfileImageUrl(p, 256); }

  getTimeRelative(d: string) {
    d += 'Z';
    let m = moment(d);
    if (m.isSame(moment(new Date()), 'd')) {
      return m.local(true).format("HH:mm");
    }
    else {
      return m.fromNow();
    }
  }


  sendTestMessage() {
    let fromProfileId = this.profiles[Math.floor(this.profiles.length * Math.random())].id;

    let m = new Message();
    m.content = "new message " + (new Date()).toString();
    m.fromProfileId = fromProfileId;
    m.toProfileId = this.currentProfile.id;
    m.messageType = 1;
    m.status = 1;
    m.created = new Date();
    this.messageService.Add(m).subscribe(data => {
      //this.messageService.sendMessage(data);
      //this.messageSendService.broadcastMessage(data);                   // Send the message via a service
    });
  }



}
