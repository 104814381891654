import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Profile } from '../../models/Profile';
import { LoginEventsEmitter } from '../../core/events/login.events';
import { TitleEventsEmitter } from '../../core/events/title.events';
import { BaseComponent } from '../shared/base-component/base-component.component';

interface SavedCredentials {
  emailAddress: string;
  password: string;
  remember: boolean;
}


@Component({
  selector: 'app-login',
  styleUrls: ['./login.component.css'],
  templateUrl: './login.component.html',
})
export class LoginComponent extends BaseComponent implements OnInit, OnDestroy {
  returnUrl: string;
  isLoggingIn: boolean = false;
  validateForm!: UntypedFormGroup;
  error: string;
  private readonly CREDENTIALS_KEY = 'savedLoginCredentials';
  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private titleEventsEmitter: TitleEventsEmitter,
    private loginEventsEmitter: LoginEventsEmitter,
  ) {
    super();
  }

  ngOnInit() {
    this.titleEventsEmitter.broadcastEventTitleChanged("Login");
    this.validateForm = this.fb.group({
      emailAddress: [null, [Validators.required]],
      password: [null, [Validators.required]],
      remember: [true]
    });
    this.loadSavedCredentials();
    this.setLoading(false);
    this.validateForm.get('remember')?.valueChanges.subscribe(remember => {
      if (!remember) {
        this.clearSavedCredentials();
      }
    });
  }

  openLinkInNewTab(): void {
    const url = 'https://the-otto-connection.smartmatchapp.com/client/submissionform/17/';
    window.open(url, '_blank');
  }

  submitForm(): void {
    this.authService.login(this.validateForm.controls['emailAddress'].value, this.validateForm.controls['password'].value).subscribe(
      data => {
        let mProfile: Profile = data;
        if (mProfile != null && mProfile.token) {
          this.saveCredentials();
          this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
          this.loginEventsEmitter.broadcastEventProfileLoginChanged(mProfile);
          if (this.returnUrl == undefined) {
            this.returnUrl = '/';
          }

          this.gaService.event(this.analytics.auth.login, this.analytics.auth.category, mProfile.id.toString())

          this.router.navigate([this.returnUrl]);

        }
      },
      error => {
        if (error.status == 404) {
          this.error = "Email or password not found"
        } else {
          this.error = "Error logging in"
        }
        this.notify("error", this.error);
      });
  }

  private loadSavedCredentials(): void {
    const savedCredentials = localStorage.getItem(this.CREDENTIALS_KEY);
    if (savedCredentials) {
      const credentials: SavedCredentials = JSON.parse(savedCredentials);
      this.validateForm.patchValue({
        emailAddress: credentials.emailAddress,
        password: credentials.password,
        remember: credentials.remember
      });

      if (!credentials.remember) {
        this.clearSavedCredentials();
      }
    }
  }

  private saveCredentials(): void {
    if (this.validateForm.value.remember) {
      const credentials: SavedCredentials = {
        emailAddress: this.validateForm.value.emailAddress,
        password: this.validateForm.value.password,
        remember: true
      };
      localStorage.setItem(this.CREDENTIALS_KEY, JSON.stringify(credentials));
    } else {
      this.clearSavedCredentials();
    }
  }

  private clearSavedCredentials(): void {
    localStorage.removeItem(this.CREDENTIALS_KEY);
  }
}
