import { Component, OnInit, OnDestroy } from '@angular/core';
import { GridSettings } from '../../../models/shared/grids/gridSettings';
import { Tribe } from '../../../models/Tribe';
import { TribeService } from '../../../core/services/tribe.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { NzMessageService } from 'ng-zorro-antd/message';
import { BaseComponent } from '../../shared/base-component/base-component.component';
import { Router } from '@angular/router';
import { GlobalService } from '../../../core/services/global.service';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { ProfileService } from '../../../core/services/profile.service';

@Component({
  selector: 'admin-tribes',
  styleUrls: ['./tribes.component.css'],
  templateUrl: './tribes.component.html',
})
export class AdminTribesComponent extends BaseComponent implements OnInit, OnDestroy {
  public items: Tribe[];
  public newTribe: Tribe;
  gridSettings: GridSettings;
 
  total = 1;

  validateForm!: UntypedFormGroup;

  constructor(
    router: Router,
    globalService: GlobalService,
    authenticationService: AuthenticationService,
    private tribeService: TribeService,
    private profileService: ProfileService,
    private fb: UntypedFormBuilder,
    private nzMessageService: NzMessageService
  ) {
    super();

    this.gridSettings = new GridSettings();
    this.gridSettings.sortField = "Created";
    this.gridSettings.sortAsc = true;
    this.gridSettings.pageSize = 50;
    this.gridSettings.pageIndex = 0;
  }

  ngOnInit() {
    this.validateForm = this.fb.group({
      formLayout: ['inline'],
      title: [null, [Validators.required]],
            founder: [null, [Validators.required]]
    });

    this.loadData();
  }

  loadData() {
    this.tribeService.GetAll("", this.gridSettings.pageIndex, this.gridSettings.pageSize, this.gridSettings.sortField, this.gridSettings.sortAsc ? 1 : -1).subscribe(data => {
      this.items = data;
      this.isLoading = false;
    })
  }

  //drop(event: CdkDragDrop<string[]>): void {
    

  //  let remaining = this.items.length;
  //  for (let i = 0; i < this.items.length; i++) {
  //    if (i == event.previousIndex) {
  //      this.items[i].viewOrder = event.currentIndex;
  //    } else if (i == event.currentIndex) {
  //      this.items[i].viewOrder = event.previousIndex;
  //    } else {
  //      this.items[i].viewOrder = i;
  //    }
  //    this.tribeService.Update(this.items[i]).subscribe(data => {
  //      remaining--;
  //      if (remaining == 0) {
  //        this.loadData();
  //        this.nzMessageService.info('Updated order.');
  //      }
  //    });
  //  }

  //  //moveItemInArray(this.listOfData, event.previousIndex, event.currentIndex);
  //}

  submitForm(): void {

  
     


    let item = new Tribe();
    item.title = this.validateForm.controls['title'].value;
    this.tribeService.Add(item).subscribe(data => {
      this.newTribe = data;
      this.setLoading(true);
      this.profileService.InviteTribeFounder(this.validateForm.controls['founder'].value, 0, this.newTribe.id).subscribe(data => {
        this.validateForm.controls['founder'].setValue('');
        this.msgService.success('Invitation sent');


      },
        error => {
          this.msgService.error(error);
          this.setLoading(false);
        }
      );
      this.loadData();
    });
    //for (const i in this.validateForm.controls) {
    //  this.validateForm.controls[i].markAsDirty();
    //  this.validateForm.controls[i].updateValueAndValidity();
    //}
    this.setLoading(false);
  }

  delete(item: Tribe) {
    this.tribeService.Delete(item.id).subscribe(data => {
      this.nzMessageService.info('Deleted tribe.');
      this.loadData();
    });
  }
  cancelDelete(): void {
    this.nzMessageService.info('Cancelled delete.');
  }

  hasMembers(id: number) {
   
    return this.tribeService.HasMembers(id);
  }
}



//interface DataItem {
//  name: string;
//  age: number;
//  address: string;
//}
