import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Profile } from '../../../models/Profile';
import { Router } from '@angular/router';
import { GlobalService } from '../../../core/services/global.service';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { BaseComponent } from '../../shared/base-component/base-component.component';
import { ProfileService } from '../../../core/services/profile.service';

@Component({
    selector: 'app-registration-reg-5-verifyProfile',
    styleUrls: ['./reg-5-verifyProfile.component.css'],
    templateUrl: './reg-5-verifyProfile.component.html',
})
export class Reg5VerifyProfileComponent extends BaseComponent implements OnInit, OnDestroy {
    @Input() step: number;
    

    public profiles: Profile[];

    constructor(
        router: Router,
        globalService: GlobalService,
      authenticationService: AuthenticationService,
      private profileService: ProfileService
        //private contactService: ContactService
    ) {
        super();
    }

  ngOnInit() {
    this.currentProfile = this.authenticationService.getCurrentProfile();

    localStorage.setItem('currentProfile', JSON.stringify(this.currentProfile));
    if (this.currentProfile.status == 2) this.nav("/");
    if (this.currentProfile.emailConfirmed != true) this.nav("registration/3");
    let mProfile: Profile = this.authenticationService.getCurrentProfile();
    if (mProfile.status != null) {
      //if (!this.authService.allowAccess(2)) {
      // not logged in so redirect to login page with the return url
     this.profileService.Get(mProfile.id).subscribe(data => {
        this.currentProfile = data;
       localStorage.setItem('currentProfile', JSON.stringify(data));

      });

    }
        //this.contactService.GetAll("", 0, 10, "Created", 1).subscribe(data => {
        //  this.contacts = data;
        //})
      this.setLoading(false);
    }
}
