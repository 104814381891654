import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Profile } from '../../../models/Profile';
import { ProfileService } from '../../../core/services/profile.service';
import { Router } from '@angular/router';
import { GlobalService } from '../../../core/services/global.service';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { BaseComponent } from '../../shared/base-component/base-component.component';
import { LoginEventsEmitter } from '../../../core/events/login.events';

@Component({
  selector: 'app-registration-reg-1-landing',
  styleUrls: ['./reg-1-landing.component.css'],
  templateUrl: './reg-1-landing.component.html',
})
export class Reg1LandingComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() step: number;
  priceValue = "0";

  constructor(
    private profileService: ProfileService,
    private loginEventsEmitter: LoginEventsEmitter
  ) {
    super();
  }

  ngOnInit() {
          let newDate = Date.parse(new Date(2024, 0, 15).toString());
    let profileDate = Date.parse(new Date().toString());
          console.log(this.currentProfile);
          if (this.currentProfile.tribeId == 37) {


            if (profileDate > newDate) {
              this.priceValue = "30";

            } else {
              this.priceValue = "20";

            }

          }

          if (this.currentProfile.tribeId == 33) {
            this.priceValue = "0";

          }

          console.log(this.currentProfile);
          if (this.currentProfile.invitedBy != 0) {
            this.profileService.Get(this.currentProfile.invitedBy).subscribe(data => {
              this.currentProfile.invitedByProfile = data;
              //this.currentProfile.invitedBy = data.id;

              this.setLoading(false);
            },
              error => {
                this.msgService.error('Invalid invitation');
                this.nav('/');
              }
            );
          }



     

   
  }

  submit() {
    //localStorage.removeItem('currentProfile');
    this.nav('/registration/2');
  }
}
