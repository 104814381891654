import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Configuration } from '../../../app.constants';
import { TitleEventsEmitter } from '../../../core/events/title.events';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { MediaService } from '../../../core/services/media.service';
import { ProfileService } from '../../../core/services/profile.service';
import { QuestionService } from '../../../core/services/question.service';
import { BaseComponent } from '../../shared/base-component/base-component.component';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { ActivatedRoute } from '@angular/router';

//declare var Stripe: any;

@Component({
  selector: 'subscription-component',
  templateUrl: 'subscription.component.html',
  styleUrls: ['subscription.component.css']
})
export class SubscriptionComponent extends BaseComponent implements OnInit {

  private priceId;
  stripe;
  publishableKey;
  mode = 'pay';
  response = "";
  customers: any[] = [];
  subs: any[] = [];
  priceValue = "30";
  constructor(
    private profileService: ProfileService,
    private configuration: Configuration,
    //private alertService: AlertService,
    public questionService: QuestionService,
    private mediaService: MediaService,
    //private loaderService: LoaderService,
    //private ngProgress: NgProgress,
    //private titleEventsEmitter: TitleEventsEmitter,
    private titleEventsEmitter: TitleEventsEmitter,
    authenticationService: AuthenticationService,
    private msg: NzMessageService,
    private http: HttpClient,
    private route: ActivatedRoute
  ) {
    super();
    //this.currentProfile = new Profile();
    //this.form = fb.group({
    //    newPassword: ['', Validators.required],
    //    confirmPassword: ['', Validators.required]
    //}, {
    //        validator: PasswordValidation.MatchPassword // your validation method
    //    })
  }

  ngOnInit() {
    super.ngOnInit();
   
    this.profileService
      .Get(this.currentProfile.id)
      .subscribe(
        data => {
          this.currentProfile = data;
          localStorage.setItem('currentProfile', JSON.stringify(data));
          this.subs = [];
          this.http.get<any>("/v1/payments/link-subscription?profileId=" + this.currentProfile.id + "&customerId=" + this.currentProfile.stripeCustomerID).subscribe(tdata => {

          //console.log(tdata)
          //  if (tdata.length > 0) {

          //    this.currentProfile.subscriptionStatus = 2;
          //    this.currentProfile.stripeCustomerID = tdata[0].customerId;
          //    this.currentProfile.subscriptionExpiry = tdata[0].currentPeriodEnd;
          //    this.profileService.Update(this.currentProfile).subscribe(pr => {
          //      console.log(pr);
          //      localStorage.setItem('currentProfile', JSON.stringify(pr));
          //      this.currentProfile = pr;
          //      this.currentProfile.subscriptionStatus = 2;
          //      this.currentProfile.stripeCustomerID = tdata[0].customerId;
          //      this.currentProfile.subscriptionExpiry = tdata[0].currentPeriodEnd;
                this.router.navigate(['/']);
          //    });
          //  } else {

          //  }
         });

          if (this.currentProfile.status == 1) {
            this.router.navigate(['/']);
          }


        },
        error => console.log(error),
        () => { }
    );
    
    this.titleEventsEmitter.broadcastEventTitleChanged("Subscription");

    this.route.params.subscribe(params => {
      let newDate = Date.parse(new Date(2024, 0, 15).toString());
      let profileDate = Date.parse(this.currentProfile.created.toString());
      
      let view = params['view'];
      console.log(view,'view...')
      let response = params['response'];
      let priceId = this.route.snapshot.queryParams.priceId;
      if (this.currentProfile.tribeId == 37) {
        
        if (profileDate > newDate) {
          this.priceValue = "30";
          this.priceId = "price_1OX4LKFAO6iYW5jvLy6aDuks";
        } else {
          this.priceValue = "20";
          this.priceId = "price_1M3dUZFAO6iYW5jvVdsns0xy";
        }
      }
      if (this.currentProfile.tribeId == 33) {
        this.priceValue = "0";
        this.priceId = "price_1NNDOoFAO6iYW5jvebZVOyPi";
      }
      if (priceId == "feb23discount") {
        this.priceValue = "10";
        this.priceId = "price_1KO1cFFAO6iYW5jvKPnyPQKy";
      }
      if (priceId == "feb24discount") {
        this.priceValue = "20";
        this.priceId = "price_1M3dUZFAO6iYW5jvVdsns0xy";
      }

      //if (priceId == "feb23discount") {
      //  this.priceId = "price_1KO1cFFAO6iYW5jvKPnyPQKy";
      //} else {
      //  if (this.currentProfile.subscriptionExpiry >= new Date() && this.currentProfile.subscriptionStatus == 2) {
      //    this.router.navigate(['/account/manage-subscription']);
      //  }
      //}
      this.initStripe();

      if (view == null || view == "pay") {
        this.mode = "pay";
      } else if (view == "success") {
        this.mode = "success";
        ////this.currentProfile.subscriptionStatus = 2;
        //this.profileService
        //  .Update(this.currentProfile)
        //  .subscribe(
        //    data => {
        //      //this.alertService.success("Your password was updated successfully");
        //      localStorage.setItem('currentProfile', JSON.stringify(data));
        //      //this.ngProgress.complete();
        //    },
        //    error => {
        //    });
       
      } else if (view == "canceled") {
        this.mode = "cancel";
      }
    });


  }

  ngAfterViewInit() {

    this.setLoading(false); 

  }



  initStripe() {
   
    this.http.get<any>("/v1/payments/setup").subscribe(data => {
      this.publishableKey = data.publishableKey;
      if (this.priceId == null) {
        this.priceId = data.priceId;
      }

      

      loadStripe(this.publishableKey).then(res => {
        this.stripe = res;
      });

      this.setLoading(false);

      //// Setup event handler to create a Checkout Session when button is clicked
      //document
      //  .getElementById("basic-plan-btn")
      //  .addEventListener("click", (evt) => {
      //    this.createCheckoutSession(basicPriceId).then(function (data) {
      //      // Call Stripe.js method to redirect to the new Checkout page
      //      stripe
      //        .redirectToCheckout({
      //          sessionId: data.sessionId
      //        })
      //        .then(handleResult);
      //    });
      //  });

      // Setup event handler to create a Checkout Session when button is clicked
      //document
      //  .getElementById("pro-plan-btn")
      //  .addEventListener("click", function (evt) {
      //    createCheckoutSession(proPriceId).then(function (data) {
      //      // Call Stripe.js method to redirect to the new Checkout page
      //      stripe
      //        .redirectToCheckout({
      //          sessionId: data.sessionId
      //        })
      //        .then(handleResult);
      //    });
      //  });
    })
    /* Get your Stripe publishable key to initialize Stripe.js */
    ////fetch("/setup")
    //  .then(handleFetchResult)
    //  .then(function (json) {

    //  });
  }

  pay() {

    //this.priceId = "price_1NyFkgFAO6iYW5jvkyRR72QW";
    console.log("f - ", this.priceId);
    this.http.post<any>("/v1/payments/create-checkout-session", JSON.stringify({ priceId: this.priceId, customerId: this.currentProfile.id }), this.authenticationService.getHttpOptions()).subscribe(data => {
      this.stripe
        .redirectToCheckout({
          sessionId: data.sessionId
        })
        .then(() => this.handleResult);
    });
  }
  //public refreshProfile() {
  //  //this.ngProgress.start();

  //  this.profileService
  //    .Get(this.currentProfile.id)
  //    .subscribe(
  //      data => {
  //        localStorage.setItem('currentProfile', JSON.stringify(data));
  //        this.currentProfile = data;
  //        //this.loaderService.hide();
  //        //this.ngProgress.complete();
  //      },
  //      error => console.log(error),
  //      () => { }
  //  );
  //  this.setLoading(false);
  //}

  //public updateProfile() {
  //  //this.ngProgress.start();
  //  this.profileService
  //    .Update(this.currentProfile)
  //    .subscribe(
  //      data => {
  //        //this.alertService.success("Your password was updated successfully");
  //        localStorage.setItem('currentProfile', JSON.stringify(data));
  //        //this.ngProgress.complete();
  //      },
  //      error => {
  //      });
  //}

  //showErrorMessage(err) {
  //  console.log(err);

  //}

  // If a fetch error occurs, log it to the console and show it in the UI.
  handleFetchResult(result) {
    if (!result.ok) {
      return result.json().then(function (json) {
        if (json.error && json.error.message) {
          throw new Error(result.url + ' ' + result.status + ' ' + json.error.message);
        }
      }).catch(function (err) {
        this.showErrorMessage(err);
        throw err;
      });
    }
   
    return result.json();
  };

  // Create a Checkout Session with the selected plan ID
  //createCheckoutSession(priceId) {
  //  this.http.post<any>("/v1/payments/create-checkout-session", JSON.stringify({ priceId: priceId })).subscribe(data => {

  //  })
  //}).then(this.handleFetchResult);
  //};

  // Handle any errors returned from Checkout
  handleResult(result) {
    if (result.error) {
      this.showErrorMessage(result.error.message);
    }
  };

  showErrorMessage(message) {
    var errorEl = document.getElementById("error-message")
    errorEl.textContent = message;
    errorEl.style.display = "block";
  };



}
