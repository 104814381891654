import { Component, OnInit, OnDestroy, TemplateRef, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { NavigationCancel, Router, NavigationStart, NavigationEnd, RouterEvent } from '@angular/router';
import { LoginEventsEmitter } from '../../core/events/login.events';
import { AuthenticationService } from '../../core/services/authentication.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { GlobalService } from '../../core/services/global.service';

import { Howl } from 'howler'
import { interval, Observable } from 'rxjs';
import { take, filter } from 'rxjs/operators';
import { TitleEventsEmitter } from '../../core/events/title.events';
import { Title } from '@angular/platform-browser';
import { BaseComponent } from '../shared/base-component/base-component.component';
import { UniversalCookieConsentService } from 'universal-cookie-consent';
//import { AngularGaService } from '@angular/ganalytics';
//import { filter } from 'rxjs/operators';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  animations: [
    //animations,
    //routerTransition,
    //fadeInOnEnterAnimation(),
    //fadeOutOnLeaveAnimation()
  ]
})
export class AppComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(TemplateRef, { static: false }) notificationTemplate: TemplateRef<any>;

  consents$: Observable<string[]>;

  private endPageTitle: string = 'Otto | The Otto Connection';

  showNavMenu: boolean = true;
  message: string;
  loadPercentage: number;
  audioSrc: string;
  footerYear: number = (new Date()).getFullYear();

  constructor(
    private title: Title,
    private loginEventsEmitter: LoginEventsEmitter,
    private nzNotificationService: NzNotificationService,
    private titleEventsEmitter: TitleEventsEmitter,
    private cd: ChangeDetectorRef,
    private cookieConsentService: UniversalCookieConsentService,
    private $gaService: GoogleAnalyticsService
    //private angularGa: AngularGaService, private router: Router


  ) {
    super();

    //this.isLoading = false;
    //this.isNavigating = false;
    this.trackRoutes();
    this.consents$ = this.cookieConsentService.getGrantedConsents();
    this.loadPercentage = 0;

    this.globalService.audio.subscribe(event => {
      let sound = new Howl({
        src: ["./assets/audio/" + event + ".mp3"]
      });
      sound.play()
    });

    this.registerHandlers();
    //this.trackRoutes();

  }

  ngOnInit() {
    this.currentProfile = this.authService.currentProfileValue;
    //if (this.currentProfile.subscriptionStatus == )

    //this.router.events.pipe(
    //  filter((e: RouterEvent) => e instanceof NavigationStart || e instanceof NavigationEnd)
    //).subscribe({
    //  next: (e: RouterEvent) => {
    //    //const scrollContainer = document.getElementById('scrollableContent');
    //    if (e instanceof NavigationStart) {
    //      //this.scrollTopPositions[router.url] = window.scrollY;
    //    } else if (e instanceof NavigationEnd) {
    //          window.scrollTo(0, 0);
    //      //const newUrl = router.url;
    //      //interval(500).pipe(take(1)).subscribe(x => {
    //      //  if (newUrl.indexOf('pictures') >= 0 || newUrl.indexOf('archive') >= 0) {
    //      //    window.scrollTo(0, this.scrollTopPositions[newUrl]);
    //      //  }
    //      //});
    //    }
    //  }
    //});


    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          //if (!this.inProgress) {
          //  this.progressBar.start();
          //  this.inProgress = true;
          //  this.cycleProgressColor();
          //}
          //this.isLoading = true;
        }
        else if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel
        ) {
          window.scrollTo(0, 0);
          this.showNavMenu = (this.router.url.indexOf('reset-password') == -1) &&
            (this.router.url.indexOf('registration') == -1) &&
            (this.router.url.indexOf('privacy') == -1) &&
            (this.router.url.indexOf('terms') == -1) &&
            (this.router.url.indexOf('login') == -1) &&
            (this.currentProfile != null);
          //window.scrollTo(0, 0);
          //this.isLoading = false;
        }
      });
  }

  ngAfterViewInit() {

    this.globalService.progress.subscribe(event => {
      if (event === true) {
        //this.progressBar.start();
        this.loadPercentage = 0;
        //this.isLoading = true;
        //interval(250).pipe(take(20)).subscribe(x => {
        //  if (this.loadPercentage < 100) this.loadPercentage += (100 - this.loadPercentage) / 8;
        //});
      } else {
        if (event != null) {
          this.message = event;
          this.nzNotificationService.template(this.notificationTemplate);
        }
        this.loadPercentage = 100;
        this.isLoading = false;
        this.cd.detectChanges();
      }
    });

  }



  trackRoutes() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(event => {
        const url = (event as NavigationEnd).urlAfterRedirects;
        this.$gaService.pageView(url, url, this.currentProfile.emailAddress);
      });
  }

  // Other methods for tracking events
  //trackEventExample() {
  //  this.angularGa.trackEvent('category', 'action', 'label', value);
  //}
  //ngOnDestroy() {
  //this._hubConnection
  //  .stop()
  //  .then(function () {
  //  }).catch(function (err) {
  //    return console.error(err.toString());
  //  });
  //}

  onButtonClick() {
    this.cookieConsentService.show(true);
  }

  getState(outlet) {
    return outlet.activatedRouteData.state;
  }

  private registerHandlers(): void {
    this.registerUserLoggingEventHandler();
    this.registerTitleChangedEventHandler();
  }

  private registerUserLoggingEventHandler(): void {
    this.loginEventsEmitter.currentProfile$.subscribe(data => {
      this.currentProfile = data;
      if (this.currentProfile != null) {
        //this.loadAddTimesheet();
      }
    });
  }

  private registerTitleChangedEventHandler(): void {
    this.titleEventsEmitter.viewTitle$.subscribe(
      title => {
        this.title.setTitle(title + " | " + this.endPageTitle);
        //this.viewTitle = viewTitle;
      });
  }

  //private _changeTitleOnNavigation() {
  //  this.routerSub$ = this.router.events
  //    .pipe(
  //      filter(event => event instanceof NavigationEnd),
  //      map(() => this.activatedRoute),
  //      map(route => {
  //        while (route.firstChild) route = route.firstChild;
  //        return route;
  //      }),
  //      filter(route => route.outlet === 'primary'),
  //      mergeMap(route => route.data)
  //    )
  //    .subscribe(event => {
  //      this._setMetaAndLinks(event);
  //    });
  //}

  //private _setMetaAndLinks(event) {
  //  // Set Title if available, otherwise leave the default Title
  //  const title = event['title']
  //    ? `${event['title']} - ${this.endPageTitle}`
  //    : `${this.endPageTitle} | ${this.endPageTitle}`;

  //  this.title.setTitle(title);

  //  const metaData = event['meta'] || [];
  //  const linksData = event['links'] || [];

  //  for (let i = 0; i < metaData.length; i++) {
  //    this.meta.updateTag(metaData[i]);
  //  }

  //  for (let i = 0; i < linksData.length; i++) {
  //    this.linkService.addTag(linksData[i]);
  //  }
  //}

}
