import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient } from "@angular/common/http";
import { AuthenticationService } from './authentication.service';
import { Configuration } from '../../app.constants';
import { Log } from '../../models/Log';

@Injectable()
export class LogService {
    private isBrowser: boolean;
    private actionUrl: string;

    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string,
        configuration: Configuration,
        private authenticationService: AuthenticationService
    ) {
        this.actionUrl = configuration.Server + 'v1/Log/';
        //http.get<WeatherForecast[]>(baseUrl + 'weatherforecast').subscribe(result => {
        //   this.forecasts = result;
        //}, error => console.error(error));
    }

    public GetAll = (keyword: string, row: number, pageSize: number, sortField: string, sortOrder: number): Observable<Log[]> => {
        let url = this.actionUrl + 'all?' + '&keyword=' + keyword + '&row=' + row + '&pageSize=' + pageSize + '&sortField=' + sortField + '&sortOrder=' + sortOrder;
        return this.http.post<Log[]>(url, '', this.authenticationService.getHttpOptions());
    }
    public GetAllCount = (keyword: string): Observable<number> => {
        return this.http.post<number>(this.actionUrl + 'allcount?keyword=' + keyword, '', this.authenticationService.getHttpOptions());
    }
    public Get = (id: number): Observable<Log> => {
        return this.http.post<Log>(this.actionUrl + id, "", this.authenticationService.getHttpOptions());
    }
    public Add = (item: Log): Observable<Log> => {
        return this.http.post<Log>(this.actionUrl, JSON.stringify(item), this.authenticationService.getHttpOptions());
    }
    public Update = (item: Log): Observable<Log> => {
        return this.http.put<Log>(this.actionUrl, JSON.stringify(item), this.authenticationService.getHttpOptions());
    }
    public Delete = (id: number): Observable<any> => {
        return this.http.delete(this.actionUrl + id, this.authenticationService.getHttpOptions());
    }

    public Log = (content: string, logType: number): Observable<Log> => {
        let item = new Log();
        item.content = content;
        item.logType = logType;
        return this.http.post<Log>(this.actionUrl, JSON.stringify(item), this.authenticationService.getHttpOptions());
    }
}
