import { Component, OnInit, Input, Injectable } from '@angular/core';
import { Profile } from '../../../models/Profile';
import { ProfileService } from '../../../core/services/profile.service';
import { Router } from '@angular/router';
import { GlobalService } from '../../../core/services/global.service';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { BaseComponent } from '../../shared/base-component/base-component.component';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { NgbCalendar, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';


@Injectable()
export class CustomDateParserFormatter {
  parse(value: string): NgbDateStruct {
    if (!value)
      return null
    let parts = value.split('/');
    return { year: +parts[0], month: +parts[1], day: +parts[2] } as NgbDateStruct

  }
  format(date: NgbDateStruct): string {

    return date ? ('0' + date.day).slice(-2) + "/" + ('0' + date.month).slice(-2) + "/" + date.year : null
  }
}




@Component({
  selector: 'app-registration-reg-2-details',
  styleUrls: ['./reg-2-details.component.css'],
  templateUrl: './reg-2-details.component.html',
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ]

})
export class Reg2DetailsComponent extends BaseComponent implements OnInit {
  @Input() step: number;

  optionList = [
    { label: 'He/Him', value: 1 },
    { label: 'She/Her', value: 2 },
    { label: 'They/Them', value: 3 },
    { label: 'Ze/Hir', value: 4 }
  ];
  selectedValue = { label: 'He/Him', value: 1 };
  emailAddressChanged: Subject<string> = new Subject<string>();
  emailAddressExists: boolean = false;
  invitedBy: number;
  model2: NgbDateStruct = { year: 2021, month: 1, day: 1 };
  model3: NgbDateStruct = { year: 2021, month: 1, day: 1 };
  model1: string;
  newDate: string;
  validDate: boolean;


  constructor(
    private profileService: ProfileService,
    private ngbCalendar: NgbCalendar,
  ) {
    super();
  }

  ngOnInit() {

    this.newDate = moment(this.currentProfile.dob).format('yyyy-MM-DD');
   


    this.invitedBy = this.currentProfile.invitedBy;

    //this.contactService.GetAll("", 0, 10, "Created", 1).subscribe(data => {
    //  this.contacts = data;
    //})

    this.emailAddressChanged.pipe(
      //distinctUntilChanged() // only emit if value is different from previous value
      debounceTime(250)
    )
      .subscribe(emailAddress => {
        this.profileService.GetByEmailAddress(this.currentProfile.emailAddress).subscribe(existingProfile => {
          this.emailAddressExists = existingProfile != null;
          if (this.emailAddressExists) {
            this.msgService.error("This email address is already registered.");
          }
        });
      });

    this.setLoading(false);
  }

  ngAfterViewInit() {
    this.currentProfile.gender = 1;
    for (var option of this.optionList) {
      if (option.value == this.currentProfile.gender) {
        this.selectedValue = option;
      }
    }
  }
  onEmailAddressChanged($event) {
    this.emailAddressChanged.next($event);
  }

  selectChange(value: string): void {
    this.currentProfile.gender = this.selectedValue.value;
  }



  onChange(result: NgbDateStruct): void {


    //if (moment(this.model2.toString(), 'DD/MM/YYYY', true).isValid()) {
    //  console.log("yah");
    //} else {
    //  console.log("nah");
    //}
    //this.currentProfile.dob = new Date(result.year + "-" + result.month + "-" + result.day + " " + ("0" + 0).slice(-2) + ":" + ("0" + 0).slice(-2));
    //console.log("as ",this.currentProfile.dob);

    if (moment(this.newDate, 'YYYY-MM-DD', true).isValid()) {
      this.validDate = true;
      this.currentProfile.dob = moment(this.newDate, 'YYYY-MM-DD', true).toDate();
      
    } else {
      this.validDate = false;
    }
  }

  submit() {

    this.profileService.GetByEmailAddress(this.currentProfile.emailAddress).subscribe(existingProfile => {
      if (!existingProfile) {

        

        this.profileService.Add(this.currentProfile).subscribe(data => {

          this.currentProfile = data;
          this.currentProfile.invitedBy = this.invitedBy;
          
          localStorage.setItem('currentProfile', JSON.stringify(this.currentProfile));

          this.currentProfile.token = sessionStorage.getItem('t');
          sessionStorage.removeItem('t');

          this.profileService.SendVerificationEmail(this.currentProfile).subscribe(data2 => {
            this.nav("/registration/3");
          });
        });
      } else {
        this.msgService.error("This email address is already registered.");
      }
    });
  }

}
