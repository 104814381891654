import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { Interest } from '../../../../models/Interest';
import { Router } from '@angular/router';
import { InterestService } from '../../../../core/services/interest.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Event } from '../../../../models/Event';
import { EventService } from '../../../../core/services/event.service';
import { EventInterest } from '../../../../models/EventInterest';
import { BaseComponent } from '../../base-component/base-component.component';
import { FilterSettings } from '../../../../models/shared/grids/filterSettings';

@Component({
  selector: 'event-interests',
  templateUrl: './event-interests.component.html',
  styleUrls: ['./event-interests.component.css']
})
export class EventInterestsComponent extends BaseComponent implements OnInit {
  @Input() event: Event;
  @Output() onEventUpdated: EventEmitter<Event> = new EventEmitter<Event>();
  @Input() readonly: boolean = false;
  @Input() max: number = 100;
  filterSettings: FilterSettings;
  public subscriptions: Subscription[] = [];
  public interests: Interest[];

  constructor(
     
    private eventService: EventService,
    private interestService: InterestService,
   
  ) {
    super();
    this.filterSettings = new FilterSettings();
  }

  ngOnInit() {
    //this.contactService.GetAll("", 0, 10, "Created", 1).subscribe(data => {
    //  this.contacts = data;
    //})

    this.interestService.GetForFilterSettings(this.filterSettings, 0, 1000, "ViewOrder", 1).subscribe(data => {
      data.forEach(i => {
        (<any>i).checked = this.event.eventInterest.find(x => x.interestId == i.id) != null;
        (<any>i).color = this.getColor(data.indexOf(i), data.length);
        (<any>i).checkedColor = this.getColor(data.indexOf(i), data.length);

      });
      this.interests = !this.readonly ? data : data.filter(x => (<any>x).checked);
      if (this.max < 100) this.interests = this.interests.splice(0, this.max);
    });

    if (!this.event.eventInterest) {

      this.event.eventInterest = [];
    }
  }

  //hasInterest(interestId: number) {
  //  return this.event.eventInterest.filter(x => x.interestId == interestId).length > 0;
  //}

  handleChange(checked: boolean, interest: any): void {
    if (checked) {
      if (!interest.checked) {
        let ei = new EventInterest();
        ei.interestId = interest.id;
        this.event.eventInterest.push(ei)
       
        //this.msgService.info('Interest Added');
        interest.checked = true;
      }
    } else {
      interest.checked = false;
      this.event.eventInterest = this.event.eventInterest.filter(x => x.interestId != interest.id);
      //this.msgService.info('Interest Removed');
    }
  }

  submit() {
  }

  getColor(index, length): string {
    let f = length > 0 ? index / length : 0;
    let h = this.scaledInt(0, 360, f);
    let s = this.scaledInt(42, 98, f) * 0.65;
    let l = this.scaledInt(40, 90, Math.sqrt(f));
    return `hsl(${h},${s}%,${l}%)`;
  }

  public scaledInt(min, max, f) {
    return ((max - min) * f) + min;
  }

}
