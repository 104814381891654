import { Component, OnChanges, OnInit } from '@angular/core';
import { Profile } from '../../../models/Profile';
import { Configuration } from '../../../app.constants';
import { ProfileService } from '../../../core/services/profile.service';
import { Message } from '../../../models/Message';
import { Event } from '../../../models/Event';
import { MessageService } from '../../../core/services/message.service';
import { GlobalService } from '../../../core/services/global.service';
import { LoginEventsEmitter } from '../../../core/events/login.events';
import { NavigationEnd, Router } from '@angular/router';
import { BaseComponent } from '../base-component/base-component.component';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { FilterSettings } from '../../../models/shared/grids/filterSettings';
import { NotificationService } from '../../../core/services/notification.service';
import { Notification } from '../../../models/notification';
import { EventService } from '../../../core/services/event.service';
import * as moment from 'moment';
import { AuthService } from '../../../core/services/auth2.service';

//import { MessageSendService } from '../../../core/services/message-send.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent extends BaseComponent implements OnChanges, OnInit {
  isExpanded = false;
  isModalInviteVisible = false;
  isModalInviteOkLoading = true;
  ttColor = "#ffffff";
  invalidEmail = false;
  msgCount: number;
  cdnUrl: string = "";
  notificationCount: number = 0;
  profiles: Profile[];
  notifications: Notification[];
  inviteForm!: UntypedFormGroup;
  visible: boolean = false;
  filterSettings: FilterSettings;
  addon: string = "?ht=" + (Math.floor(Math.random() * 99999999));

  constructor(
    router: Router,
    globalService: GlobalService,
    authenticationService: AuthService,
    private fb: UntypedFormBuilder,
    private configuration: Configuration,
    private profileService: ProfileService,
    private eventService: EventService,
    private loginEventsEmitter: LoginEventsEmitter,
    private messageService: MessageService,
    private toolTipModule: NzToolTipModule,
    private siteNotificationService: NotificationService
    //private messageSendService: MessageSendService
  ) {
    super();
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) { this.updateInbox(); }
    });
    this.filterSettings = new FilterSettings();

    this.inviteForm = this.fb.group({
      formLayout: ['inline'],

      email: [null, [Validators.required, Validators.email]],
    });

  }

  ngOnInit() {
    this.messageService.initMessageHubConnection2(this.currentProfile.id);
    //Call client methods from hub to update User
    this.messageService._messageHubConnection.on('UpdateInbox', (message: Message) => {
      this.globalService.playAudio('message');
      this.updateInbox();
      console.log("eeee");
    });
    //this.messageService._messageHubConnection.on('ReceiveMessage', (message: Message) => {
    //  this.globalService.playAudio('message');
    //  this.updateInbox();
    //  console.log("test");
    //});
    //setInterval(() => { this.updateProfile() }, 20000);
    this.cdnUrl = this.configuration.CDNUrl;
    setInterval(() => { this.onProfileUpdated() }, 20000);
    if (this.currentProfile) {
      this.onProfileUpdated();

      this.loginEventsEmitter.currentProfile$.subscribe(data => {
        if (this.currentProfile != data && data != null) {
          this.currentProfile = data;

          this.onProfileUpdated();
        }
      });


      this.setLoading(false);
    }

  }

  updateNotificationCount() {
    this.siteNotificationService.GetAllCount(this.currentProfile.id, true).subscribe(data => {
      this.notificationCount = data;
    });
  }

  ngOnChanges() {
  }

  updateProfile() {

    //if (this.currentProfile && (this.currentProfile.subscriptionStatus != 2) || !(moment(this.currentProfile.subscriptionExpiry).isAfter(moment()))) { //.add(1, 'days')// || !moment(mProfile.subscriptionExpiry, 'MM/D/YYYY').isAfter(moment(Date.now(), 'MM/D/YYYY'))

    //  //is not subscribed
    //  if (this.currentProfile.userLevel != 3) {
    //    if (this.router.url.toString().indexOf("subscription") < 0) {
    //      this.router.navigate(['/account/subscription']);
    //    }
    //  }
    //}
  }
  onProfileUpdated() {
    if (this.currentProfile && this.currentProfile.id > 0) {
      this.filterSettings.profileId = this.currentProfile.id;


      this.siteNotificationService.GetInbox(this.currentProfile.id, 10).subscribe(data => {
        this.notifications = data;
        this.notifications = this.notifications.filter(
          (thing, i, arr) => arr.findIndex(t => t.globalId === thing.globalId && t.globalObjectType === thing.globalObjectType) === i
        );

        this.notificationCount = this.notifications.filter(t => t.status != 2).length;
        this.notifications.forEach(r => {
          if (r.globalObjectType == 2) {
            this.eventService.Get(r.globalId).subscribe(t => {
              r.imageURL = t.imageUrl;
              r.titleText = t.title;

            });
          }
        });

      });

      //this.updateInbox();
    }
  }

  collapse() {
    this.isExpanded = false;
    this.toggleBodyOverflow();
  }

  toggleBodyOverflow(): void {
    document.body.classList.toggle('overflow-hidden', this.isExpanded);
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
    this.toggleBodyOverflow();
  }

  showModalInvite(): void {
    this.isModalInviteVisible = true;
  }

  handleModalInviteOk(): void {
    this.isModalInviteOkLoading = true;
    setTimeout(() => {
      this.isModalInviteVisible = false;
      this.isModalInviteOkLoading = false;
    }, 3000);
  }



  invite(): void {
    if (this.inviteForm.status != 'INVALID') {
      this.invalidEmail = false;
      this.setLoading(true);
      this.profileService.Invite(this.inviteForm.controls['email'].value, this.currentProfile.id).subscribe(data => {
        this.inviteForm.controls['email'].setValue('');
        this.msgService.success('Invitation sent');
        this.setLoading(false);
        this.isModalInviteVisible = false;
      },
        error => {
          this.msgService.error(error);
          this.setLoading(false);
        }
      );
    } else {
      this.invalidEmail = true;
    }
  }

  handleModalInviteCancel(): void {
    this.isModalInviteVisible = false;
  }

  //testMessage() {
  //  this.sendTestMessage();
  //}

  //sendTestMessage() {
  //  //let fromProfileId = this.profiles[Math.floor(this.profiles.length * Math.random())].id;

  //  //let m = new Message();
  //  //m.content = "new message " + (new Date()).toString();
  //  //m.fromProfileId = fromProfileId;
  //  //m.toProfileId = this.currentProfile.id;
  //  //m.messageType = 1;
  //  //m.status = 1;
  //  //m.created = new Date();
  //  //this.messageService.Add(m).subscribe(data => {
  //  //  this.messageService.sendMessage(data);
  //  //  //this.messageSendService.broadcastMessage(data);                   // Send the message via a service
  //  //});
  //}

  updateInbox() {
    if (this.currentProfile) {
      this.messageService.GetUnreadCount(this.currentProfile.id).subscribe(data => {
        //if (data > this.msgCount) {
        //  this.globalService.playAudio('message');
        //}
        this.msgCount = data;
        //console.log(data);
        //this.messageSendService.retrieveInboxMessagesObject().subscribe((inboxMessages: Message[]) => {
        //  this.msgCount = inboxMessages.filter(x => x.status == 1).length;
        //});
      });
    }

  }

  //1200/800/256
  getImageUrl = (p: Profile, size: number): string => { return this.globalService.getProfileImageUrl(p, size) + this.addon; }

  go(url) {

    console.log(url);
    this.isExpanded = false;
    this.toggleBodyOverflow();
    this.nav(url);
  }
  goNotification(item: Notification) {
    item.status = 2;
    let url = "";
    this.siteNotificationService.UpdateGlobal(item).subscribe(f => {
      this.isExpanded = false;
      this.toggleBodyOverflow();
      if (item.globalObjectType == 2) {
        url = '/events/'
      }
      this.onProfileUpdated();
      this.nav(url + item.globalId);
    });

  }

}
