import { Component, OnInit, PLATFORM_ID, Inject, Input } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { Media } from '../../../../models/Media';
import { MediaService } from '../../../../core/services/media.service';
import { Configuration } from '../../../../app.constants';
import { Router } from '@angular/router';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { NzMessageService } from 'ng-zorro-antd/message';
import { GlobalService } from '../../../../core/services/global.service';
import { Observer, Observable } from 'rxjs';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { Output, EventEmitter } from '@angular/core';
import { BaseComponent } from '../../base-component/base-component.component';
import { animations } from 'ack-angular-fx';
import { Uploads } from '../../../../models/shared/upload/Uploads';

@Component({
  selector: 'media-grid',
  templateUrl: './media-grid.component.html',
  styleUrls: ['media-grid.component.css'],
  animations: [
    animations,

  ]
})

export class MediaGridComponent extends BaseComponent implements OnInit {
  @Input() ref: string = "-";
  @Input() object: any;
  @Input() items: Media[];
  @Output() newItems = new EventEmitter<Media[]>();
  @Input() mediaType: number;
  @Input() mediaTypeId: number;
  @Input() allowUpload: boolean;
  @Input() allowDelete: boolean;

  public imagePlaceholder: string = '/assets/img/placeholder.png';
  mediaObject: Media;
  public isBrowser: boolean = false;
  previewImage: string | undefined = '';
  previewVisible = false;
  isUpdating: boolean = false;
  isUsingCollection: boolean;
  actionUrl = null;
  isModalInviteVisible: boolean = false;
  constructor(
    router: Router,
    globalService: GlobalService,
    authenticationService: AuthenticationService,
    private mediaService: MediaService,
    private configuration: Configuration,
    private msg: NzMessageService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    super();
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    this.load();
  }

  ngOnChanges() {
    //if (!this.isUpdating) {
    //  this.update();
    //} else {
    //  this.isUpdating = false;
      
    //}
   this.update();
    
  }

  refresh() {
    this.actionUrl = '/v1/Media/uploadFile/' + this.mediaType + '/' + this.mediaTypeId + '/' + this.currentProfile.id;
    this.mediaService.GetByMediaType(this.mediaType, this.mediaTypeId)
      .subscribe(data => {
        this.items = data;
        
        this.isLoading = false;
      });
  }

  update() {
    
    if (this.isUsingCollection == false) {
      
   
    
 
      this.refresh();
    }

   
  }

  load() {
    this.actionUrl = "https://run.mocky.io/v3/40d69f1e-59e1-448c-84b6-4f7e6bc36197";
    this.isUsingCollection = (this.items != null);//&& this.mediaTypeId != 0);
  
    if (this.isUsingCollection) {
      ////this.mediaItems = this.items;
      this.isLoading = false;
      
      //if (!this.mediaTypeId) {
      //  this.mediaTypeId = this.items[0].mediaType;
      //}
      //this.mediaType = this.items[0].mediaType;
      //this.mediaService.GetByMediaType(this.mediaType, this.mediaTypeId)
      //  .subscribe(data => {
      //    this.items = data;
      //    this.isLoading = false;
      //  });
    } else {
      this.actionUrl = '/v1/Media/uploadFile/' + this.mediaType + '/' + this.mediaTypeId + '/' + this.currentProfile.id;
      this.mediaService.GetByMediaType(this.mediaType, this.mediaTypeId)
        .subscribe(data => {
          this.items = data;
          this.isLoading = false;
        });
    }
  }

  transformFile = (file: NzUploadFile) => {
   
    return new Observable((observer: Observer<Blob>) => {
      Uploads.convertToBlob(file).then(blobSrc => {
        if (this.isUsingCollection) {


          if (this.items == null) this.items = [];
          let newMediaItem = new Media;
          newMediaItem.filename = blobSrc;
          if (this.items.find(x => x.filename == newMediaItem.filename) == null) this.items.push(newMediaItem);
         
        }
      });
    });
  };

  beforeUpload = (file: NzUploadFile): boolean => {
    
    //var fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file.originFileObj! as Blob));
    return true;
  };

  getImageUrl = (p: Media, size: number): string => {
    if (p?.filename?.indexOf("data:") > -1) return p.filename;
    return this.globalService.getMediaUrl(p, size);
  }

  handleChange(info: any): void {
    this.newItems.next(this.items);
    
  }

  handleModalInviteOk() {
    this.isModalInviteVisible = false;
  }

  showModalInvite(item) {
    this.isModalInviteVisible = true;
    this.mediaObject = item;
    console.log(item);
  }

  delete(item) {
    (<any>item).deleted = true;

    

    //this.items.splice(this.items.indexOf(item), 1)


    // this.mediaItems.filter(x => x.filename.indexOf("data:") > -1)
    //items.splice(4, 1,)
  }

}
