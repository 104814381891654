export const ANALYTICS = {
  profiles: {
    profiles: 'profile_list',
    profile: 'profile',
  },
  auth: {
    category:'auth',
    login: 'login',
    signup:'signup'
  }
}