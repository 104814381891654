// comment-modal.component.ts
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { BaseComponent } from '../../shared/base-component/base-component.component';
import { NewsItem } from 'src/app/models/NewsItem';
import { NewsItemService } from 'src/app/core/services/news-item.service';

@Component({
  selector: 'app-comment-modal',
  templateUrl: './comment-modal.component.html',
  styleUrls: ['./comment-modal.component.css']
})
export class CommentModalComponent extends BaseComponent {
  commentForm: FormGroup;
  fileList: NzUploadFile[] = [];

  constructor(
    private fb: FormBuilder,
    private modalRef: NzModalRef,
    private newsService: NewsItemService,
  ) {
    super()
    this.commentForm = this.fb.group({
      title: ['', Validators.required],
      content: ['', Validators.required]
    });
  }



  submitForm(): void {
    this.setLoading(true);
    if (this.commentForm.valid) {
      const formData = {
        ...this.commentForm.value,
        images: this.fileList
      };
      const { content, title, images } = formData;
      let item = new NewsItem()
      item.description = content;
      item.title = title;
      item.profileId = this.currentProfile.id;
      item.tribeId = this.currentProfile.tribeId;
      item.created = new Date();
      item.status = 1
      item.itemType = 2;

      this.newsService.Add(item).subscribe(data => {
        this.modalRef.close(formData);
        this.setLoading(false);
      });
      console.log(formData, 'formdata..')
    } else {
      Object.values(this.commentForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
          this.setLoading(false);
        }
      });
    }
  }

  cancel(): void {
    this.modalRef.close();
  }

  beforeUpload = (file: NzUploadFile): boolean => {
    this.fileList = this.fileList.concat(file);
    return false;
  };

  removeFile = (file: NzUploadFile): boolean => {
    const index = this.fileList.indexOf(file);
    if (index > -1) {
      this.fileList.splice(index, 1);
    }
    return true;
  };
}