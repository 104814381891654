import { Component, OnInit, Input, OnDestroy, Sanitizer } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Media } from '../../../../models/Media';
import { Subscription } from 'rxjs';
import { MediaService } from '../../../../core/services/media.service';
import { GlobalService } from '../../../../core/services/global.service';

@Component({
  selector: 'media-item',
  templateUrl: './media-item.component.html',
  styleUrls: ['./media-item.component.css']
})

export class MediaItemComponent implements OnInit, OnDestroy {
  @Input() media: Media = new Media();
  @Input() mediaType: number;
  @Input() mediaTypeId: number;

  public subscriptions: Subscription[] = [];
  public isLoading: boolean = false;

  item: Media;

  constructor(
    router: Router,
    private route: ActivatedRoute,
    private mediaService: MediaService,
    private globalService: GlobalService,
    private sanitizer: Sanitizer
  ) {
    //this.siteContext = this.pageService.GetCurrentContext(this.router);
    this.isLoading = false;
  }

  ngOnInit() {
    if (this.mediaTypeId != 0 && this.mediaType > 0) {
      this.mediaService.GetByMediaType(this.mediaType, this.mediaTypeId).subscribe(data => {
        this.item = data.length > 0 ? data[0] : null;
      });
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => { subscription.unsubscribe(); });
    this.subscriptions = [];
  }

  //selectItem(item: Media) {
  //    this.onSelect.emit(item);
  //}

  getImageUrl = (p: Media, size: number): string => {
    return this.globalService.getMediaUrl(p, size);
  }
}
