export class GridSettings {
  public pageIndex: number = 0;
  public pageSize: number = 10;
  public sortField: string = ''
  public sortAsc: boolean = true;

  public pageSizeTypes = [];

  constructor() {
    this.pageSize = 25;
    this.sortField = "Created";
    this.sortAsc = false;

    this.pageSizeTypes = GridSettings.getPageSizeTypes();
  }

  public static getPageSizeTypes() {
    let types = [];
    types.push({ key: 10, title: '10' });
    types.push({ key: 25, title: '25' });
    types.push({ key: 50, title: '50' });
    types.push({ key: 100, title: '100' });
    types.push({ key: 250, title: '250' });
    types.push({ key: 500, title: '500' });
    types.push({ key: 1000, title: '1000' });
    types.push({ key: 2500, title: '2500' });
    types.push({ key: 5000, title: '5000' });
    types.push({ key: 10000, title: '10000' });
    types.push({ key: 10000000, title: 'All' });
    return types;
  }

}
