import { Component, OnInit, OnDestroy } from '@angular/core';
import { GridSettings } from '../../../models/shared/grids/gridSettings';
import { Question } from '../../../models/Question';
import { QuestionService } from '../../../core/services/question.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzInputModule } from 'ng-zorro-antd/input';
import { Router } from '@angular/router';
import { GlobalService } from '../../../core/services/global.service';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { BaseComponent } from '../../shared/base-component/base-component.component';
import * as moment from 'moment';
import { FilterSettings } from '../../../models/shared/grids/filterSettings';

@Component({
  selector: 'admin-questions',
  styleUrls: ['./questions.component.css'],
  templateUrl: './questions.component.html',

})
export class AdminQuestionsComponent extends BaseComponent implements OnInit, OnDestroy {
  public items: Question[];
  filterSettings: FilterSettings;
  gridSettings: GridSettings;
  question: Question;
  unEditedQuestion: Question;
  total = 1;
  isVisible = false;
  validateForm!: UntypedFormGroup;
  newItem: Question;


  constructor(
    router: Router,
    globalService: GlobalService,
    authenticationService: AuthenticationService,
    private questionService: QuestionService,
    private fb: UntypedFormBuilder,
    private nzMessageService: NzMessageService
  ) {
    super();
    this.filterSettings = new FilterSettings();
    this.gridSettings = new GridSettings();
    this.gridSettings.sortField = "ViewOrder";
    this.gridSettings.sortAsc = true;
    this.gridSettings.pageSize = 50;
    this.gridSettings.pageIndex = 0;
  }

  ngOnInit() {
    this.newItem = new Question();

    this.newItem.questionType = 1;
    this.validateForm = this.fb.group({
      formLayout: ['inline'],

      title: [null, [Validators.required]],
      questionType: [this.newItem.questionType.toString(), [Validators.required]]
    });

    this.loadData();
  }

  loadData() {
    this.filterSettings.profileId = this.currentProfile.id;
    this.questionService.GetForFilterSettings(this.filterSettings, this.gridSettings.pageIndex, this.gridSettings.pageSize, this.gridSettings.sortField, this.gridSettings.sortAsc ? 1 : -1).subscribe(data => {
      this.items = data;
      this.isLoading = false;
    })
  }

  drop(event: CdkDragDrop<string[]>): void {


    let remaining = this.items.length;
    for (let i = 0; i < this.items.length; i++) {
      if (i == event.previousIndex) {
        this.items[i].viewOrder = event.currentIndex;
      } else if (i == event.currentIndex) {
        this.items[i].viewOrder = event.previousIndex;
      } else {
        this.items[i].viewOrder = i;
      }
      this.questionService.Update(this.items[i]).subscribe(data => {
        remaining--;
        if (remaining == 0) {
          this.loadData();
          this.nzMessageService.info('Updated order.');
        }
      });
    }

    //moveItemInArray(this.listOfData, event.previousIndex, event.currentIndex);
  }

  submitForm(): void {
    let item = new Question();
    item.title = this.validateForm.controls['title'].value;
    item.questionType = +this.validateForm.controls['questionType'].value;
    this.questionService.Add(item).subscribe(data => {
      this.loadData();
    });
    //for (const i in this.validateForm.controls) {
    //  this.validateForm.controls[i].markAsDirty();
    //  this.validateForm.controls[i].updateValueAndValidity();
    //}
  }

  delete(item: Question) {
    this.questionService.Delete(item.id).subscribe(data => {
      this.nzMessageService.info('Deleted question.');
      this.loadData();
    });
  }
  cancelDelete(): void {
    this.nzMessageService.info('Cancelled delete.');
  }
  showModal(questionEdit: Question): void {

    this.question = questionEdit;
    this.unEditedQuestion = questionEdit;
    this.isVisible = true;
  }

  handleOk(questionEdit: Question): void {
    this.questionService.Update(questionEdit).subscribe(data => {
      this.loadData();
    });
    this.nzMessageService.info("Question Updated");
    this.isVisible = false;
  }

  handleCancel(): void {
    this.loadData();
    this.nzMessageService.info('No Changes Made');
    this.isVisible = false;
  }
}

//interface DataItem {
//  name: string;
//  age: number;
//  address: string;
//}
