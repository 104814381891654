import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { TitleEventsEmitter } from '../../core/events/title.events';
import { animations } from 'ack-angular-fx';
import { BaseComponent } from '../shared/base-component/base-component.component';
import { GridSettings } from '../../models/shared/grids/gridSettings';
import { MediaListComponent } from '../shared/media/media-list/media-list.component';
import { FilterSettings } from '../../models/shared/grids/filterSettings';
import { Router, Scroll, Event as RouterEvent } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { filter } from 'rxjs/operators';

const RECORD_COUNT_KEY = 'galary.record.count';

@Component({
  selector: 'gallery-component',
  templateUrl: 'gallery.component.html',
  styleUrls: ['gallery.component.css'],
  animations: [
    animations
  ]
})

export class GalleryComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('mediaList') mediaList: MediaListComponent;
  public filterSettings: FilterSettings;
  gridSettings: GridSettings = new GridSettings();
  view: number = 0;
  row: number = 1;
  lastViewedIndex: number | null = null;
  scrollPositioin: any;
  recordCount: number = 0;
  constructor(
    private titleEventsEmitter: TitleEventsEmitter,
    public router: Router,
    private viewportScroller: ViewportScroller
  ) {
    super();
    this.filterSettings = new FilterSettings();

    this.gridSettings.pageSize = 5;
    this.gridSettings.sortAsc = false;
    this.gridSettings.sortField = "MediaTypeId";

    router.events.pipe(filter((event: RouterEvent,): event is Scroll => event instanceof Scroll)
    ).subscribe(e => {
      this.scrollPositioin = e.position;
    });
  }

  ngAfterViewInit(): void {
    const savedState = localStorage.getItem('profileListState');
    if (savedState) {
      const state = JSON.parse(savedState);
      const pageCount = state.currentPage;
      this.lastViewedIndex = state.lastViewedIndex;
      this.reload(pageCount);
    }
  }

  ngOnInit() {
    this.gridSettings.pageSize = this.getRecordCount();
    this.titleEventsEmitter.broadcastEventTitleChanged("Gallery");
    this.setLoading(true);
  }

  ngOnDestroy() {
    this.saveState();
  }


  private getRecordCount(): number {
    const recordCount = parseInt(sessionStorage.getItem(RECORD_COUNT_KEY), 10);
    return recordCount || this.gridSettings.pageSize;
  }

  private saveState() {
    sessionStorage.setItem(RECORD_COUNT_KEY, this.recordCount.toString());
  }

  onDataLoaded(recordCount) {
    this.recordCount = recordCount;
    if (this.scrollPositioin) {
      setTimeout(() => {
        this.viewportScroller.scrollToPosition(this.scrollPositioin);
        sessionStorage.removeItem(RECORD_COUNT_KEY);
      }, 0)
    }
  }

  loadMore() {
    this.row = this.row + 1;
    this.setLoading(true);
    this.gridSettings.pageIndex = this.row;
    this.mediaList.loadMedia(true, this.gridSettings);

  }

  private reload(pages: number) {
    console.log(pages, 'pages...pages..')
    for (let i = 0; i < pages; i++) {
      this.loadMore();
    }
    this.scrollToLastViewed();
  }

  private scrollToLastViewed() {
    if (this.lastViewedIndex !== null) {
      setTimeout(() => {
        const element = document.getElementById(`user-${this.lastViewedIndex}`);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      });
    }
  }


  onListLoaded($event) {
    this.setLoading(false);
  }

}
