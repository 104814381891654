import { Component, Input, OnChanges, OnInit, Output, EventEmitter, ChangeDetectorRef, NgZone, ViewChild, ViewEncapsulation } from '@angular/core';
import { Configuration } from '../../../app.constants';
import { FilterSettings } from '../../../models/shared/grids/filterSettings';
import { InterestService } from '../../../core/services/interest.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { NzSelectComponent } from 'ng-zorro-antd/select';
//import { debounce } from 'rxjs/operators';
//import * as _ from 'underscore';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css']
})
export class FilterComponent implements OnChanges, OnInit {
  @ViewChild('interestsSelect') interestsSelect: NzSelectComponent;
  @Input() filterSettings: FilterSettings;
  @Input() showGender: boolean;
  @Input() showHeadings: boolean = true;
  @Input() showDates: boolean;
  @Input() profileId: number;
  @Output() onUpdated: EventEmitter<any> = new EventEmitter<any>();
  extrasString = "";
  isExpanded = false;
  myEventsChecked = false;
  isLoading = true;
  inputSize: string = "default";
  listOfInterests: string[] = [];
  listOfSelectedValue = [];
  listOfUnselectedValue = [];


  popupInterestsVisible: boolean;

  colClass = 'col-md-3';

  constructor(
    private configuration: Configuration,
    private interestService: InterestService,
    private cd: ChangeDetectorRef,
    private ngZone: NgZone,
  ) {
    if (this.filterSettings != null) { this.filterSettings = new FilterSettings(); }
    this.keywordChanged.pipe(
      //distinctUntilChanged() // only emit if value is different from previous value
      debounceTime(1000)
    )
      .subscribe(keyword => {
        this.filterSettings.keyword = keyword;
        this.onChanged();
 
      });
  }

  ngOnInit() {
    
    //if (!this.showHeadings) {
    //  this.searchInputCss = "smallInputGroup";
    //  this.searchInputHolderCss = "smallInputHolder";
    //  this.inputSize = "small";
    //} else {
    //  this.searchInputCss = "normalInputGroup";
    //  this.searchInputHolderCss = "normalInputHolder";
    //  this.inputSize = "default";
    //}
   
    this.isLoading = true;

    this.colClass = (this.showDates || this.showGender ? 'col-md-3' : 'col-md-4');
    //this.colSideClass = (this.showDates || this.showGender ? 'col-md-4' : 'col-md-4');

    this.interestService.GetForFilterSettings(this.filterSettings, 0, 200, "Title", 1).subscribe(data => {
      this.listOfInterests = data.map(x => x.title);
      this.listOfSelectedValue = [];
      //this.listOfUnselectedValue = this.listOfInterests.map(x => x);
      this.isLoading = false;
    });

    //if (this.filterSettings) {
    //  this.filterSettings.distance = this.filterSettings.distance.toString();
    //  this.filterSettings.gender = this.filterSettings.gender.toString();
    //}

  }

  ngOnChanges() {
    //if (this.filterSettings) {
    //  this.filterSettings.distance = this.filterSettings.distance.toString();
    //  this.filterSettings.gender = this.filterSettings.gender.toString();
    //}
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  keywordChanged: Subject<string> = new Subject<string>();

  onKeywordChanged($event) {
    this.keywordChanged.next($event);
  }

  updateMyEventsFilter() {
    this.filterSettings.forProfile = this.myEventsChecked;
    this.onChanged();
  }

  onChanged() {
    this.filterSettings.month = +this.filterSettings.month;
    this.filterSettings.gender = +this.filterSettings.gender;
    this.filterSettings.distance = +this.filterSettings.distance;
    this.filterSettings.interests = this.listOfSelectedValue;
   
    //this.listOfSelectedValue = $event;
    this.onUpdated.emit(this.filterSettings);
  }

  onInterestsSelectedUpdated($event) {
    this.listOfSelectedValue = $event.map(x => x);
  
    this.filterSettings.interests = this.listOfSelectedValue;
    //this.listOfSelectedValue = $event;
    //this.interestsSelect.updateListOfContainerItem();//(this.listOfSelectedValue);
    //this.interestsSelect.updateListOfValue();//(this.listOfSelectedValue);
    this.onUpdated.emit(this.filterSettings);

    //this.ngZone.run(() => {
    //  this.listOfSelectedValue = $event;
    //})
    //this.isLoading = true;

    //this.filterSettings.interests = this.listOfSelectedValue;
    //this.cd.detectChanges();
    ////this.isLoading = false;
    //console.log(this.listOfSelectedValue);
    //this.onChanged();
    //console.log($event);
    //if ($event != null) {
    //  this.filterSettings.interests = $event;
    //  this.listOfSelectedValue = $event;
    ////  this.filterSettings = $event;
    ////  this.loadData();
    //}
    
  }

  onInterestsUnselectedUpdated($event) {
    this.listOfUnselectedValue = $event;
  }
  
  onPopupInterestsVisibleChange(value: boolean): void {
  
  }

  showPopupInterests() {
    this.popupInterestsVisible = true;
  }

  showExtraTags() {
    this.extrasString = (this.listOfSelectedValue.length + ' More...');
  }

  hidePopupInterests() {
    this.popupInterestsVisible = false;
  }

}
