import { Component, OnInit, Inject, Input, OnChanges, SimpleChange, ViewChild, HostListener, OnDestroy } from '@angular/core';
import { Media } from '../../../models/Media';
import { MediaService } from '../../../core/services/media.service';
import { BaseComponent } from '../base-component/base-component.component';
import { animations } from 'ack-angular-fx';
import { ProfileService } from '../../../core/services/profile.service';
import { EventService } from '../../../core/services/event.service';
import { Profile } from '../../../models/Profile';
import { Event } from '../../../models/Event';
import * as moment from 'moment';

import { createLocalAudioTrack, Room, LocalTrack, LocalVideoTrack, LocalAudioTrack, RemoteParticipant } from 'twilio-video';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { VideoCameraComponent } from './camera/camera.component';
import { VideoRoomsComponent } from './rooms/rooms.component';
import { VideoSettingsComponent } from './settings/settings.component';
import { VideoParticipantsComponent } from './participants/participants.component';
import { VideoChatService, NamedRoom } from './services/videochat.service';

@Component({
  selector: 'video-chat',
  templateUrl: './video-chat.component.html',
  styleUrls: ['video-chat.component.css'],
  animations: [
    animations,
  ]
})

export class VideoChatComponent extends BaseComponent implements OnInit, OnDestroy {



  @ViewChild('rooms') rooms: VideoRoomsComponent;
  //@ViewChild('camera') camera: VideoCameraComponent;
  @ViewChild('settings') settings: VideoSettingsComponent;
  @ViewChild('participants') participants: VideoParticipantsComponent;
  @Input() event: Event;

  profiles: Profile[];
  events: Event[];

  settingsVisible: boolean = false;
  activeRoom: Room;
  private videoHub: HubConnection;
  videoElement;

  chatParticipants: any[];

  constructor(
    private readonly videoChatService: VideoChatService,
    private profileService: ProfileService
  ) {
    super();
  }

  ngOnInit() {
    this.setLoading(true);

    this.profileService.GetAll("", 0, 10000, "Created", 1).subscribe(data => {
      this.profiles = data;

      const builder =
        new HubConnectionBuilder()
          .configureLogging(LogLevel.Information)
          .withUrl(`${location.origin}/video-socket?user=${this.currentProfile.id}`);

      this.videoHub = builder.build();

      this.videoHub.on('RoomsUpdated', async updated => {
      
        if (updated) {
          await this.rooms.updateRooms();
        }
      });

      this.videoHub.on('UpdateUserList', (data) => {
        
      });


      this.videoHub.start().then(d => {
        
        this.rooms.updateRooms().then(v => {

          let eventRoom = this.rooms.rooms.find(x => x.name == this.event.id.toString());
          if (!eventRoom) {
          
            this.onRoomChanged(this.event.id.toString());
          } else {
      
            this.onRoomChanged(this.event.id.toString());
          }
        });
      });
      //await this.onRoomChanged('Main Room');

      this.setLoading(false);
    });
  }

  ngOnDestroy(): void {
    this.onLeaveRoom(true);
  }

  async onSettingsChanged(deviceInfo?: MediaDeviceInfo) {
    await this.participants.camera.initializePreview(deviceInfo.deviceId);
    //await this.camera.initializePreview(deviceInfo.deviceId);
    if (this.settings.isPreviewing) {
      const track = await this.settings.showPreviewCamera();
      if (this.activeRoom) {
        const localParticipant = this.activeRoom.localParticipant;
        localParticipant.videoTracks.forEach(publication => publication.unpublish());
        await localParticipant.publishTrack(track);
      }
    }
  }

  openSettings() {
    this.settingsVisible = true;
    !this.settingsVisible;
  }
  closeSettings() {
    this.settingsVisible = false;
  }

  async onLeaveRoom(_: boolean) {
    if (this.activeRoom) {
      this.activeRoom.disconnect();
      this.activeRoom = null;
    }

    const videoDevice = this.settings.hidePreviewCamera();
    await this.participants.camera.initializePreview(videoDevice?.deviceId);
    //await this.camera.initializePreview(videoDevice?.deviceId);

    this.participants.clear();
    this.videoChatService.nudge();
  }

  async onRoomChanged(roomName: string) {
    if (roomName) {
      if (this.activeRoom) {
        this.activeRoom.disconnect();
      }

      //this.camera.finalizePreview();

      const tracks = await Promise.all([
        createLocalAudioTrack(),
        this.settings.showPreviewCamera()
      ]);

    
      this.activeRoom = await this.videoChatService.joinOrCreateRoom(roomName, tracks, this.currentProfile.id);

      this.participants.initialize(this.activeRoom.participants);
      this.registerRoomEvents();

      this.chatParticipants = [];
      this.chatParticipants.push({ profile: this.currentProfile });
      this.activeRoom.participants.forEach(p => {

      });

      this.videoHub.send('RoomsUpdated', true);
    }
  }

  onCameraInitialised($event) {
    //this.loadScene(this.selectedScene,$event);

  }

  onParticipantsChanged(_: boolean) {
    this.videoChatService.nudge();
  }

  private registerRoomEvents() {
    this.activeRoom
      .on('disconnected',
        (room: Room) => room.localParticipant.tracks.forEach(publication => this.detachLocalTrack(publication.track)))
      .on('participantConnected',
        (participant: RemoteParticipant) => this.participants.add(participant))
      .on('participantDisconnected',
        (participant: RemoteParticipant) => this.participants.remove(participant))
      .on('dominantSpeakerChanged',
        (dominantSpeaker: RemoteParticipant) => this.participants.loudest(dominantSpeaker));
  }

  private detachLocalTrack(track: LocalTrack) {
    if (this.isDetachable(track)) {
      track.detach().forEach(el => el.remove());
    }
  }

  private isDetachable(track: LocalTrack): track is LocalAudioTrack | LocalVideoTrack {
    return !!track
      && ((track as LocalAudioTrack).detach !== undefined
        || (track as LocalVideoTrack).detach !== undefined);
  }

  getImageUrl = (p: Media, size: number): string => { return this.globalService.getMediaUrl(p, size); }

  getTimeAgo = (d: Date): string => { return moment(d).fromNow(); }

}
