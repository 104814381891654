import { Title } from '@angular/platform-browser';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class TitleEventsEmitter {
  private viewTitle = new Subject<string>();
  viewTitle$ = this.viewTitle.asObservable();

  constructor(

    private title: Title
  ) {
  }

  broadcastEventTitleChanged(title: string) {
    this.viewTitle.next(title);
    this.title.setTitle(title + (title.length > 0 ? ' | ' : '') + 'Otto | The Otto Connection');
  }
}
