import { ElementRef, Injectable, Input, NgZone } from '@angular/core';
import { Subscription } from 'rxjs';
import { ProfileService } from '../../../core/services/profile.service';

declare var d3: any;
declare var goUrl: any;
@Injectable()
export class D3Graph {
  public graphContainer: ElementRef;
  public svgElement: ElementRef;
  public zone;
  public subscriptions: Subscription[];
  public profileService: ProfileService;
  tempValue = 0;
  public svg;
  public simulation;
  //public color;
  private link;
  private lines;
  private node;
  private circles;
  private defs;
  private labels;
  private lineLabels;
  @Input() setWidth: number = 300;
  @Input() setHeight: number = 300;
  public width: number;
  public height: number;
  private nodes = [];
  private links = [];
  private currentProfileId: number;

  public chargeStrength: number = 1.0;
  //public centerStrength: number = 0.01;
  public collideStrength: number = 1.0;
  public collideRadius: number = 30.0;
  public circleRadiusMax: number = 14.0;
  public maxTextOpacity: number = 1.0;
  public maxLineOpacity: number = 1.0;
  public maxLineWidth: number = 8.0;
  public maxNodeOpacity: number = 1.0;

  public fadeDurationMS: number = 500;
  public hoverMultiplier: number = 4;

  public showLinks: boolean = true;
  public showLineLabels: boolean = false;
  public showLabels: boolean = true;
  public allowHover: boolean = true;

  constructor(zone: NgZone, subscriptions: Subscription[], profileService: ProfileService, graphContainer: ElementRef, svgElement: ElementRef) {
    this.graphContainer = graphContainer;
    this.svgElement = svgElement;
    this.zone = zone;
    this.subscriptions = subscriptions;
    this.profileService = profileService;

    this.nodes = [];
    this.links = [];
  }

  unique = arr => arr.filter((d, i) => arr.indexOf(d) === i);

  public init() {
    this.width = this.setWidth;
    this.height = this.setHeight;

    this.svg = d3.select('.svg');
    this.simulation = d3.forceSimulation()
      .force('link', d3.forceLink().distance((d) => { return 300; }).id((d) => { return d.id; }))
      .force('charge', d3.forceManyBody().strength(-2600))
      .force('center', d3.forceCenter(this.width / 2, this.height / 2));
  //.force('link', d3.forceLink().links(links));
  }

  public startGraph(newNodes, newLinks, currentProfileId) {


    this.nodes = newNodes;
    this.links = newLinks;
    this.currentProfileId = currentProfileId;

    this.fixDataValues();

    const tMax = newNodes.reduce(function (prev, current) {
      return (prev.group > current.group) ? prev : current
    }) //returns object

    this.width = (tMax.group / 10) * 250;
    this.height = (tMax.group / 10) * 250;


    this.reset(newNodes, newLinks);
  }

  public buildNodes() {
    this.link = this.svg.append('g')
      .attr('class', 'links')
      .selectAll('g')
      .data(this.links)
      .enter().append('g');

    this.lines = this.link
      .append('line')
      .attr('stroke-width', (c) => { return 2.0 });

    if (this.showLineLabels) {
      this.lineLabels = this.link
        .append('text')
        .attr('class', 'link-label')
        .attr('dx', '0')
        .attr('dy', '8px')
        .attr('text-anchor', 'middle')
        .attr('fill-opacity', (d) => { return this.getLineOpacity(d); })
        .text((d) => { return Math.round(d.value); })
    }

    this.node = this.svg.append('g')
      .attr('class', 'nodes')
      .selectAll('g')
      .data(this.nodes)
      .enter()
      .append('g')
      .attr('class', (d) => { return (+d.id === this.currentProfileId ? 'active' : ''); });

    this.defs = this.svg.append('svg:defs');

    this.nodes.forEach((d) => {
      let avatarSize = d.radius * 2.0;
      this.defs.append('svg:pattern')
        .attr('id', 'avatar-image' + d.id)
        .attr('width', avatarSize)
        .attr('height', avatarSize)
        .attr('patternUnits', 'userSpaceOnUse')
        .append('svg:image')
        .attr('xlink:href', d.img + '?stamp=' + Date.now())
        .attr('width', avatarSize)
        .attr('height', avatarSize)
        .attr('x', 0)
        .attr('y', 0);
    });

    this.circles = this.node.append('circle')
      .attr('class', 'circles')
      .attr('cx', (d) => { return d.radius; })
      .attr('cy', (d) => { return d.radius; })
      .attr('r', (d) => { return d.radius; })
      .style('fill', (d) => { return 'url(#avatar-image' + d.id + ')' });

    if (this.showLabels) {
      this.labels = this.node.append('text')
        .text((d) => { return d.title; })
        .attr('x', (d) => { return d.radius; })
        .attr('y', (d) => { return d.radius + 66; })
        .attr('text-anchor', 'middle')
        .attr('fill-opacity', (d) => { return 1.0; });

      this.node.append('title')
        .text((d) => { return d.title; });
    }

    this.node.on('click', (d) => {
      goUrl('/members/' + d.id);
    });

    this.startSimulation();
  }

  public startSimulation() {
    let ids: number[] = this.nodes.map(x => parseInt(x.id));
    let min: number = Math.min.apply(null, ids);
    let max: number = Math.max.apply(null, ids);
    let range = (max - min);
    if (range < 1) range = 1;

    this.simulation
      .nodes(this.nodes)
      .on('tick', () => { return this.ticked() });

    if (this.showLinks) {
      this.simulation
        .force('link')
        .links(this.links);
    }

    this.simulation.restart();
  }

  public fixDataValues() {
    this.nodes.forEach(x => {
      x.group = Math.min(this.links.filter(y => y.source.toString() == x.id.toString() || y.target.toString() == x.id.toString()).length, 10.0) * 10.0;
    });
    this.nodes.forEach(x => x.radius = 45);
    this.links.forEach(x => {
      let sc = this.links.filter(y => y.source == x.target || y.source == x.source).length;
      let tc = this.links.filter(y => y.target == x.target || y.source == x.source).length;
      x.value = Math.min((sc + tc) / 10.0, 1.0) * 100.0;
    });

  }

  public fixDataValues2() {
    this.nodes.forEach(x => {
      x.group = this.links.filter(y => y.source.toString() == x.id.toString() || y.target.toString() == x.id.toString()).length;
    });
    this.nodes.forEach(x => x.radius = 45);
    this.links.forEach(x => {
      x.value = this.nodes.find(y => y.id.toString() == x.source.toString())?.group + this.nodes.find(y => y.id.toString() == x.target.toString())?.group;
      x.value *= 10;
    });

  }

  public ticked() {
    if (this.node && this.lines) {
      this.lines
        .attr('x1', (d) => { return d.source.x + 52.0; })
        .attr('y1', (d) => { return d.source.y + 52.0; })
        .attr('x2', (d) => { return d.target.x + 52.0; })
        .attr('y2', (d) => { return d.target.y + 52.0; });

      if (this.showLineLabels) {
        this.lineLabels
          .attr('x', (d) => {
            return (d.source.x + d.target.x) / 2;
          })
          .attr('y', (d) => {
            return (d.source.y + d.target.y) / 2;
          });
      }

      this.node
        .attr('transform', (d) => {
          return 'translate(' + d.x + ',' + d.y + ')';
        })
    } else {
    }
  }

  public dragstarted(d) {
    if (!d3.event.active) this.simulation.alphaTarget(0).restart();
    d.fx = d.x;
    d.fy = d.y;
  }

  public dragged(d) {
    d.fx = d3.event.x;
    d.fy = d3.event.y;
  }

  public dragended(d) {
    if (!d3.event.active) this.simulation.alphaTarget(0);
    d.fx = null;
    d.fy = null;
  }

  public reset(newNodes, newLinks) {
    this.nodes = newNodes;
    this.links = newLinks;
    if (this.link) {
      this.link = this.link.data([]);
      this.link.exit().remove().exit().remove();
      this.link = null;
      this.node = this.node.data([]);
      this.node.exit().remove().exit().remove();
      this.node = null;
      this.circles.exit().remove();
      this.circles = null;
      this.labels.exit().remove();
      this.labels = null;
      this.defs.exit().remove();
      this.defs = null;
      d3.selectAll('.links').remove();
      d3.selectAll('.nodes').remove();
    }
    this.buildNodes();
  }

  public animTransition(d) {
    return d
      .transition()
      .duration(this.fadeDurationMS);
  }

  public getNodeOpacity(d, hover: boolean = false) {
    return 1.0;
  }

  public getLineOpacity(val: number, hover: boolean = false) {
    return hover ? 1.0 : Math.max(0.75, this.maxLineOpacity * val / 100.0);
  }

  public normalise(v: number, min: number, max: number, multiplier: number = 1) {
    v = v < min ? min : v > max ? max : v;
    v *= multiplier;
    v = v < min ? min : v > max ? max : v;
    return v;
  }
}
