import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
//import { ToasterService } from 'angular2-toaster';
import { Configuration } from '../../app.constants';
import { Media } from '../../models/Media';
import { Profile } from '../../models/Profile';
import { NewsItem } from '../../models/NewsItem';
import { Event } from '../../models/Event';
import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import { filter } from 'rxjs/operators';
//import { Http, Response, Headers, RequestOptions } from '@angular/http';

@Injectable()
export class GlobalService {
  private notify = new Subject<any>();
  notifyObservable$ = this.notify.asObservable();

  public progress = new Subject<any>();
  progressObservable$ = this.progress.asObservable();

  public navigating = new Subject<any>();
  navigatingObservable$ = this.navigating.asObservable();

  public audio = new Subject<any>();
  audioObservable$ = this.audio.asObservable();

  public imagePlaceholder: string = '/assets/img/placeholder.png';

  constructor(
    //private toasterService: ToasterService,
    private http: HttpClient,
    private configuration: Configuration
  ) {
    //this.actionUrl = configuration.Server + 'v2/course/';
    //this.getAllCoursesWithClassesUrl = 'v2/course/api/getAllSchoolCourses/';
  }

  public startNavigating() {
    this.navigating.next(true);
  }

  public startProgress() {
    this.progress.next(true);
  }

  public completeProgress(msg: string) {
    this.progress.next(msg);
  }



  public componentCommand(command: string): void {
    this.notify.next(command);
  }

  public authCommand(command: string): void {
    this.notify.next(command);
  }

  //Arrays
  public arrayContains(a: number[], i: number): boolean {
    return i == 0 || (a != null && a.indexOf(parseInt(i.toString())) > -1);
  }

  //Number
  public random(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  //Dates
  public addDays(d: Date, days: number) {
    return new Date(d.getTime() + days * 24 * 60 * 60 * 1000);
  }

  //Geography
  public getDistanceKmText(DistanceInMetres: number): string {
    if (DistanceInMetres == undefined) {
      return '';
    }
    return (DistanceInMetres / 1000).toFixed(2).toString() + 'km';
  }

  //Color
  public convertHex(hex: string, opacity: number) {
    //convert Hex to RGBA
    hex = hex.replace('#', '');
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);

    let rgba = 'rgba(' + r + ',' + g + ',' + b + ',' + opacity / 100 + ')';
    return rgba;
  }

  public isMobileDevice(): boolean {
    return (typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1);
  }

  public initScrollTransformations(): any[] {
    let a = [];
    (<any>document.querySelectorAll('.page-section-darken-bottom')).forEach(e => a.push(e));
    (<any>document.querySelectorAll('.page-section-darken-top')).forEach(e => a.push(e));
    (<any>document.querySelectorAll('.page-section-plx')).forEach(e => a.push(e));
    return a;
  }

  public applyScrollTransformations(elements: any[]) {
    elements.forEach(e => {
      if (e.className.indexOf('page-section-darken-bottom') >= 0) {
        let elY = e.getBoundingClientRect().top;
        let o = 1.0 - (Math.max(Math.min(Math.abs(elY) / 500, 1), 0));
        e.style.opacity = o.toString();
      } else if (e.className.indexOf('page-section-darken-top') >= 0) {
        let elY = e.getBoundingClientRect().top;
        let o = (Math.max(Math.min(Math.abs(elY) / 500, 1), 0));
        e.style.opacity = o.toString();//(ce.offsetHeight + Math.round(d * 0.5)).toString() + 'px';
      } else if (e.className.indexOf('page-section-plx') >= 0) {
        let elInner = e.querySelector('.plx-inner');
        let offsetY = parseInt((e.getBoundingClientRect().top / 2.0).toString());
        elInner.style.top = "-" + offsetY + "px";
      }
    });
  }

  public getProfileImageUrl(m: Profile, size: number) {
    return this.getCdnUrl('profile', m?.imageUrl, m?.id, size);
    //if (p.imageUrl) {
    //  let path = p.imageUrl;
    //  switch (size) {
    //    case 1200: path = "1200/1200x900.jpg"; break;
    //    case 800: path = "800/800x600.jpg"; break;
    //    case 256: path = "256/256x192.jpg"; break;
    //  }

    //  return this.configuration.CDNUrl + '/profile/' + p.id + '/' + path;// + '?random=' + Math.random();// + '?t=' + parseInt((Math.random() * 1000).toString()
    //} else {
    //  return './assets/img/avatar.png';
    //}
  }

  public getEventImageUrl(m: Event, size: number) {
    if (m?.imageUrl?.indexOf('assets/img') > 0) return m.imageUrl;
    //if (m?.imageUrl?.indexOf('http') >= 0) m.imageUrl;
    return this.getCdnUrl('event', m?.imageUrl, m?.id, size);
    //if (p.imageUrl) {
    //  let path = p.imageUrl;
    //  switch (size) {
    //    case 1200: path = "1200/1200x900.jpg"; break;
    //    case 800: path = "800/800x600.jpg"; break;
    //    case 256: path = "256/256x192.jpg"; break;
    //  }

    //  return this.configuration.CDNUrl + '/profile/' + p.id + '/' + path;// + '?random=' + Math.random();// + '?t=' + parseInt((Math.random() * 1000).toString()
    //} else {
    //  return './assets/img/avatar.png';
    //}
  }

  

  public getNewsItemUrl(m: NewsItem, size: number) {
    return this.getCdnUrl('news-items', m?.imageFilename, m?.id, size);
    //if (m.imageFilename) {
    //  let path = m.imageFilename;
    //  switch (size) {
    //    case 1200: path = "1200/1200x900.jpg"; break;
    //    case 800: path = "800/800x600.jpg"; break;
    //    case 256: path = "256/256x192.jpg"; break;
    //  }

    //  return this.configuration.CDNUrl + '/news-items/' + m?.id + '/' + path;// + '?random=' + Math.random();// + '?t=' + parseInt((Math.random() * 1000).toString()
    //} else {
    //  return './assets/img/no-image.png';
    //}
  }

  public getMediaUrl(m: Media, size: number) {
    return this.getCdnUrl('media', m?.filename, m?.id, size);
    //let path = m.filename;
    //switch (size) {
    //  case 1200: path = "1200/1200x900.jpg"; break;
    //  case 800: path = "800/800x600.jpg"; break;
    //  case 256: path = "256/256x192.jpg"; break;
    //}
    //if (m.filename && m.filename.length > 0) {
    //  return this.configuration.CDNUrl + '/media/' + m?.id + '/' + path;// + '?random=' + Math.random();
    //} else {
    //  return '/assets/img/placeholder.png';
    //}
  }

  public getCdnUrl(container: string, imageFilename: string, id: number, size: number, placeholderPath: string = './assets/img/no-image.png') {
    if (imageFilename) {
      let path = imageFilename;

      path = path.replace("https://ottostoragelive.blob.core.windows.net/" + container + "/" + id +"/","")

      let extension = path.slice((path.lastIndexOf(".") - 1 >>> 0) + 2);

      switch (size) {
        case 1200: path = "1200/1200x900." + extension; break;
        case 800: path = "800/800x600." + extension; break;
        case 256: path = "256/256x192." + extension; break;
      }
      return this.configuration.CDNUrl + '/' + container + '/' + id + '/' + path;// + '?random=' + Math.random();// + '?t=' + parseInt((Math.random() * 1000).toString()
    } else {
      return placeholderPath;
    }
  }

  public playAudio(name: string) {
    this.audio.next(name);
  }

  public calcDistanceKM(lat1, lon1, lat2, lon2) {
    let R = 6371; // km
    let dLat = this.toRad(lat2 - lat1);
    let dLon = this.toRad(lon2 - lon1);
    let dlat1 = this.toRad(lat1);
    let dlat2 = this.toRad(lat2);

    let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(dlat1) * Math.cos(dlat2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = R * c;
    return d;
  }

  public capitalize(s) {
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  // Converts numeric degrees to radians
  toRad(Value) {
    return Value * Math.PI / 180;
  }
}
