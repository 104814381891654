export class QuestionAnswer {
  id: number;
  profileId: number;
  questionId: number;
  answer: string;
  created: Date;

  constructor() {
  }

  static create(profileId: number, questionId: number, answer: string) {
    let m = new QuestionAnswer()
    m.questionId = questionId;
    m.profileId = profileId;
    m.answer = answer;
    return (m);
  }
}
