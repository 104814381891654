import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient } from "@angular/common/http";
import { AuthenticationService } from './authentication.service';
import { Configuration } from '../../app.constants';
import { Profile } from '../../models/Profile';
import { FilterSettings } from '../../models/shared/grids/filterSettings';
import { GridSettings } from '../../models/shared/grids/gridSettings';

@Injectable()
export class ProfileService {
  private isBrowser: boolean;
  private actionUrl: string;

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    configuration: Configuration,
    private authenticationService: AuthenticationService
  ) {
    this.actionUrl = configuration.Server + 'v1/Profile/';
    //http.get<WeatherForecast[]>(baseUrl + 'weatherforecast').subscribe(result => {
    //   this.forecasts = result;
    //}, error => console.error(error));
  }

  public GetAll = (keyword: string, row: number, pageSize: number, sortField: string, sortOrder: number): Observable<Profile[]> => {
    let url = this.actionUrl + 'all?' + '&keyword=' + keyword + '&row=' + row + '&pageSize=' + pageSize + '&sortField=' + sortField + '&sortOrder=' + sortOrder;
    return this.http.post<Profile[]>(url, '', this.authenticationService.getHttpOptions());
  }
  public GetAllCount = (keyword: string): Observable<number> => {
    return this.http.post<number>(this.actionUrl + 'allcount?keyword=' + keyword, '', this.authenticationService.getHttpOptions());
  }
  public Get = (id: number): Observable<Profile> => {

    return this.http.post<Profile>(this.actionUrl + id, "", this.authenticationService.getHttpOptions());
  }

  public Block = (id: number): Observable<Profile> => {
    return this.http.post<Profile>(this.actionUrl + 'block/' + id, JSON.stringify({ profileId: id }), this.authenticationService.getHttpOptions());
  }



  public Add = (item: Profile): Observable<Profile> => {
    return this.http.post<Profile>(this.actionUrl, JSON.stringify(item), this.authenticationService.getHttpOptions());
  }

  public Update = (item: Profile): Observable<Profile> => {

    return this.http.put<Profile>(this.actionUrl, JSON.stringify(item), this.authenticationService.getHttpOptions());
  }
  public Delete = (id: number): Observable<any> => {
    return this.http.delete(this.actionUrl + id, this.authenticationService.getHttpOptions());
  }
  public GetByEmailAddress = (emailAddress: string): Observable<Profile> => {
    return this.http.post<Profile>(this.actionUrl + 'byEmailAddress', JSON.stringify(emailAddress), this.authenticationService.getHttpOptions());
  }
  public GetByToken = (token: string): Observable<Profile> => {
    return this.http.post<Profile>(this.actionUrl + 'byToken', JSON.stringify(token), this.authenticationService.getHttpOptions());
  }
  public GetByEncryptedId = (encryptedId: string): Observable<Profile> => {
    return this.http.post<Profile>(this.actionUrl + 'byEncryptedId', JSON.stringify(encryptedId), this.authenticationService.getHttpOptions());
  }
  public VerifyEmail = (encryptedId: string): Observable<Profile> => {
    return this.http.post<Profile>(this.actionUrl + 'verifyEmail', JSON.stringify(encryptedId), this.authenticationService.getHttpOptions());
  }
  public SendVerificationEmail = (item: Profile): Observable<Profile> => {

    return this.http.post<Profile>(this.actionUrl + 'sendVerificationEmail', JSON.stringify(item), this.authenticationService.getHttpOptions());
  }
  public SendCompletionEmail = (item: Profile): Observable<Profile> => {

    return this.http.post<Profile>(this.actionUrl + 'sendCompletionEmail', JSON.stringify(item), this.authenticationService.getHttpOptions());
  }
  public Invite = (emailAddress: string, byProfileId: number): Observable<Profile> => {
    return this.http.post<Profile>(this.actionUrl + 'invite', JSON.stringify({ emailAddress: emailAddress, byProfileId: byProfileId }), this.authenticationService.getHttpOptions());
  }
  public InviteTribeFounder = (emailAddress: string, byProfileId: number, tribeId: number): Observable<Profile> => {
    return this.http.post<Profile>(this.actionUrl + 'founderInvite', JSON.stringify({ emailAddress: emailAddress, byProfileId: byProfileId, tribeId: tribeId }), this.authenticationService.getHttpOptions());
  }

  public AcceptInvite = (token: string): Observable<Profile> => {
    return this.http.post<Profile>(this.actionUrl + 'acceptInvite?token=' + token, "", this.authenticationService.getHttpOptions());
  }

  public AcceptFounderInvite = (token: string): Observable<Profile> => {
    return this.http.post<Profile>(this.actionUrl + 'acceptInvite?token=' + token, "", this.authenticationService.getHttpOptions());
  }
  public AddInterest = (item: Profile, interestId: number): Observable<Profile> => {
    return this.http.post<Profile>(this.actionUrl + 'addInterest/' + interestId, JSON.stringify(item), this.authenticationService.getHttpOptions());
  }
  public RemoveInterest = (item: Profile, interestId: number): Observable<Profile> => {
    return this.http.post<Profile>(this.actionUrl + 'removeInterest/' + interestId, JSON.stringify(item), this.authenticationService.getHttpOptions());
  }

  public GetForFilterSettings = (filterSettings: FilterSettings, row: number, pageSize: number, sortField: string, sortOrder: number): Observable<Profile[]> => {
    let url = this.actionUrl + 'forFilterSettings?row=' + row + '&pageSize=' + pageSize + '&sortField=' + sortField + '&sortOrder=' + sortOrder;
    return this.http.post<Profile[]>(url, JSON.stringify(filterSettings), this.authenticationService.getHttpOptions());
  }

  public GetForAdmin = (filterSettings: FilterSettings, row: number, pageSize: number, sortField: string, sortOrder: number): Observable<any> => {
    let url = this.actionUrl + 'forAdmin?row=' + row + '&pageSize=' + pageSize + '&sortField=' + sortField + '&sortOrder=' + sortOrder;
    return this.http.post<any>(url, JSON.stringify(filterSettings), this.authenticationService.getHttpOptions());
  }

  public ApproveProfile = (id: number): Observable<any> => {
    let url = this.actionUrl + 'ApproveProfile?id=' + id;
    return this.http.post<any>(url, null, this.authenticationService.getHttpOptions());
  }

  public GetForInvitation = (filterSettings: FilterSettings, row: number, pageSize: number, sortField: string, sortOrder: number): Observable<any> => {
    let url = this.actionUrl + 'forInvitation?row=' + row + '&pageSize=' + pageSize + '&sortField=' + sortField + '&sortOrder=' + sortOrder;
    return this.http.post<any>(url, JSON.stringify(filterSettings), this.authenticationService.getHttpOptions());
  }

  public GetForFilterSettingsPublic = (filterSettings: FilterSettings, row: number, pageSize: number, sortField: string, sortOrder: number): Observable<Profile[]> => {
    let url = this.actionUrl + 'forFilterSettingsPublic?row=' + row + '&pageSize=' + pageSize + '&sortField=' + sortField + '&sortOrder=' + sortOrder;
    return this.http.post<Profile[]>(url, JSON.stringify(filterSettings), this.authenticationService.getHttpOptions());
  }

  public GetForSettings = (filterSettings: FilterSettings, gridSettings: GridSettings): Observable<Profile[]> => {
    return this.GetForFilterSettings(filterSettings, gridSettings.pageIndex, gridSettings.pageSize, gridSettings.sortField, (gridSettings.sortAsc ? 1 : -1));
  }

  public GetConnectedGraph = (profileId: number, profileId2: number): Observable<any> => {
    return this.http.post<any>(this.actionUrl + 'connectedgraph/' + profileId + "/" + profileId2, "", this.authenticationService.getHttpOptions());
  }

  public GetGraph = (profileId: number): Observable<any> => {
    return this.http.post<any>(this.actionUrl + 'graph/' + profileId, "", this.authenticationService.getHttpOptions());
  }
  public GetGraphAll = (): Observable<any> => {
    return this.http.post<any>(this.actionUrl + 'graph', "", this.authenticationService.getHttpOptions());
  }

  public CreateTestData = (): Observable<any> => {
    return this.http.post<any>(this.actionUrl + 'createTestData', "", this.authenticationService.getHttpOptions());
  }

  //public RestoreProfiles = (): Observable<any> => {
  //  return this.http.post<any>(this.actionUrl + 'restoreProfiles', "", this.authenticationService.getHttpOptions());
  //}




  public PostProcess = (): Observable<any> => {
    return this.http.post<any>(this.actionUrl + 'postProcess', "", this.authenticationService.getHttpOptions());
  }

  public GetInviteCount = (profileId: number): Observable<number> => {
    return this.http.post<any>(this.actionUrl + 'getInviteCount?profileId=' + profileId, "", this.authenticationService.getHttpOptions());
  }

}
