import { Component, OnInit, Inject, Input, OnChanges, SimpleChange, ViewChild, EventEmitter, Output } from '@angular/core';
import { Media } from '../../../../models/Media';
import { MediaService } from '../../../../core/services/media.service';
import { BaseComponent } from '../../base-component/base-component.component';
import { animations } from 'ack-angular-fx';
import { ProfileService } from '../../../../core/services/profile.service';
import { EventService } from '../../../../core/services/event.service';
import { Profile } from '../../../../models/Profile';
import { Event } from '../../../../models/Event';
import * as moment from 'moment';
import { GridSettings } from '../../../../models/shared/grids/gridSettings';
import { FilterSettings } from '../../../../models/shared/grids/filterSettings';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'media-list',
  templateUrl: './media-list.component.html',
  styleUrls: ['media-list.component.css'],
  animations: [
    animations,
  ]
})

export class MediaListComponent extends BaseComponent implements OnInit {
  @Input() mediaType: number;
  @Input() mediaTypeId: number;
  @Input() additionalMediaType: number;
  @Input() allowDelete: boolean;
  @Input() showPrivate: boolean = true;
  @Input() gridSettings: GridSettings;
  @Input() filterSettings: FilterSettings;
  @Output() onDataLoaded = new EventEmitter<number>();
  items: Media[] = [];
  profiles: Profile[] = [];
  events: Event[] = [];

  constructor(
    private profileService: ProfileService,
    private eventService: EventService,
    private mediaService: MediaService,
    private modal: NzModalService
  ) {
    super();
  }

  ngOnInit() {
    //if (!this.filterSettings) {
    //  this.filterSettings.profileId = this.currentProfile.id;
    //}
    this.load();
  }

  //ngOnChanges(changes: { [propName: string]: SimpleChange }) {
  //  console.log(changes);
  //  if (this.items && changes['gridSettings'] && changes['gridSettings'].previousValue != changes['gridSettings'].currentValue) {
  //    this.loadMedia();
  //  } else {
  //  }
  //}
  ngOnChanges() {
    this.load();
  }
  load() {
    console.log('loading.. galary..')

    this.setLoading(true);
    if (!this.gridSettings) this.gridSettings = new GridSettings();
    this.filterSettings.showApproved = true;
    this.eventService.GetForFilterSettings(this.filterSettings, 0, 1000, "Created", 0).subscribe(data2 => {
      this.events = data2.model;
      console.log(this.events, 'loaded events')
      this.loadMedia(false);
    });
  }

  loadMedia(append: boolean, gridSettings: GridSettings = null) {
    if (gridSettings) this.gridSettings = gridSettings;

    this.mediaService.GetForSettings(this.filterSettings, this.mediaType, this.mediaTypeId, "", this.gridSettings, this.additionalMediaType, this.showPrivate)
      .subscribe(data3 => {
        console.log(data3, 'ss')
        data3.forEach(m => {
          (<any>m).event = this.events.find(x => x.id == m.mediaTypeId);



        });
        if (append) {
          data3.forEach(m => this.items.push(m));
        } else {
          this.items = data3;/*.filter(x => x.tribeId == this.currentProfile.tribeId);*/
        }
        this.onDataLoaded.emit(this.items.length);
        this.setLoading(false);
      });
  }

  showDeleteConfirm(id: number): void {
    this.modal.confirm({
      nzTitle: 'Are you sure you want to delete this item?',
      nzContent: 'This action cannot be undone.',
      nzOkText: 'Yes',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => this.deleteItem(id),
      nzCancelText: 'No',
      nzOnCancel: () => console.log('Delete canceled')
    });
  }

  deleteItem(id: number) {

    this.setLoading(true);
    this.mediaService.Delete(id).subscribe(data => {

      this.load();
      this.setLoading(false);


    });


  }

  getImageUrl = (p: Media, size: number): string => { return this.globalService.getMediaUrl(p, size); }

  getTimeAgo = (d: Date): string => { return moment(d).fromNow(); }

}
