import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Profile } from '../../../models/Profile';
import { ProfileService } from '../../../core/services/profile.service';
import { BaseComponent } from '../../shared/base-component/base-component.component';
import { LoginEventsEmitter } from '../../../core/events/login.events';

@Component({
  templateUrl: 'join.component.html',
  //styleUrls: ['resetPassword.component.css']
})

export class ActionJoinComponent extends BaseComponent implements OnInit, OnDestroy {

  public t: string = "";
  public message: string = "";
  public returnUrl: string = "";

  public profile: Profile = new Profile();

  constructor(
    private route: ActivatedRoute,
    private profileService: ProfileService,
    private loginEventsEmitter: LoginEventsEmitter
  ) {
    super();
  }

  ngOnInit() {
    if (this.authenticationService.getCurrentProfile()) {
      this.authenticationService.logout();
     this.loginEventsEmitter.broadcastEventProfileLoginChanged(null);
    }
   

    this.route.params.subscribe(params => {
      let t = this.route.snapshot.queryParamMap.get('t');
      sessionStorage.setItem('t', t);

      this.profileService.AcceptInvite(t).subscribe(
        data => {
          this.profile = data;
          console.log(this.profile);
          //this.profile.invitedBy = data.id;
          localStorage.setItem('currentProfile', JSON.stringify(this.profile));
          
          this.nav("/registration");
        },
        error => {
          //if (error.status == 404) {
          //    this.message = "Email or password not found"
          //} else {
          //    this.message = "Error logging in"
          //}
          this.msgService.error('This invite link has already been used. Please request another invite.');
        }
      );
    });
  }

}
