import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProfileService } from '../../../core/services/profile.service';
import { UntypedFormBuilder } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NewsItemService } from '../../../core/services/news-item.service';
import { Configuration } from '../../../app.constants';
import { GlobalService } from '../../../core/services/global.service';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { BaseComponent } from '../../shared/base-component/base-component.component';
import { Router } from '@angular/router';

@Component({
  selector: 'admin-settings',
  styleUrls: ['./settings.component.css'],
  templateUrl: './settings.component.html',
})
export class AdminSettingsComponent extends BaseComponent implements OnInit, OnDestroy {
  debugOut: string;

  //public items: NewsItem[];
  //public item: NewsItem;

  //gridSettings: GridSettings;

  //total = 1;
  //addForm!: FormGroup;
  //editForm!: FormGroup;

  //previewImage: string | undefined = '';
  //previewVisible = false;

  constructor(
    router: Router,
    globalService: GlobalService,
    authenticationService: AuthenticationService,
    private newsItemService: NewsItemService,
    private msg: NzMessageService,
    private configuration: Configuration,
    private fb: UntypedFormBuilder,
    private nzMessageService: NzMessageService,
    private profileService: ProfileService
  ) {
    super();
    //this.gridSettings = new GridSettings();
    //this.gridSettings.sortField = "Created";
    //this.gridSettings.sortAsc = false;
    //this.gridSettings.pageSize = 50;
    //this.gridSettings.pageIndex = 0;
  }

  ngOnInit() {
    this.currentProfile = this.authenticationService.getCurrentProfile();
    this.load();
  }

  load() {
    //this.newsItemService.GetAll("", this.gridSettings.pageIndex, this.gridSettings.pageSize, this.gridSettings.sortField, this.gridSettings.sortAsc ? 1 : -1).subscribe(data => {
    //  this.items = data;
    //  this.setLoading(false);

    //  if (!this.item) {
    //    this.addForm = this.fb.group({
    //      formLayout: ['inline'],
    //      title: [null, [Validators.required]]
    //    });
    //  } else {
    //    this.item = this.items.find(x => x.id == this.item.id);
    //    this.editForm = this.fb.group({
    //      //formLayout: ['inline'],
    //      title: [this.item.title, [Validators.required]],
    //      description: [this.item.description, [Validators.required]],
    //      link: [this.item.link, [Validators.required]],
    //      status: [this.item.status.toString(), [Validators.required]]
    //    });
    //  }
    //});
    this.setLoading(false);
  }

  createTestData() {
    this.setLoading(true);
    this.profileService.CreateTestData().subscribe(data => {
      this.debugOut += 'Finished\n';
      this.setLoading(false);
    });
  }

  postProcessA() {
    this.setLoading(true);
    this.profileService.PostProcess().subscribe(data => {
      this.debugOut += 'Finished\n';
      this.setLoading(false);
    });
  }

  //edit(id: number) {
  //  this.setLoading(true);

  //  this.item = this.items.find(x => x.id == id);
  //  this.load();
  //}

  //drop(event: CdkDragDrop<string[]>): void {

  //  //let remaining = this.items.length;
  //  //for (let i = 0; i < this.items.length; i++) {
  //  //  if (i == event.previousIndex) {
  //  //    this.items[i].viewOrder = event.currentIndex;
  //  //  } else if (i == event.currentIndex) {
  //  //    this.items[i].viewOrder = event.previousIndex;
  //  //  } else {
  //  //    this.items[i].viewOrder = i;
  //  //  }
  //  //  this.interestService.Update(this.items[i]).subscribe(data => {
  //  //    remaining--;
  //  //    if (remaining == 0) {
  //  //      this.loadData();
  //  //      this.nzMessageService.info('Updated order.');
  //  //    }
  //  //  });
  //  //}

  //  //moveItemInArray(this.listOfData, event.previousIndex, event.currentIndex);
  //}

  //add(): void {
  //  this.setLoading(true);

  //  let item = new NewsItem();
  //  item.profileId = this.currentProfile.id;
  //  item.status = 2;
  //  item.title = this.addForm.controls['title'].value;
  //  this.newsItemService.Add(item).subscribe(data => {
  //    this.load();
  //  });
  //  //for (const i in this.addForm.controls) {
  //  //  this.addForm.controls[i].markAsDirty();
  //  //  this.addForm.controls[i].updateValueAndValidity();
  //  //}
  //}

  //delete(item: NewsItem) {
  //  this.setLoading(true);

  //  this.newsItemService.Delete(item.id).subscribe(data => {
  //    this.nzMessageService.info('Deleted news item.');
  //    this.load();
  //  });
  //}

  //cancelDelete(): void {
  //  this.nzMessageService.info('Cancelled delete.');
  //}

  //save() {
  //  this.setLoading(true);

  //  this.newsItemService.Update(this.item).subscribe(data => {
  //    this.item = null;
  //    this.load();
  //  });
  //}

  //handlePreview = async (file: NzUploadFile) => {
  //  if (!file.url && !file.preview) {
  //    file.preview = await this.getBase64(file.originFileObj!);
  //  }
  //  this.previewImage = file.url || file.preview;
  //  this.previewVisible = true;
  //};

  ////getMediaUrl = (m: Media): string => { return this.globalService.getMediaUrl(m, 256); }

  //beforeUpload() {
  //}

  //handleChange(info: { file: NzUploadFile }): void {
  //  this.setLoading(true);

  //  switch (info.file.status) {
  //    case 'uploading':
  //      this.isLoading = true;
  //      break;
  //    case 'done':
  //      // Get this url from response in real world.
  //      //this.getBase64(info.file!.originFileObj!, (img: string) => {
  //      //  this.isLoading = false;
  //      //  this.avatarUrl = img;
  //      //});
  //      //this.isLoading = false;
  //      this.item.imageFilename = null;
  //      this.load();
  //      //this.newsItemService.Update(this.item).subscribe(data => {
  //      //  this.item = data;
  //      //});
  //      break;
  //    case 'error':
  //      this.msg.error('Network error');
  //      this.setLoading(false);
  //      break;
  //  }
  //}

  //getImageUrl = (m: NewsItem, size: number): string => { return this.globalService.getNewsItemUrl(m, size); }

  //setLoading(isLoading: boolean) {
  //  this.isLoading = isLoading;
  //  if (this.isLoading) {
  //    this.globalService.startProgress();
  //  } else {
  //    this.globalService.completeProgress(null);
  //  }
  //}
}

//interface DataItem {
//  name: string;
//  age: number;
//  address: string;
//}
