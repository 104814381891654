import { Component, OnInit } from '@angular/core';
//import { MessageSendService } from '../../../core/services/message-send.service';
import { Message } from '../../../models/Message';

@Component({
    selector: 'app-messages-test',
    templateUrl: './test.component.html',
    styleUrls: ['./test.component.css']
})
export class MessagesTestComponent implements OnInit {
    msg: Message = new Message();
    msgInboxArray: Message[] = [];

    constructor(
        //private messageSendService: MessageSendService
    ) {
    }

    ngOnInit(): void {
        //this.messageSendService.retrieveMappedObject().subscribe((receivedObj: Message) => {
        //  this.addToInbox(receivedObj);
        //});  // calls the service method to get the new messages sent
    }

    send(): void {
        //if (this.msg) {
        //  if (this.msg.user.length == 0 || this.msg.user.length == 0) {
        //    window.alert("Both fields are required.");
        //    return;
        //  } else {
        //    this.messageSendService.broadcastMessage(this.msg);                   // Send the message via a service
        //    this.msg.msgText = '';
        //  }
        //}
    }

    addToInbox(obj: Message) {
        let newObj = new Message();
        //newObj.user = obj.user;
        //newObj.msgText = obj.msgText;
        this.msgInboxArray.push(newObj);
    }
}
