import { Profile } from "./Profile";

export class Message {
  id: number;
  tribeId: number;
  content: string;
  status: number;
  messageType: number;
  fromProfileId: number;
  toProfileId: number;
  globalObjectId: number;
  globalObjectType: number;
  created: Date;

  fromProfile: Profile;
  toProfile: Profile;

  statusText: string;
  messageTypeText: string;

  constructor() {
  }

  public static otherId(m: Message, thisId: number): number {
    return thisId == m.fromProfileId ? m.toProfileId : m.fromProfileId;
  }
}
