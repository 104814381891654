import { Component, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { TitleEventsEmitter } from '../../core/events/title.events';
import { BaseComponent } from '../shared/base-component/base-component.component';

@Component({
   selector: 'terms',
   templateUrl: './terms.component.html',
   styleUrls: ['./terms.component.css']
   //animations: [
   //    trigger('isLoading', [
   //        state('false', style({ opacity: 0 })),
   //        state('true', style({ opacity: 0.5 })),
   //        transition('false => true', useAnimation(fadeIn, { params: { timing: Configuration.animDurationS, delay: 0 } })),
   //        transition('true => false', useAnimation(fadeOut, { params: { timing: Configuration.animDurationS / 2, delay: 0 } }))
   //    ])
   //]
})
export class TermsComponent extends BaseComponent implements OnInit, OnDestroy{

   constructor(
      private titleEventsEmitter: TitleEventsEmitter,
      @Inject(NgZone) private zone: NgZone
   ) {
     super();
   }

   ngOnInit() {
     this.titleEventsEmitter.broadcastEventTitleChanged("Terms");
     this.setLoading(false);
   }

}
