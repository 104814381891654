import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { animations } from 'ack-angular-fx';
import { TitleEventsEmitter } from '../../core/events/title.events';

import { BaseComponent } from '../shared/base-component/base-component.component';
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation, bounceInOnEnterAnimation } from 'angular-animations';
import { ProfileService } from '../../core/services/profile.service';

import { NewsItem } from '../../models/NewsItem';
import { NewsItemService } from '../../core/services/news-item.service';
import { GridSettings } from '../../models/shared/grids/gridSettings';
import * as moment from 'moment';
import { FilterSettings } from '../../models/shared/grids/filterSettings';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'news-component',
  templateUrl: 'news.component.html',
  styleUrls: ['news.component.css'],
  animations: [
    animations,
    bounceInOnEnterAnimation({ anchor: 'enter', duration: 500, delay: 0 }),
    //fadeInOnEnterAnimation({ anchor: 'enter', duration: 500, delay: 0 }),
    fadeOutOnLeaveAnimation({ anchor: 'leave', duration: 500, delay: 0 })
  ]
  //animations: [fadeInAnimation],
  //host: { '[@fadeInAnimation]': '' }
  //animations: [fadeInAnimation],
  //host: { '[@fadeInAnimation]': '' }
})

export class NewsComponent extends BaseComponent implements OnInit, OnDestroy {
  public items: NewsItem[];
  public item: NewsItem;
  public size: 'large';
  gridSettings: GridSettings;
  filterSettings: FilterSettings;
  @Input() itemType: number;

  constructor(
    private profileService: ProfileService,
    private newsItemService: NewsItemService,
    private titleEventsEmitter: TitleEventsEmitter,
    private route: ActivatedRoute,
  ) {
    super();

    this.gridSettings = new GridSettings();
    this.gridSettings.sortField = "Created";
    this.gridSettings.sortAsc = false;
    this.gridSettings.pageSize = 50;
    this.gridSettings.pageIndex = 0;
    this.filterSettings = new FilterSettings();
  }

  ngOnInit() {
    this.route.params.subscribe(param => {
      let itemType = this.route.snapshot.queryParamMap.get('itemType');
      const number = Number(itemType);
      this.itemType = isNaN(number) ? 1 : number;
      this.load();

    });
    this.currentProfile = this.authenticationService.getCurrentProfile();
    this.isLoading = true;
    this.size
  }

  load() {
    this.newsItemService.GetForFilterSettings(this.filterSettings, this.gridSettings.pageIndex, this.gridSettings.pageSize, this.gridSettings.sortField, this.gridSettings.sortAsc ? 1 : -1, this.itemType).subscribe(data => {
      this.items = data.filter(x => x.status == 1);
      this.isLoading = false;
      console.log(this.item, 'item for test')
    });
  }

  getImageUrl = (m: NewsItem): string => { return this.globalService.getNewsItemUrl(m, 256); }
  getTimeAgo = (d: Date): string => { return moment(d).fromNow(); }


}
