import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient } from "@angular/common/http";
import { AuthenticationService } from './authentication.service';
import { Configuration } from '../../app.constants';
import { NewsItem } from '../../models/NewsItem';
import { FilterSettings } from '../../models/shared/grids/filterSettings';
import { GridSettings } from '../../models/shared/grids/gridSettings';

@Injectable()
export class NewsItemService {
    private isBrowser: boolean;
    private actionUrl: string;

    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string,
        configuration: Configuration,
        private authenticationService: AuthenticationService
    ) {
        this.actionUrl = configuration.Server + 'v1/NewsItem/';
        //http.get<WeatherForecast[]>(baseUrl + 'weatherforecast').subscribe(result => {
        //   this.forecasts = result;
        //}, error => console.error(error));
    }

    public GetAll = (keyword: string, row: number, pageSize: number, sortField: string, sortOrder: number): Observable<NewsItem[]> => {
        let url = this.actionUrl + 'all?' + '&keyword=' + keyword + '&row=' + row + '&pageSize=' + pageSize + '&sortField=' + sortField + '&sortOrder=' + sortOrder;
        return this.http.post<NewsItem[]>(url, '', this.authenticationService.getHttpOptions());
    }
    public GetAllCount = (keyword: string): Observable<number> => {
        return this.http.post<number>(this.actionUrl + 'allcount?keyword=' + keyword, '', this.authenticationService.getHttpOptions());
    }
    public Get = (id: number): Observable<NewsItem> => {
        return this.http.post<NewsItem>(this.actionUrl + id, "", this.authenticationService.getHttpOptions());
    }
    public Add = (item: NewsItem): Observable<NewsItem> => {
        return this.http.post<NewsItem>(this.actionUrl, JSON.stringify(item), this.authenticationService.getHttpOptions());
    }
    public Update = (item: NewsItem): Observable<NewsItem> => {
        return this.http.put<NewsItem>(this.actionUrl, JSON.stringify(item), this.authenticationService.getHttpOptions());
    }
    public Delete = (id: number): Observable<any> => {
        return this.http.delete(this.actionUrl + id, this.authenticationService.getHttpOptions());
    }
    public GetForFilterSettings = (filterSettings: FilterSettings, row: number, pageSize: number, sortField: string, sortOrder: number, itemType: number = 1): Observable<NewsItem[]> => {
        let url = this.actionUrl + 'forFilterSettings?row=' + row + '&pageSize=' + pageSize + '&sortField=' + sortField + '&sortOrder=' + sortOrder + '&itemType=' + itemType;
        return this.http.post<NewsItem[]>(url, JSON.stringify(filterSettings), this.authenticationService.getHttpOptions());
    }
    public GetForSettings = (filterSettings: FilterSettings, gridSettings: GridSettings): Observable<NewsItem[]> => {
        return this.GetForFilterSettings(filterSettings, gridSettings.pageIndex, gridSettings.pageSize, gridSettings.sortField, (gridSettings.sortAsc ? 1 : -1));
    }
    //public NewsItem = (content: string, NewsItemType: number): Observable<NewsItem> => {
    //  let item = new NewsItem();
    //  item.content = content;
    //  item.NewsItemType = NewsItemType;
    //  return this.http.post<NewsItem>(this.actionUrl, JSON.stringify(item), this.authenticationService.getHttpOptions());
    //}
}
