import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Profile } from '../../../models/Profile';
import { ProfileService } from '../../../core/services/profile.service';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { GlobalService } from '../../../core/services/global.service';
import { BaseComponent } from '../../shared/base-component/base-component.component';

@Component({
  selector: 'app-registration-reg-4-password',
  styleUrls: ['./reg-4-password.component.css'],
  templateUrl: './reg-4-password.component.html',
})
export class Reg4PasswordComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() step: number;
  passwordStrength: number = 0;

  public model: any = {};

  constructor(
    router: Router,
    globalService: GlobalService,
    authenticationService: AuthenticationService,
    private profileService: ProfileService,
  ) {
    super();
  }

  ngOnInit() {
    
    //this.contactService.GetAll("", 0, 10, "Created", 1).subscribe(data => {
    //  this.contacts = data;
    //})
 
    this.currentProfile = this.authenticationService.getCurrentProfile();

    localStorage.setItem('currentProfile', JSON.stringify(this.currentProfile));
    if (this.currentProfile.emailConfirmed != true) this.nav("registration/3");
    if (this.currentProfile.status == 2) { this.nav("/"); }
    if (this.currentProfile.token != null) this.nav("registration/5");

    this.setLoading(false);
  }

  submit() {
    if (this.model.password == this.model.password2) {
      this.currentProfile.password = this.model.password;
      if (this.currentProfile.tribeId == null || this.currentProfile.tribeId < 1) this.currentProfile.tribeId = this.currentProfile.invitedByProfile.tribeId;
      this.authenticationService.UpdatePassword(this.currentProfile.id, "", this.model.password).subscribe(data => {
        localStorage.setItem('currentProfile', JSON.stringify(data));
        this.nav('/registration/5');
      });
    }
  }
}
