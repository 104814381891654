export class Log {
  id: number;
  tribeId: number;
  content: string;
  logType: number;
  title: string;
  created: Date;

  logTypeText: string;

  constructor() {
  }
}
