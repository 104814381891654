import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Profile } from '../../../../models/Profile';
import { Interest } from '../../../../models/Interest';
import { ProfileService } from '../../../../core/services/profile.service';
import { InterestService } from '../../../../core/services/interest.service';
import { BaseComponent } from '../../base-component/base-component.component';
import { FilterSettings } from '../../../../models/shared/grids/filterSettings';

@Component({
  selector: 'profile-interests',
  templateUrl: './profile-interests.component.html',
  styleUrls: ['./profile-interests.component.css']
})
export class ProfileInterestsComponent extends BaseComponent implements OnInit {
  @Input() profile: Profile;
  @Input() readonly: boolean = false;
  @Output() onProfileUpdated: EventEmitter<Profile> = new EventEmitter<Profile>();
  filterSettings: FilterSettings;
  public interests: Interest[];

  constructor(
    private profileService: ProfileService,
    private interestService: InterestService,
  ) {
    super();
    this.filterSettings = new FilterSettings();
  }

  ngOnInit() {
    //this.contactService.GetAll("", 0, 10, "Created", 1).subscribe(data => {
    //  this.contacts = data;
    //})
    this.interestService.GetForFilterSettings(this.filterSettings, 0, 1000, "ViewOrder", 1).subscribe(data => {
      data.forEach(i => {
        (<any>i).checked = this.profile.profileInterest.find(x => x.interestId == i.id) != null; 
        (<any>i).color = this.getColor(data.indexOf(i), data.length);
      });
      this.interests = !this.readonly ? data : data.filter(x => (<any>x).checked);
    });
  }

  ngOnChanges() {
    this.ngOnInit();
  }

  handleChange(checked: boolean, interest: any): void {
    if (checked) {
      if (!interest.checked) {
        this.profileService.AddInterest(this.profile, interest.id).subscribe(data => {
          this.profile = data;
          this.onProfileUpdated.next(data);
          //this.msgService.info('Interest "' + interest.title + '" Added');
        });
        interest.checked = true;
      }
    } else {
      interest.checked = false;
      this.profileService.RemoveInterest(this.profile, interest.id).subscribe(data => {
        this.profile = data;
        localStorage.setItem('currentProfile', JSON.stringify(data));
        //this.msgService.info('Interest "' + interest.title + '" Removed');
        this.onProfileUpdated.next(data);
      });
    }
  }

  getColor(index, length): string {
    let f = length > 0 ? index / length : 0;
    let h = this.scaledInt(0, 360, f);
    let s = this.scaledInt(42, 98, f) * 0.65;
    let l = this.scaledInt(40, 90, Math.sqrt(f));
    return `hsl(${h},${s}%,${l}%)`;
  }

  public scaledInt(min, max, f) {
    return ((max - min) * f) + min;
  }

}
