import { Component, OnInit, Input, ViewChild, OnChanges, SimpleChange, EventEmitter, Output } from '@angular/core';
//import { MessageSendService } from '../../../core/services/message-send.service';
import { Message } from '../../../models/Message';
import { BaseComponent } from '../../shared/base-component/base-component.component';
import { MessageService } from '../../../core/services/message.service';
import { interval, Observable, Observer } from 'rxjs';
import { take, filter } from 'rxjs/operators';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Profile } from '../../../models/Profile';
import { Media } from '../../../models/Media';
import { HttpRequest, HttpResponse, HttpClient } from '@angular/common/http';
import { Uploads } from '../../../models/shared/upload/Uploads';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import * as moment from 'moment';

@Component({
  selector: 'message-thread',
  templateUrl: './message-thread.component.html',
  styleUrls: ['./message-thread.component.css']
})
export class MessagesThreadComponent extends BaseComponent implements OnInit, OnChanges {

  @ViewChild('messageThread') messageThread: any;
  @Input() inputForm: UntypedFormGroup;
  @Input() selectedItem: any;
  @Input() messageType: any;
  @Input() globalObjectId: any = 0;
  @Output() onMessagesUpdated: EventEmitter<any> = new EventEmitter<any>();
  currentProfile: Profile;

  constructor(
    private messageService: MessageService,
    private http: HttpClient,
    private fb: UntypedFormBuilder,
    private authentication: AuthenticationService
  ) {
    super();

    this.inputForm = this.fb.group({
      //formLayout: ['inline'],
      inputMsg: ["", [Validators.required]]
    });
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (changes['selectedItem'] && changes['selectedItem'].previousValue != changes['selectedItem'].currentValue) {
      this.bindSelectedItem();
    }
    this.scrollToEnd();
  }

  ngOnInit(): void {
    this.currentProfile = this.authentication.getCurrentProfile();
    //if (this.messageType == 2) { //we only init the hub connection inside the thread from the events area as this is done outside (in the inbox) for the messages area
    //  this.messageService.initEventHubConnection2(this.globalObjectId);
    //  this.messageService._eventHubConnection.on('ReceiveMessage', (message: Message) => {
    //    if (message.messageType == 2) {
    //      if (this.selectedItem.messages.find(x => x.id == message.id) == null) {
    //        this.selectedItem.messages.push(message);
    //        this.bindSelectedItem();
    //        this.onMessagesUpdated.next();
    //      }
    //    } else {
    //      this.onMessagesUpdated.next();
    //    }
    //    //this.bindSelectedItem();
    //  });
    //}
    this.messageService._messageHubConnection.on('UpdateMessages', (message: Message) => {
      console.log("hit");
      if (this.selectedItem.messages.find(x => x.id == message.id) == null) {
        this.selectedItem.messages.push(message);
        this.bindSelectedItem();
        this.onMessagesUpdated.next();

      }

    });
    this.bindSelectedItem();
  }

  getCurrentHubConnection() {
    return (this.messageType == 2 ? this.messageService._eventHubConnection : this.messageService._messageHubConnection);
  }

  //hubListening: boolean = false;

  bindSelectedItem() {
    if (this.selectedItem) {

      //Taken from init
      if (this.messageType == 2) { //we only init the hub connection inside the thread from the events area as this is done outside (in the inbox) for the messages area
        this.messageService.initEventHubConnection2(this.globalObjectId);
        this.messageService._eventHubConnection.on('ReceiveMessage', (message: Message) => {
          if (message.messageType == 2) {
            if (this.selectedItem.messages.find(x => x.id == message.id) == null) {
              this.selectedItem.messages.push(message);
              this.bindSelectedItem();
              this.onMessagesUpdated.next();
            }
          } else {
            this.onMessagesUpdated.next();
          }
          //this.bindSelectedItem();
        });
      }


      this.selectedItem.messages = this.selectedItem.messages.sort((a, b) => (b.id < a.id) ? 1 : -1);
      this.selectedItem.messages.filter(x => x.messageType == 1).forEach(m => {
        if (m.status == 1) {
          m.status = 2;
          if (m.fromProfileId !== this.currentProfile.id) {
            this.messageService.Update(m).subscribe(data => {
              m = data;
              //this.messageService.sendInboxUpdates(this.getCurrentHubConnection(), [this.currentProfile.id]);
            });
          }
        }
      });
      this.selectedItem.unread = false;
    }
    this.isLoading = false;
    this.scrollToEnd();
  }

  scrollToEnd() {
    interval(250).pipe(take(1)).subscribe(x => {
      let el = document.querySelector('.message-thread-scrollable');

      if (el) {
        el.scrollTop = el.scrollHeight;
      }
    });
  }

  send() {
    let m = new Message();
    console.log("a");
    m.content = this.inputForm.get('inputMsg').value;
    m.fromProfileId = this.currentProfile.id;
    m.toProfileId = this.selectedItem.profile?.id;
    m.messageType = this.messageType;
    m.globalObjectId = this.globalObjectId;
    m.status = 1;
    m.created = moment(new Date()).utc(true).toDate();
    //m.created = moment(new Date()).utc.toDate();
    this.messageService.Add(m).subscribe(data2 => {
      this.selectedItem.messages.push(data2);
      //this.messageSendService.broadcastMessage(data);                   // Send the message via a service

      this.messageService.sendMessage(this.getCurrentHubConnection(), data2);
      //this.messageService.sendInboxUpdates(this.getCurrentHubConnection(), [this.currentProfile.id]);
      //this.messages.push(m);
      //this.bindSelectedItem();

    });
    this.inputForm.controls['inputMsg'].setValue("");
    //let textArea = document.getElementById("text-input");
    //textArea.style.height = 'auto';

  }

  //onKeyUp($event) {
  //  //this.autogrow();
  //}

  //autogrow() {
  //  let textArea = document.getElementById("text-input");
  //  textArea.style.overflow = 'hidden';
  //  //textArea.style.height = 'auto';
  //  textArea.style.padding = '0';
  //  textArea.style.height = (textArea.scrollHeight) + 'px';
  //}

  addEmoji($event) {
    let data = this.inputForm.get('inputMsg');
    data.patchValue(data.value + $event.emoji.native)
  }

  getImageUrl = (p: Profile): string => { return this.globalService.getProfileImageUrl(p, 256); }

  //beforeUpload = (file: NzUploadFile, _fileList: NzUploadFile[]) => {
  //  return new Observable((observer: Observer<boolean>) => {
  //    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  //    if (!isJpgOrPng) {
  //      this.msgService.error('You can only upload JPG file!');
  //      observer.complete();
  //      return;
  //    }
  //    const isLt2M = file.size! / 1024 / 1024 < 2;
  //    if (!isLt2M) {
  //      this.msgService.error('Image must smaller than 2MB!');
  //      observer.complete();
  //      return;
  //    }
  //    observer.next(isJpgOrPng && isLt2M);
  //    observer.complete();
  //  });
  //};


  transformFile = (file: NzUploadFile) => {
    this.setLoading(true);
    let url = "";
    return new Observable((observer: Observer<Blob>) => {
      Uploads.convertToBlob(file).then(blobSrc => {
        url = blobSrc as string;
        //Uploads.uploadBlob(this.http, url, '/v1/Media/uploadFile/' + 2 + '/' + this.item.id + '/' + this.currentProfile.id).subscribe(data => {

        //  this.mediaList.loadMedia(false);
        //  this.mediaGrid.refresh();
        //  this.setLoading(false);
        //});


        let m = new Message();
        m.content = '';

        m.fromProfileId = this.currentProfile.id;
        m.toProfileId = this.selectedItem.profile?.id;
        m.messageType = this.messageType;
        m.globalObjectId = this.globalObjectId;

        //m.fromProfileId = this.currentProfile.id;
        //m.toProfileId = this.selectedItem.profile.id;
        //m.messageType = 1;
        m.status = 1;
        //m.created = new Date();
        m.created = moment(new Date()).toDate();

        this.messageService.Add(m).subscribe(data => {
          m = data;
          Uploads.uploadBlob(this.http, url, '/v1/Media/uploadFile/' + 3 + '/' + m.id + '/' + this.currentProfile.id).subscribe(data => {
            m.content = 'image';
            this.messageService.Update(m).subscribe(data => {
              m = data;
              this.messageService.sendMessage(this.getCurrentHubConnection(), m);
              this.messageService.sendInboxUpdates(this.getCurrentHubConnection(), [this.currentProfile.id]);
              this.bindSelectedItem();
            });
          });
        });
        this.inputForm.controls['inputMsg'].setValue("");
      });
    });

  }


  //handleChange(info: any): void {
  //  let newMediaItem = new Media;
  //  Uploads.getBase64(info.file!.originFileObj!, (img: string) => {
  //    newMediaItem.filename = img;
  //  });

  //  switch (info.file.status) {
  //    case 'uploading':
  //      break;
  //    case 'done':
  //      let m = new Message();
  //      m.content = '';
  //      m.fromProfileId = this.currentProfile.id;
  //      m.toProfileId = this.selectedItem.profile.id;
  //      m.messageType = 1;
  //      m.status = 1;
  //      m.created = new Date();

  //      this.messageService.Add(m).subscribe(data => {
  //        m = data;
  //        Uploads.uploadBlob(this.http, newMediaItem.filename, '/v1/Media/uploadFile/' + 3 + '/' + m.id + '/' + this.currentProfile.id).subscribe(data => {
  //          m.content = 'image';
  //          this.messageService.Update(m).subscribe(data => {
  //            m = data;
  //            this.messageService.sendMessage(m);
  //            this.bindSelectedItem();
  //          });
  //        });
  //      });
  //      this.inputForm.controls['inputMsg'].setValue("");
  //      break;
  //    case 'error':
  //      this.msgService.error('Network error');
  //      this.isLoading = false;
  //      break;
  //  }
  //}

}
