import { Component, OnInit, OnDestroy } from '@angular/core';
import { GridSettings } from '../../../models/shared/grids/gridSettings';
import { Interest } from '../../../models/Interest';
import { InterestService } from '../../../core/services/interest.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { NzMessageService } from 'ng-zorro-antd/message';
import { BaseComponent } from '../../shared/base-component/base-component.component';
import { Router } from '@angular/router';
import { GlobalService } from '../../../core/services/global.service';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { FilterSettings } from '../../../models/shared/grids/filterSettings';
import { ProfileService } from '../../../core/services/profile.service';

@Component({
  selector: 'admin-interests',
  styleUrls: ['./interests.component.css'],
  templateUrl: './interests.component.html',
})
export class AdminInterestsComponent extends BaseComponent implements OnInit, OnDestroy {
  public items: Interest[];
  filterSettings: FilterSettings;
  gridSettings: GridSettings;

  total = 1;

  validateForm!: UntypedFormGroup;

  constructor(
    router: Router,
    globalService: GlobalService,
    authenticationService: AuthenticationService,
    private profileService: ProfileService,
    private interestService: InterestService,
    private fb: UntypedFormBuilder,
    private nzMessageService: NzMessageService
  ) {
    super();

    this.gridSettings = new GridSettings();
    this.gridSettings.sortField = "ViewOrder";
    this.gridSettings.sortAsc = true;
    this.gridSettings.pageSize = 100;
    this.gridSettings.pageIndex = 0;
    this.filterSettings = new FilterSettings();
  }

  ngOnInit() {
    this.validateForm = this.fb.group({
      formLayout: ['inline'],
      title: [null, [Validators.required]]
    });

    this.loadData();
  }

  loadData() {
    console.log(this.filterSettings);
    this.interestService.GetForFilterSettings(this.filterSettings, this.gridSettings.pageIndex, this.gridSettings.pageSize, this.gridSettings.sortField, this.gridSettings.sortAsc ? 1 : -1).subscribe(data => {
      this.items = data;
      this.isLoading = false;
    })
  }

  drop(event: CdkDragDrop<string[]>): void {
    

    let remaining = this.items.length;
    for (let i = 0; i < this.items.length; i++) {
      if (i == event.previousIndex) {
        this.items[i].viewOrder = event.currentIndex;
      } else if (i == event.currentIndex) {
        this.items[i].viewOrder = event.previousIndex;
      } else {
        this.items[i].viewOrder = i;
      }
      this.interestService.Update(this.items[i]).subscribe(data => {
        remaining--;
        if (remaining == 0) {
          this.loadData();
          this.nzMessageService.info('Updated order.');
        }
      });
    }

    //moveItemInArray(this.listOfData, event.previousIndex, event.currentIndex);
  }

  submitForm(): void {
    
    let item = new Interest();
    item.title = this.validateForm.controls['title'].value;
    this.interestService.Add(item).subscribe(data => {
     
      this.loadData();
    });
    //for (const i in this.validateForm.controls) {
    //  this.validateForm.controls[i].markAsDirty();
    //  this.validateForm.controls[i].updateValueAndValidity();
    //}
  }

  delete(item: Interest) {
    this.interestService.Delete(item.id).subscribe(data => {
      this.nzMessageService.info('Deleted interest.');
      this.loadData();
    });
  }
  cancelDelete(): void {
    this.nzMessageService.info('Cancelled delete.');
  }
  
}

//interface DataItem {
//  name: string;
//  age: number;
//  address: string;
//}
