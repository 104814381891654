import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Profile } from '../../../models/Profile';
import { Subscription } from 'rxjs';
import { ProfileService } from '../../../core/services/profile.service';
import { BaseComponent } from '../../shared/base-component/base-component.component';
import { GlobalService } from '../../../core/services/global.service';
import { AuthenticationService } from '../../../core/services/authentication.service';

@Component({
    templateUrl: 'verify-email.component.html',
    //styleUrls: ['resetPassword.component.css']
})

export class ActionVerifyEmailComponent extends BaseComponent implements OnInit, OnDestroy {
    public profileId: string = "";
    public message: string = "";
    public returnUrl: string = "";
    public profile: Profile = new Profile();

    constructor(
        router: Router,
        globalService: GlobalService,
        authenticationService: AuthenticationService,
        private route: ActivatedRoute,
        private profileService: ProfileService,
        //authenticationService: AuthenticationService,
        //private alertService: AlertService,
        //private ngProgress: NgProgress,
        //private loginEventsEmitter: LoginEventsEmitter
    ) {
        super();
    }

    ngOnInit() {
        //this.authenticationService.logout();
        this.route.params.subscribe(params => {
            //this.ngProgress.start();
            let profileIdEncrypted = this.route.snapshot.queryParamMap.get('pId');
            

            this.profileService.VerifyEmail(profileIdEncrypted)
                .subscribe(
                  data => {

                        localStorage.setItem('currentProfile', JSON.stringify(data));
                        this.nav("/registration/4");
                    },
                  error => {
   
                    if (error.error) this.nav(error.error);
                    
                        //if (error.status == 404) {
                        //    this.message = "Email or password not found"
                        //} else {
                        //    this.message = "Error logging in"
                        //}
                        //this.alertService.error(this.message);
                    });

            //this.ngProgress.complete();
            //this.contactId = params['id'];
            //console.log(params['id']);
        });
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription: Subscription) => { subscription.unsubscribe(); });
        this.subscriptions = [];
    }

    //public getCurrentContact(): any {
    //    if (localStorage.getItem('currentContact')) {
    //        let c: Contact = new Contact();
    //        c = JSON.parse(<string>localStorage.getItem('currentContact'));
    //        return c;
    //    }
    //    return null;
    //    //return JSON.parse(JSON.stringify(""));
    //}
}
