import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProfileService } from '../../../core/services/profile.service';
import { Profile } from '../../../models/Profile';
import { Notification } from '../../../models/notification';
import { Event } from '../../../models/Event';
import { EventService } from '../../../core/services/event.service';
import { FilterSettings } from '../../../models/shared/grids/filterSettings';
import { MediaService } from '../../../core/services/media.service';
import { BaseComponent } from '../../shared/base-component/base-component.component';
import { Question } from '../../../models/Question';
import { QuestionService } from '../../../core/services/question.service';
import { GridSettings } from '../../../models/shared/grids/gridSettings';
import { EventProfile } from '../../../models/EventProfile';
import * as moment from 'moment';
import { TitleEventsEmitter } from '../../../core/events/title.events';
import { MessageService } from '../../../core/services/message.service';
import { Message } from '../../../models/Message';
import { Uploads } from '../../../models/shared/upload/Uploads';
import { HttpClient } from '@angular/common/http';
import { Observable, Observer } from 'rxjs';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { VideoChatComponent } from '../../shared/video-chat/video-chat.component';
import { NotificationService } from '../../../core/services/notification.service';
import { Configuration } from '../../../app.constants';
import { NavMenuComponent } from '../../shared/nav-menu/nav-menu.component';
import { MapInfoWindow, MapMarker, GoogleMap } from '@angular/google-maps';
import { Location } from '@angular/common'

@Component({
  selector: 'event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.css'],
  //animations: [fadeInAnimation],
  //host: { '[@fadeInAnimation]': '' }
})

export class EventComponent extends BaseComponent implements OnInit, OnDestroy {

  @ViewChild('startTime') startTime: ElementRef;
  @ViewChild('endTime') endTime: ElementRef;
  @ViewChild('mediaList') mediaList;
  @ViewChild('mediaGrid') mediaGrid;
  @ViewChild('videoChat') videoChat: VideoChatComponent;

  isExpanded = false;
  isModalInviteVisible = false;
  isModalInviteOkLoading = false;
  chatMode: number = 0;
  item: Event;

  zoom = 12;
  center!: google.maps.LatLng;
  options: google.maps.MapOptions = {
    zoomControl: true,
    scrollwheel: false,
    disableDefaultUI: true,
    fullscreenControl: true,
    disableDoubleClickZoom: true,
    mapTypeId: 'hybrid',
    // maxZoom:this.maxZoom,
    // minZoom:this.minZoom,
  };
  latitude!: any;
  longitude!: any;
  markers = [];

  public invites: EventProfile[];
  public invite: EventProfile;

  public invitedList: EventProfile[];
  public interestedList: EventProfile[];
  public acceptedList: EventProfile[];
  public declinedList: EventProfile[];
  public profileCheck: Profile;

  messages: Message[];
  messageThread: any;

  date = null;
  eventId = null;
  ref: string;
  eventIdCheck: boolean;
  publicClass = 'not-active';
  privateClass = 'active';

  //sliderStartDateText = "09:00";
  //sliderEndDateText = "18:00";
  //startDateMinutes = 0;
  //endDateMinutes = 0;
  //disabled = false;
  selectedTabIndex = 0;
  isChat: boolean = false;
  profiles: Profile[];
  notifications: Notification[];
  questions: Question[];
  filterSettings: FilterSettings = new FilterSettings();
  mediaGridSettings: GridSettings;
  selectedTab = false;
  questionId: number = 0;
  spacesLeft: number = 0;
  cdnUrl: string = "";
  showChatOptions: boolean = false;
  showUploadPhoto: boolean = false;
  inputValue: string = "";
  settingsVisible: boolean = false;
  isFull: boolean = false;
  panels = [
    {
      active: true,
      name: '',
      disabled: false
    }
  ];
  isAllSelected: boolean;

  constructor(
    private route: ActivatedRoute,
    private profileService: ProfileService,
    private eventService: EventService,
    private configuration: Configuration,
    private mediaService: MediaService,
    private questionService: QuestionService,
    private messageService: MessageService,
    private eventNotificationService: NotificationService,
    private titleEventsEmitter: TitleEventsEmitter,
    private http: HttpClient,
    private location: Location

  ) {
    super();

    this.titleEventsEmitter.broadcastEventTitleChanged("Event");

    //this.filterSettings = new FilterSettings();
    //this.filterSettings.gender = 0;
    //this.filterSettings.distance = 0;
    this.filterSettings.profileId = this.currentProfile.id;
    this.mediaGridSettings = new GridSettings();
    this.mediaGridSettings.pageSize = 1000;
  }

  ngOnInit() {
    super.ngOnInit();
    this.cdnUrl = this.configuration.CDNUrl;
    if (!this.isMobile) this.showChatOptions = true;

    this.route.queryParams.subscribe(params => {
      const tab = params['tab'];
      if (tab) {
        //console.log('Hello world,tab', tab  )
        //this.onTabSelected('Chat');
        //this.selectedTabIndex = 1;
        //this.bindMessages();
      }
    });
    this.route.params.subscribe(params => {
      this.eventId = +params['eId'];

      if (this.eventId > 0) {
        this.load();
      }
    });
  }

  load() {
    this.eventService.Get(+this.eventId).subscribe(data => { //update from api
      console.log(this.eventId, 'even..', data)
      const addressFirstLine = data?.addressFirstLine?.trim();
      const location = data?.location?.trim();

      if (location?.startsWith(',') || addressFirstLine?.endsWith(',')) {
        data.location = data.addressFirstLine + data.location;
      }
      this.item = data;
      console.log(this.item.location);

      this.eventNotificationService.GetForItem(+this.eventId, 2, this.item.createdBy).subscribe(result => {
        this.notifications = result;

        console.log(this.item.createdBy, this.currentProfile.id);
        let place = new google.maps.LatLng(+(this.item.latitude), +(this.item.longitude));
        this.markers.push({
          position: {
            lat: +this.item.latitude,
            lng: +this.item.longitude,
          },
          label: {
            color: 'red',
            text: 'a',
          },
          title: 'B ',
          options: { animation: google.maps.Animation.BOUNCE },
        });
        this.center = place;
        console.log(this.center.toString());
      });


      this.setEventImage();

      if (data.privacyStatus === 2 && data.createdBy !== this.currentProfile.id && data.eventProfile.every(eP => eP.profileId !== this.currentProfile.id)) {
        if (this.currentProfile.userLevel != 3) { this.nav("/"); }
      }

      this.bindInvites();
      this.bindSlider();

      this.setLoading(false);

    });
  }

  formatAddress(): string {
    const event = this.item;
    const addressFirstLine = event?.addressFirstLine?.trim();
    const addressTown = event.addressTown;
    const postCode = event.postcode;
    return `${addressFirstLine} , ${addressTown} ${postCode}`
  }

  setEventImage() {
    this.item.cdnImageUrl = this.globalService.getEventImageUrl(this.item, 800) + '?t=' + parseInt((Math.random() * 1000).toString());
  }

  addNotification() {
    let newNotification = new Notification;
    newNotification.content = this.inputValue;
    newNotification.globalId = this.item.id;
    newNotification.globalObjectType = 2;
    newNotification.tribeId = this.currentProfile.tribeId;
    newNotification.toProfileId = this.item.createdBy;
    console.log(this.currentProfile.id);
    newNotification.status = 1;
    newNotification.created = new Date();
    this.eventNotificationService.Add(newNotification).subscribe(r => {
      this.inputValue = "";
      this.notify("success", "Notification Sent!")
      this.eventNotificationService.GetForItem(+this.eventId, 2, this.item.createdBy).subscribe(result => {
        this.notifications = result;
        console.log(result);


      });
    });
  }

  bindSlider() {

    //initialise
    //this.sliderStartDateText = moment(this.item.startDate).format("HH:mm");
    //this.sliderEndDateText = moment(this.item.endDate).format("HH:mm");
    //this.startDateMinutes = (parseInt(moment(this.item.startDate).format("HH")) * 60) + parseInt(moment(this.item.startDate).format("mm"));
    //this.endDateMinutes = (parseInt(moment(this.item.endDate).format("HH")) * 60) + parseInt(moment(this.item.endDate).format("mm"));
  }
  selectAll() {
    this.isAllSelected = !this.isAllSelected;
    this.invitedList.forEach(each => {
      each.profile.selected = this.isAllSelected;
    })
  }

  resendInvite() {
    const attendees = this.invitedList.filter(x => x.profile.selected).map(x => x.profileId);
    if (!attendees || !attendees.length) {
      this.msgService.error('No attend selected ');
      return;
    }

    const eventId = this.eventId;

    this.eventService.ResendInvitation({ attendees, eventId }).subscribe({
      complete: () => {
        this.selectAll();
        this.msgService.info('Invitation resend');
      }, error: () => {
        this.msgService.error('could not resend invitation')
      }
    })
  }

  bindInvites() {
    this.item.eventProfile.forEach(f => {
      if (f.profileId != null) {
        this.profileService.Get(f.profileId).subscribe(result => {
          this.profileCheck = null;
          this.profileCheck = result;
          if (this.profileCheck == null) { this.item.eventProfile = this.item.eventProfile.filter(g => g.id != f.id); }
        });
      } else {
        this.item.eventProfile = this.item.eventProfile.filter(g => g != f);
      }
    });

    this.invitedList = this.item.eventProfile.filter(x => x.inviteStatus == 2);
    this.acceptedList = this.item.eventProfile.filter(x => x.inviteStatus == 1);
    let x = new EventProfile;
    x.eventId = this.item.id;
    x.inviteStatus = 1;
    x.profileId = this.item.createdBy;
    x.profile = this.item.createdByProfile;
    x.created = this.item.created;
    x.id = 0;
    this.acceptedList.push(x);


    if (this.currentProfile.id != this.item.createdBy) {
      if (this.item.genderWeight == true) {
        if (this.currentProfile.gender == 1) {
          this.spacesLeft = this.item.sizeMaleLimit - this.item.eventProfile.filter(x => x.profile.gender == 1 && (x.inviteStatus == 1 || x.inviteStatus == 2)).length;
        }
        if (this.currentProfile.gender == 2) {

          this.spacesLeft = this.item.sizeFemaleLimit - this.item.eventProfile.filter(x => x.profile.gender == 2 && (x.inviteStatus == 1 || x.inviteStatus == 2)).length;

        }
        if (this.currentProfile.gender != 2 && this.currentProfile.gender != 1) {
          this.spacesLeft = this.item.sizeLimit - this.item.eventProfile.filter(x => x.inviteStatus == 1 || x.inviteStatus == 2).length;
        }
      } else {
        this.spacesLeft = this.item.sizeLimit - this.item.eventProfile.filter(x => x.inviteStatus == 1 || x.inviteStatus == 2).length;
      }
    } else {
      this.spacesLeft = this.item.sizeLimit - this.item.eventProfile.filter(x => x.inviteStatus == 1 || x.inviteStatus == 2).length;
    }
    if (this.spacesLeft < 0) { this.spacesLeft = 0 }

    if (this.spacesLeft > 0) {
      this.isFull = false;
    } else {
      this.isFull = true;
    }

    this.declinedList = this.item.eventProfile.filter(x => x.inviteStatus == 3);
    this.interestedList = this.item.eventProfile.filter(x => x.inviteStatus == 4);

  }

  bindMessages() {


    this.messageService.GetByDetails(2, 0, this.eventId, 0, 0).subscribe(data => {
      this.bindMessageThread(data);
      //this.profiles = Array.from(new Set(this.profiles.map((item: any) => item.id))).map(x => this.profiles.find(y => y.id == x)).filter(x => x.id != this.currentProfile.id);
      //this.profiles.forEach(x => {

    });
  }

  bindMessageThread(messages) {
    let today = moment(new Date());
    let yesterday = moment(new Date()).subtract(1, 'day');

    this.messages = messages.sort((a, b) => (a.created > b.created) ? 1 : -1);
    //this.profiles = this.messages.map(x => x.fromProfile).concat(this.messages.map(x => x.toProfile)).filter(x => x != null);

    let curCreated = null;
    for (let i = 0; i < this.messages.length; i++) {
      let created = moment(this.messages[i].created);

      (<any>this.messages[i]).dateText = "";
      if (curCreated == null || !(curCreated.isSame(created, 'day'))) {
        curCreated = created;
        (<any>this.messages[i]).dateText = today.isSame(curCreated, 'day') ? 'TODAY' : (yesterday.isSame(curCreated, 'day') ? 'YESTERDAY' : curCreated.format('YYYY-MM-DD'));
      }
    }

    this.messages = messages.sort((a, b) => (a.created < b.created) ? 1 : -1);

    this.messageThread = {
      profile: null,
      lastMessage: this.messages.sort((a, b) => (a.created < b.created) ? -1 : 1),
      messages: this.messages,
      unread: this.messages.filter(x => x.status == 1).length > 0
    }
  }

  updateMessages($event) {
    this.bindMessageThread(this.messages);

  }

  //onSliderUpdate(a: any[]) {
  //  //slider returns array of two ints (minutes 0-1425)

  //  let eventDate = moment(this.item.eventDate);

  //  let hour = Math.floor(a[0] / 60);
  //  let min = a[0] - hour * 60;
  //  let momentStartDate = moment([eventDate.year(), eventDate.month(), eventDate.day(), hour, min, 0, 0]);
  //  this.item.startDate = momentStartDate.toDate();
  //  this.sliderStartDateText = momentStartDate.format('hh:mm');

  //  hour = Math.floor(a[1] / 60);
  //  min = a[1] - hour * 60;
  //  let momentEndDate = moment([eventDate.year(), eventDate.month(), eventDate.day(), hour, min, 0, 0]);
  //  this.item.endDate = momentStartDate.toDate();
  //  this.sliderEndDateText = momentStartDate.format('hh:mm');
  //}

  setChatMode(mode) {
    this.chatMode = mode;
  }

  onTabSelected(tabTitle) {
    this.showChatOptions = (tabTitle == "Chat");
    this.showUploadPhoto = (tabTitle == "Gallery");
    this.selectedTab = (tabTitle == "Gallery");
    if (tabTitle = "Chat") {
      this.isChat = true;
      this.bindMessages();
    } else {
      this.isChat = false;
    }
  }

  goBack() {
    this.location.back();
  }

  edit() {
    //this.item = null;
    //this.nav('/events/edit/' + this.eventId.toString());
    this.router.navigateByUrl('/events/edit/' + this.eventId.toString());
  }

  getEventDateSummary(m: Event) {
    return moment(m.eventDate).format('dddd, MMMM Do, YYYY');
  }

  getEventTimeSummary(m: Event) {
    return moment(m.startDate).format('h:mma') + ' - ' + moment(m.endDate).format('h:mma');
  }

  getTimeAgo = (d: Date): string => {
    return moment(d).fromNow();
  }

  checkInvite() {

    let check = this.item.eventProfile.find(x => x.profileId == this.currentProfile.id);

    if (check) {
      return check.inviteStatus;
    }
  }

  decline() {
    console.log('decli...')
    if (this.item.eventProfile.find(x => x.profileId == this.currentProfile.id)) {
      this.item.eventProfile.find(x => x.profileId == this.currentProfile.id).inviteStatus = 3;
      this.msgService.info('You will not be attending ' + this.item.title);
      this.eventService.Decline(this.item).subscribe(data => {
        this.item = data;
        this.setEventImage();
        this.bindInvites();
      });
    }
  }

  accept() {
    if (this.item.privacyStatus == 1) {
      this.item.eventProfile.push({ created: new Date(), eventId: this.item.id, profileId: this.currentProfile.id, inviteStatus: 1, profile: this.currentProfile, id: 0 });
    }
    else {
      this.item.eventProfile.find(x => x.profileId == this.currentProfile.id).inviteStatus = 1;
    }

    this.msgService.info('You will  be attending ' + this.item.title);
    this.eventService.Accept(this.item).subscribe(data => {
      this.item = data;
      this.setEventImage();
      this.bindInvites();
    }, err => {
      console.log(err, 'err')
    });
  }

  setInviteStatus(a) {


    if (a) {


      let msgText = "";
      if (a == 3) msgText = "not";

      if (this.item.eventProfile.find(x => x.profileId == this.currentProfile.id)) {
        if (a == 4) {
          if (this.item.eventProfile.find(x => x.profileId == this.currentProfile.id).inviteStatus == a) {
            this.item.eventProfile = this.item.eventProfile.filter(x => x != this.item.eventProfile.find(x => x.profileId == this.currentProfile.id))
            this.msgService.info('You have been registered as no longer interested in attending ' + this.item.title);
          } else {
            this.item.eventProfile.find(x => x.profileId == this.currentProfile.id).inviteStatus = a;
            this.msgService.info('You have been registered as interested in attending ' + this.item.title);
          }

        } else {
          this.item.eventProfile.find(x => x.profileId == this.currentProfile.id).inviteStatus = a;
          this.msgService.info('You will ' + msgText + ' be attending ' + this.item.title);

        }
        this.eventService.Update(this.item).subscribe(data => {

          this.item = data;
          this.setEventImage();
          this.bindInvites();



        });
      } else {



        let newInvite = new EventProfile;

        newInvite.inviteStatus = a;
        newInvite.profileId = this.currentProfile.id;
        newInvite.eventId = this.item.id;
        newInvite.profile = this.currentProfile;
        newInvite.created = new Date();


        this.item.eventProfile.push(newInvite);

        this.eventService.Update(this.item).subscribe(data => {

          this.item = data;
          this.setEventImage();
          this.bindInvites();
          if (a == 4) {
            this.msgService.info('You have been registered as interested in attending ' + this.item.title);
          } else {
            this.msgService.info('You will ' + msgText + ' be attending ' + this.item.title);
          }

        });
      }



    }

  }



  transformFile = (file: NzUploadFile) => {
    this.setLoading(true);
    let url = "";
    return new Observable((observer: Observer<Blob>) => {
      Uploads.convertToBlob(file).then(blobSrc => {
        url = blobSrc as string;
        Uploads.uploadBlob(this.http, url, '/v1/Media/uploadFile/' + 2 + '/' + this.item.id + '/' + this.currentProfile.id).subscribe(data => {

          this.mediaList.loadMedia(false);
          this.mediaGrid.refresh();
          this.setLoading(false);
        });
      });
    });
  }

  openSettings() {
    this.videoChat.openSettings();
  }
  closeSettings() {
    this.videoChat.closeSettings();
  }

}
