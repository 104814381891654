import { EventInterest } from "./EventInterest";
import { Profile } from "./Profile";
import { EventProfile } from "./EventProfile";
import * as moment from 'moment';

export class Event {

  id: number;
  tribeId: number;
  title: string;
  description: string;
  createdBy: number;
  eventDate: Date;
  eventDateSummary: string;
  logoUrl: string;
  imageUrl: string;
  cdnImageUrl?: string;
  status: number;
  sizeLimit: number;
  sizeMaleLimit: number;
  sizeFemaleLimit: number;
  genderWeight: boolean;
  emailSent: boolean;
  url: string;
  viewOrder: number;
  privacyStatus: number;
  addressFirstLine: string;
  addressSecondLine: string;
  addressTown: string;
  addressCounty: string;
  postcode: string;
  country: string;
  locationType: number;
  location: string;
  latitude: string;
  longitude: string;
  startDate: Date;
  endDate: Date;
  updated: Date;
  created: Date;

  eventInterest: EventInterest[];
  eventProfile: EventProfile[];
  createdByProfile: Profile;

  statusText: string;
  privacyStatusText: string;
  locationTypeText: string;
  total: number;

  constructor() {
  }

  public static getEventDateSummary(m: Event) {
    return moment(m.eventDate).format('dddd, MMMM Do, YYYY');
  }

  public static  getEventTimeSummary(m: Event) {
    return moment(m.startDate).format('h:mma') + ' - ' + moment(m.endDate).format('h:mma');
  }

}

export class PageableEvent {
  model: Event[];
  total: number;
}