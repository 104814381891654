import { AfterViewInit, Component, ElementRef, Inject, Input, NgZone, OnChanges, OnInit, SimpleChange, ViewChild } from '@angular/core';
import { interval } from 'rxjs';
import { take } from 'rxjs/operators';
import { ProfileService } from '../../../../core/services/profile.service';
import { Profile } from '../../../../models/Profile';
import { D3Graph } from '../../../../models/shared/graph/d3Graph';
import { BaseComponent } from '../../base-component/base-component.component';

declare var window: any;

@Component({
  selector: 'profile-graph',
  templateUrl: './profile-graph.component.html',
  styleUrls: ['./profile-graph.component.css']
})
export class ProfileGraphComponent extends BaseComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('graphContainer', { static: false }) graphContainer: ElementRef;
  @ViewChild('svgElement', { static: false }) svgElement: ElementRef;
  @Input() profile: Profile;
  @Input() mode: string;

  nodes: number = 0;
  links: number = 0;
  scaleFactor: number = 0;
  offSet: number = 0;
  svgSize: string = '';

  public d3Graph: D3Graph;

  constructor(
    private profileService: ProfileService,
    @Inject(NgZone) private zone: NgZone
  ) {
    super();

    window.goUrl = (data) => {
      this.nav(data);
    }
  }

  ngOnInit() {

  }
  


  ngAfterViewInit() {
    this.loadGraph();
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    
      this.loadGraph();
   
  }


  loadGraph() {

    if (this.profile) {
      interval(500).pipe(take(1)).subscribe(x => {
        switch (this.mode) {
          case '1':
            this.subscriptions.push(this.profileService.GetGraph(this.profile.id).subscribe(data => {
              if (data != null) {
                if (this.d3Graph == null) {
                  this.d3Graph = new D3Graph(this.zone, this.subscriptions, this.profileService, this.graphContainer, this.svgElement);
                } else {
                  this.d3Graph.reset([], []);
                }
                
                //this.nodes = data.nodes.length;
                //this.d3Graph.setHeight = 1250;
                //this.d3Graph.setWidth = 1250;
                //if (data.nodes.length > 4) {
                //  this.scaleFactor = 2050 + ((data.nodes.length - 4) * 45)
                //  this.offSet = (1250 - this.scaleFactor) / 2
                //}
                //if (data.nodes.length > 5) {
                //  this.scaleFactor = 2250 + ((data.nodes.length - 4) * 45)
                //  this.offSet = (1150 - this.scaleFactor) / 2
                //} else {
                //  this.scaleFactor = 1250;
                //  this.offSet = (1650 - this.scaleFactor) / 2
                //}

                //this.offSet = (1250-this.scaleFactor)/2
                

                this.d3Graph.init();
                this.d3Graph.startGraph(data.nodes, data.links, this.profile.id);
                const tMax = data.nodes.reduce(function (prev, current) {
                  return (prev.group > current.group) ? prev : current
                }) //returns object

               
                this.offSet = 0;
                this.offSet -= (tMax.group / 10) * 120;
                
                this.scaleFactor = (tMax.group / 10) * 420;
                this.svgSize = (this.offSet*1.5) + " " + (this.offSet*1.4) + " " + this.scaleFactor.toString() + " " + this.scaleFactor.toString();
                this.svgElement.nativeElement.setAttribute("viewBox", this.svgSize);
                this.setLoading(false);
              }
            }));
            break;
          case '2':
            this.subscriptions.push(this.profileService.GetConnectedGraph(this.currentProfile.id, this.profile.id).subscribe(data => {
              if (data != null) {
                if (this.d3Graph == null) {
                  this.d3Graph = new D3Graph(this.zone, this.subscriptions, this.profileService, this.graphContainer, this.svgElement);
                } else {
                  this.d3Graph.reset([], []);
                }
                //this.nodes = data.nodes.length;
                //this.d3Graph.setHeight = 650;
                //this.d3Graph.setWidth = 650;
                //if (data.nodes.length > 4) {
                //  this.scaleFactor = 650 + ((data.nodes.length - 4) * 17.5)
                //} else {
                //  this.scaleFactor = 650;
                //}

               
                
                this.d3Graph.init();
                this.d3Graph.startGraph(data.nodes, data.links, this.profile.id);
                const tMax = data.nodes.reduce(function (prev, current) {
                  return (prev.group > current.group) ? prev : current
                }) //returns object

                this.offSet = 0;
                this.offSet -= (data.nodes.length ) * 150;

                this.scaleFactor = (data.nodes.length ) * 550;
                this.svgSize = (this.offSet*1.5) + " " + this.offSet + " " + this.scaleFactor.toString() + " " + this.scaleFactor.toString();
                this.svgElement.nativeElement.setAttribute("viewBox", this.svgSize);
                this.setLoading(false);
              }
            }));
            break;
        }

      });
    }
  }

  onResize() {
    super.onResize();
    //this.loadGraph();
  }

}
