import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Profile } from 'src/app/models/Profile';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth2.service';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
    constructor(private authenticationService: AuthService, private router: Router) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let currentProfile: Profile | null = this.authenticationService.currentProfileValue;
        let clonedRequest = req.clone();

        if (currentProfile !== null) {
            clonedRequest = req.clone({
                headers: req.headers.set('Authorization', 'Bearer ' + currentProfile.token)
            });
        }
        return next.handle(clonedRequest).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401 && !req.url.includes('/login')) {
                    this.authenticationService.logout();
                    this.router.navigate(['/login']);
                }

                return throwError(error);
            })
        );
    }
}
