import { HttpClient, HttpRequest, HttpResponse } from "@angular/common/http";
import { NzUploadFile } from "ng-zorro-antd/upload";
import { Observable, Observer, of } from "rxjs";
import { filter } from "rxjs/operators";

export class Uploads {


  public static uploadBlob(http: HttpClient, blobSrc: string, actionUrl: string): Observable<any> {
    const blob = Uploads.b64toBlob(blobSrc, "image/jpeg");
    const formData = new FormData();
    formData.append('file', blob, "image.jpg");
    const req = new HttpRequest('POST', actionUrl, formData, {/* reportProgress: true*/ });
    return http.request(req).pipe(filter(e => e instanceof HttpResponse));
    //.subscribe(data => {
    //  return new Observable((observer: Observer<Blob>) => { data });
    //});
    //  .subscribe(
    //  (data) => {
    //    this.item = (<any>data).body;
    //    //if (count == 0) this.onFinishSaving();
    //  },
    //  () => { }
    //);
  }

  public static convertToBlob(file: NzUploadFile): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      // tslint:disable-next-line:no-any
      reader.readAsDataURL(file as any);
      reader.onload = () => {
        const img = document.createElement('img');
        img.src = reader.result as string;
        img.onload = () => {
          resolve(img.src);
        }
      }
    });
  }

  public static getBase64(img: File, callback: (img: string) => void): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result!.toString()));
    reader.readAsDataURL(img);
  }

  public static getBase64Promise(file: File): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  public static b64toArrayBuffer(dataURI) {
    const byteString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return ia;
  }

  public static b64toBlob(dataURI, mimetype) {
    return new Blob([this.b64toArrayBuffer(dataURI)], {
      type: mimetype
    });
  }




}
