import { Component, OnInit, OnDestroy, Input, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileService } from '../../../core/services/profile.service';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { Profile } from '../../../models/Profile';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Configuration } from '../../../app.constants';
import { MediaService } from '../../../core/services/media.service';
import { MediaTypes } from '../../../models/enums/MediaTypes';
import { Media } from '../../../models/Media';
import { GlobalService } from '../../../core/services/global.service';
import { BaseComponent } from '../../shared/base-component/base-component.component';
import { Uploads } from '../../../models/shared/upload/Uploads';
import { ImageCroppedEvent, base64ToFile } from 'ngx-image-cropper';
import { HttpRequest, HttpClient, HttpResponse, HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-registration-reg-9-pictures',
  styleUrls: ['./reg-9-pictures.component.css'],
  templateUrl: './reg-9-pictures.component.html',
})
export class Reg9PicturesComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() step: number;
  @ViewChild('FileSelectInputDialog') FileSelectInputDialog: ElementRef;
  checkImageList: boolean = false;
  isNotified: boolean = false;
  isVisible = false;
  imageChangedEvent: any = '';
  buttonsDisabled = false;
  croppedImage: any = '';
  profileImage = "";
profileUploaded = false;
  fileList: any[] = [
    //{ 
    //  uid: '-1',
    //  name: 'image.png',
    //  status: 'done',
    //  url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
    //},
    //{
    //  uid: '-2',
    //  name: 'image.png',
    //  status: 'done',
    //  url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
    //},
    //{
    //  uid: '-3',
    //  name: 'image.png',
    //  status: 'done',
    //  url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
    //},
    //{
    //  uid: '-4',
    //  name: 'image.png',
    //  status: 'done',
    //  url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
    //},
    //{
    //  uid: '-5',
    //  name: 'image.png',
    //  status: 'error'
    //}
  ];
  previewImage: string | undefined = '';
  previewVisible = false;

  constructor(
    router: Router,
    globalService: GlobalService,
    authenticationService: AuthenticationService,
    private profileService: ProfileService,
    private mediaService: MediaService,
    private configuration: Configuration,
    private msg: NzMessageService,
        private http: HttpClient
  ) {
    super();
  }

  ngOnInit() {
    this.currentProfile = this.authenticationService.getCurrentProfile();

    localStorage.setItem('currentProfile', JSON.stringify(this.currentProfile));
    if (this.currentProfile.emailConfirmed != true) this.nav("registration/3");
    if (this.currentProfile.status == 2) { this.nav("/"); }
    this.load();
  }

  submit() {
    this.profileService.Update(this.currentProfile).subscribe(data => {
      this.currentProfile = data;
      this.nav('/registration/8');
    });
  }

  load() {
    this.profileService.Get(this.currentProfile.id).subscribe(data => { //update from api
      this.currentProfile = data;
      localStorage.setItem('currentProfile', JSON.stringify(data));
      this.profileImage = this.getImageUrl(this.currentProfile, 256);
      this.setLoading(false);
      this.mediaService.GetByMediaType(+(MediaTypes.Profile_Image), this.currentProfile.id).subscribe(data => {
        this.fileList = data.map(x => {
          return {
            name: x.filename,
            url: this.globalService.getMediaUrl(x, 256),
            media: x
          }
        });
        
        if (this.fileList.length >= 3) {
          this.checkImageList = true;
        } else {
          this.checkImageList = false;
        }
        this.fileList = this.fileList.splice(0, 3);
        while (this.fileList.length < 3) {
          this.fileList.push(
            {
              name: null,
              url: null,
              media: null
            })
        }

      });
    });
    if (this.currentProfile.imageUrl && this.currentProfile.imageUrl.length > 0) {
      this.croppedImage = this.getImageUrl(this.currentProfile, 256)
    }
  }

  handlePreview = async (file: NzUploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await Uploads.getBase64Promise(file.originFileObj!);
    }
    this.previewImage = file.url || file.preview;
    this.previewVisible = true;
  };


  getMediaUrl = (m: Media): string => { return this.globalService.getMediaUrl(m, 256) + "?" + Date.now; }

  beforeUpload() {
  }

  handleChange(info: { file: NzUploadFile }): void {
    
    this.setLoading(true);

    switch (info.file.status) {
      case 'uploading':
        if (!this.isNotified) {
          this.msg.loading('Uploading image, please wait...');
          this.isNotified = true;
        }
        break;
      case 'done':
        // Get this url from response in real world.
        //this.getBase64(info.file!.originFileObj!, (img: string) => {
        //  this.isLoading = false;
        //  this.avatarUrl = img;
        //});
        this.msg.success('Image Uploaded!!');
        this.currentProfile.imageUrl = null;
        this.isNotified = false;
        this.profileImage = "";
   
        this.load();
        break;
      case 'error':
        this.msg.error('Network error');
        this.setLoading(false);
        this.isNotified = false;
        break;
      }
    
  }
  

  getImageUrl = (p: Profile, size: number): string => { return this.globalService.getProfileImageUrl(p, size); }


  removeImage(file) {
    console.log(this.fileList);
    this.mediaService.Delete(file.media.id).subscribe(r => {
      file.name = null;
      file.url = null;
      file.media = null;
    });
   
    
  }


  fileChangeEvent(event: any): void {
    this.isVisible = true;
    this.imageChangedEvent = event;
    this.setLoading(true);
    this.buttonsDisabled = false;

  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.setLoading(true);
  }
  imageLoaded(image?: HTMLImageElement) {
    this.setLoading(false);
  }
  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    this.msg.success('Invalid file type. Must be JPG or PNG.');
  }

  handleOk(): void {
    this.buttonsDisabled = true;
    this.setLoading(true);
    let fileToUpload = base64ToFile(this.croppedImage);
    const formData = new FormData();
    formData.append('file', fileToUpload, "avatar.jpg");

    this.http.post('/v1/Profile/uploadPhoto/' + this.currentProfile.id, formData, { reportProgress: true, observe: 'events' })
      .subscribe(event => {

        if (event.type === HttpEventType.Response) {

          this.isVisible = false;
          this.setLoading(false);
          this.msg.success('Image Uploaded!!');
          this.currentProfile.imageUrl = null;
          this.isNotified = false;
          this.profileUploaded = true;
          this.buttonsDisabled = false;
          this.profileService

            .Get(this.currentProfile.id)
            .subscribe(
              data => {
                localStorage.setItem('currentProfile', JSON.stringify(data));
               
                this.currentProfile = data;
                
                //this.loaderService.hide();
                //this.ngProgress.complete();
      
              },
              error => console.log(error),
              () => { }
          );
       
          
          
        }
      });
    
  }

  handleCancel(): void {
    this.setLoading(false);
    this.isVisible = false;
  }
  public OpenAddFilesDialog() {
    const e: HTMLElement = this.FileSelectInputDialog.nativeElement;
    e.click();
  }


}



