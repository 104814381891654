import { Component, OnInit, OnDestroy } from '@angular/core';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { GridSettings } from '../../../models/shared/grids/gridSettings';
import { LogService } from '../../../core/services/log.service';
import { Log } from '../../../models/Log';
import { Profile } from '../../../models/Profile';
import { BaseComponent } from '../../shared/base-component/base-component.component';
import { Router } from '@angular/router';
import { GlobalService } from '../../../core/services/global.service';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { TribeService } from '../../../core/services/tribe.service';
import { DashboardService } from '../../../core/services/dashboard.service'
import { Tribe } from '../../../models/Tribe';
import { NzCardComponent } from 'ng-zorro-antd/card'; 
import { DashboardFilters } from '../../../models/shared/DashboardFilters';
import { from } from 'rxjs';

import { ChartConfiguration, ChartOptions, ChartType } from "chart.js";
import { Dashboard, OttoKeyValuePaire } from '../../../models/Dashboard';
 


@Component({
  selector: 'admin-dashboard',
  styleUrls: ['./dashboard.component.css'],
  templateUrl: './dashboard.component.html',
})
export class AdminDashboardComponent extends BaseComponent implements OnInit, OnDestroy {
  gridSettings: GridSettings;
  tribes: Tribe[] = [];
  profiles: Profile[] = [];


  selectedDateRange: any| null = null;
  selectedTribe: number | null = null;

  public lineChartData: ChartConfiguration<'line'>['data'] = { datasets: [], labels: [] };
  public lineChartOptions: ChartOptions<'line'> = { responsive: false };
  public lineChartLegend = true;

  public pieChartOptions: ChartOptions<'pie'> = {responsive: false,};
  public pieChartLabels = [];
  public pieChartDatasets = [{data: []}];
  public pieChartLegend = true;
  public pieChartPlugins = [];
  public averageVisitPerUser: number;

  constructor(
    router: Router,
    private dashboardService: DashboardService,
    authenticationService: AuthenticationService,
    private tribeService: TribeService,
  ) {
    super();

    this.gridSettings = new GridSettings();
    this.gridSettings.pageSize = 50;
    this.gridSettings.pageIndex = 0;
  }

  ngOnInit() {
    const today = new Date();
    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    this.selectedDateRange = [firstDay, lastDay];

    this.loadTribes();
    this.loadDashboardData();
  
  
  }

  loadDashboardData() {
    const filters: DashboardFilters = new DashboardFilters();
    let fromDate: Date | null;
    let toDate: Date | null;

    if (this.selectedDateRange && this.selectedDateRange.length) {
      fromDate = this.selectedDateRange[0];
      toDate = this.selectedDateRange[1];
    }
    filters.from = fromDate;
    filters.to = toDate;
    filters.tribeId = this.selectedTribe;

    this.dashboardService.GetData(filters).subscribe(data => {
      this.profiles = data.activeProfilesByMessages;
      this.averageVisitPerUser = data.averageVisit;
      this.processDashboardData(data);
    })
  }

  private processDashboardData(data: Dashboard) {
    this.initMessageVolumeGraph(data);
    this.initTribeDashboardData(data);
   
  }


  private initTribeDashboardData(dashboard: Dashboard) {
    const label = dashboard.messageVolumeByTribe.map(x => x.key);
    this.pieChartLabels = label;
    const data = dashboard.messageVolumeByTribe.map(x => x.value);
    this.pieChartDatasets = [{ data: data }];
  }

  private initMessageVolumeGraph(data: Dashboard) {
    const labels = data.messageVolume.map(x => x.key);
    const datasets = [
      {
        data: data.messageVolume.map(x => x.value),
        label: 'Series A',
        fill: true,
        tension: 0.5,
        borderColor: 'black',
        backgroundColor: 'rgba(255,0,0,0.3)'
      }
    ]

    this.lineChartData = { datasets: datasets, labels: labels }
  }


  loadTribes() {
    this.setLoading(true);
    this.tribeService.GetAll("", this.gridSettings.pageIndex, 100, this.gridSettings.sortField, this.gridSettings.sortAsc ? 1 : -1).subscribe(data => {
      this.tribes = data
      console.log(this.tribes,'tribes')
      this.setLoading(false);
    })
  }


  calculateAverage(visitsPerMonth: number[]): number {
    const totalVisits = visitsPerMonth.reduce((acc, val) => acc + val, 0);
    return totalVisits / visitsPerMonth.length;
  }

  applyFilters() {
    console.log('apply file')
    this.loadDashboardData();

  }
}


