import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Profile } from '../../../models/Profile';
import { BaseComponent } from '../../shared/base-component/base-component.component';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { GlobalService } from '../../../core/services/global.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-registration-reg-10-terms',
  styleUrls: ['./reg-10-terms.component.css'],
  templateUrl: './reg-10-terms.component.html',
})
export class Reg10TermsComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() step: number;
  

  public profiles: Profile[];
  checked: boolean;

  constructor(
    router: Router,
    globalService: GlobalService,
    authenticationService: AuthenticationService,
    //private contactService: ContactService
  ) {
    super();
  }

  ngOnInit() {
    //this.contactService.GetAll("", 0, 10, "Created", 1).subscribe(data => {
    //  this.contacts = data;
    this.currentProfile = this.authenticationService.getCurrentProfile();
    if (this.currentProfile.status == 2) { this.nav("/"); }
      this.setLoading(false);
    //})
  }

}
