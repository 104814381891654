import { Profile } from "./Profile";

export class Notification {
  id: number;
  tribeId: number;
  content: string;
  status: number;
  toProfileId: number;
  globalId: number;
  globalObjectType: number;
  created: Date;
 

  
  toProfile: Profile;
  imageURL: string;
  titleText: string;
  statusText: string;
  globalObjectTypeText: string;

  constructor() {
  }

 
}
