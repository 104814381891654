import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AccountComponent } from "./components/account/account.component";
import { SubscriptionComponent } from "./components/account/subscription/subscription.component";
import { ActionJoinComponent } from "./components/action/join/join.component";

import { ActionVerifyEmailComponent } from "./components/action/verify-email/verify-email.component";
import { AdminComponent } from "./components/admin/admin.component";
import { AdminEventsComponent } from "./components/admin/events/events.component";
import { AdminInterestsComponent } from "./components/admin/interests/interests.component";
import { AdminTribesComponent } from "./components/admin/tribes/tribes.component";
import { AdminLogsComponent } from "./components/admin/logs/logs.component";
import { AdminDashboardComponent } from "./components/admin/dashboard/dashboard.component";
import { AdminNewsItemsComponent } from "./components/admin/news-items/news-items.component";
import { AdminProfilesComponent } from "./components/admin/profiles/profiles.component";
import { AdminQuestionsComponent } from "./components/admin/questions/questions.component";
import { AdminSettingsComponent } from "./components/admin/settings/settings.component";
import { EventEditComponent } from "./components/events/event-edit/event-edit.component";
import { EventComponent } from "./components/events/event/event.component";
import { EventsComponent } from "./components/events/events.component";
import { GalleryComponent } from "./components/gallery/gallery.component";
import { HomeComponent } from "./components/home/home.component";
import { InviteComponent } from "./components/invite/invite.component";
import { LoginComponent } from "./components/login/login.component";
import { ResetPasswordComponent } from "./components/login/reset-password/reset-password.component";
import { LogoutComponent } from "./components/logout/logout.component";
import { MemberComponent } from "./components/members/member/member.component";
import { MembersComponent } from "./components/members/members.component";
import { MessagesComponent } from "./components/messages/messages.component";
import { MessagesTestComponent } from "./components/messages/test/test.component";
import { NewsItemComponent } from "./components/news/news-item/news-item.component";
import { NewsComponent } from "./components/news/news.component";
import { PrivacyComponent } from "./components/privacy/privacy.component";
import { RegistrationComponent } from "./components/registration/registration.component";
import { TermsComponent } from "./components/terms/terms.component";
import { AuthGuard } from "./core/guards/auth-guard";
import { ActionAutoLoginComponent } from "./components/action/auto-login/auto-login.component";
import { RefreshProfileComponent } from "./components/account/refresh-profile/refresh-profile.component";
import { CancelSubscriptionComponent } from "./components/account/subscription/cancel/cancel-subscription.component";

const appRoutes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthGuard], pathMatch: 'full', data: { state: 'home' } },

  { path: 'login', component: LoginComponent, data: { state: 'login' } },
  { path: 'reset-password', component: ResetPasswordComponent, data: { state: 'reset-password' } },
  { path: 'registration', component: RegistrationComponent, data: { state: 'registration' } },
  { path: 'registration/:step', component: RegistrationComponent, data: { state: 'registration/:step' } },
  { path: 'invite', component: InviteComponent, canActivate: [AuthGuard], data: { state: 'invite' } },
  { path: 'account', component: AccountComponent, canActivate: [AuthGuard], data: { state: 'account' } },
  { path: 'account/subscription', component: SubscriptionComponent, canActivate: [AuthGuard], data: { state: 'account/subscription' } },
  { path: 'account/subscription/:view', component: SubscriptionComponent, canActivate: [AuthGuard], data: { state: 'account/subscription' } },
  { path: 'account/subscription/:view/:response', component: SubscriptionComponent, canActivate: [AuthGuard], data: { state: 'account/subscription' } },
  { path: 'account/subscription/:view/:response/:priceId', component: SubscriptionComponent, canActivate: [AuthGuard], data: { state: 'account/subscription' } },
  { path: 'account/manage-subscription', component: CancelSubscriptionComponent, canActivate: [AuthGuard], data: { state: 'account/manage-subscription' } },
  { path: 'account/refresh-profile', component: RefreshProfileComponent, data: { state: 'account/refresh-profile' } },
  { path: 'logout', component: LogoutComponent, data: { state: 'logout' } },

  { path: 'action/login', component: ActionAutoLoginComponent, data: { animation: '' } },
  { path: 'action/join', component: ActionJoinComponent, data: { state: 'action/join' } },
  { path: 'action/join:t', component: ActionJoinComponent, data: { state: 'action/join:t' } },
  { path: 'action/verify:pId', component: ActionVerifyEmailComponent, data: { state: 'action/verify:pId' } },
  { path: 'action/verify', component: ActionVerifyEmailComponent, data: { state: 'action/verify' } },

  { path: 'admin', component: AdminComponent, canActivate: [AuthGuard], data: { state: 'admin' } },
  { path: 'admin/profiles', component: AdminProfilesComponent, canActivate: [AuthGuard], data: { state: 'admin/profiles' } },
  { path: 'admin/events', component: AdminEventsComponent, canActivate: [AuthGuard], data: { state: 'admin/events' } },
  { path: 'admin/interests', component: AdminInterestsComponent, canActivate: [AuthGuard], data: { state: 'admin/interests' } },
  { path: 'admin/tribes', component: AdminTribesComponent, canActivate: [AuthGuard], data: { state: 'admin/tribes' } },
  { path: 'admin/questions', component: AdminQuestionsComponent, canActivate: [AuthGuard], data: { state: 'admin/questions' } },
  { path: 'admin/logs', component: AdminLogsComponent, canActivate: [AuthGuard], data: { state: 'admin/logs' } },
  { path: 'admin/settings', component: AdminSettingsComponent, canActivate: [AuthGuard], data: { state: 'admin/settings' } },
  { path: 'admin/dashboard', component: AdminDashboardComponent, canActivate: [AuthGuard], data: { state: 'admin/settings' } },
  { path: 'admin/news-items', component: AdminNewsItemsComponent, canActivate: [AuthGuard], data: { state: 'admin/news-items' } },

  { path: 'members', component: MembersComponent, canActivate: [AuthGuard], data: { state: 'members' } },
  { path: 'members/:id', component: MemberComponent, canActivate: [AuthGuard], data: { state: 'member' } },
  { path: 'events', component: EventsComponent, canActivate: [AuthGuard], data: { state: 'events' } },
  { path: 'events/add', component: EventEditComponent, canActivate: [AuthGuard], data: { state: 'events/add' } },
  { path: 'events/edit/:eId', component: EventEditComponent, canActivate: [AuthGuard], data: { state: 'events/edit/:eId' } },
  { path: 'events/:eId', component: EventComponent, canActivate: [AuthGuard], data: { state: 'events/view/:eId' } },

  { path: 'messages', component: MessagesComponent, canActivate: [AuthGuard], data: { state: 'messages' } },
  { path: 'messages/:to', component: MessagesComponent, canActivate: [AuthGuard], data: { state: 'messages/:to' } },
  { path: 'gallery', component: GalleryComponent, canActivate: [AuthGuard], data: { state: 'gallery' } },
  { path: 'terms', component: TermsComponent, data: { state: 'terms' } },
  { path: 'privacy', component: PrivacyComponent, data: { state: 'privacy' } },
  { path: 'messages/test', component: MessagesTestComponent, canActivate: [AuthGuard], data: { state: 'one' } },
  { path: 'news', component: NewsComponent, canActivate: [AuthGuard], data: { state: 'news' } },
  { path: 'news/:id', component: NewsItemComponent, canActivate: [AuthGuard], data: { state: 'news-item/:id' } }
];

export const AppRouting = RouterModule.forRoot(appRoutes, {});
