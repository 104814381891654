import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NewsItem } from 'src/app/models/NewsItem';
import { BaseComponent } from '../../shared/base-component/base-component.component';
import moment = require('moment');
import { NewsItemService } from '../../../../app/core/services/news-item.service';;


@Component({
  selector: 'comment-item',
  templateUrl: './comment-item.component.html',
  styleUrls: ['./comment-item.component.scss']
})
export class CommentItemComponent extends BaseComponent {
  @Input() item: NewsItem;
  @Output() onItemDeleted: EventEmitter<any> = new EventEmitter();

  constructor(private newsItemService: NewsItemService,) {
    super();
  }

  onDelete() {
    this.newsItemService.Delete(this.item.id).subscribe(() => {
      console.log('slslsl..lslsl')
      this.onItemDeleted.emit(this.item);
    })
  }

  getTimeAgo = (d: Date): string => { return moment(d).fromNow(); }
}
