import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { animations } from 'ack-angular-fx';
import { TitleEventsEmitter } from '../../core/events/title.events';
import { Event } from '../../models/Event';
import { FilterSettings } from '../../models/shared/grids/filterSettings';
import { BaseComponent } from '../shared/base-component/base-component.component';
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation, bounceInOnEnterAnimation } from 'angular-animations';
import { ProfileService } from '../../core/services/profile.service';
import { EventListComponent } from '../shared/event/event-list/event-list.component';
import * as moment from 'moment';
import { NzTabComponent, NzTabSetComponent } from 'ng-zorro-antd/tabs';

import { Router, Scroll } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { filter } from 'rxjs/operators';
import { Event as RouterEvent } from '@angular/router';

const FILTER_STATE_KEY = 'members.filter.state';
const RECORD_COUNT_KEY = 'members.record.count';

@Component({
  selector: 'events-component',
  templateUrl: 'events.component.html',
  styleUrls: ['events.component.css'],
  animations: [
    animations,
    bounceInOnEnterAnimation({ anchor: 'enter', duration: 500, delay: 0 }),
    //fadeInOnEnterAnimation({ anchor: 'enter', duration: 500, delay: 0 }),
    fadeOutOnLeaveAnimation({ anchor: 'leave', duration: 500, delay: 0 })
  ]
  //animations: [fadeInAnimation],
  //host: { '[@fadeInAnimation]': '' }
  //animations: [fadeInAnimation],
  //host: { '[@fadeInAnimation]': '' }
})

export class EventsComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('eventsUpcoming') eventsUpcoming: EventListComponent;
  @ViewChild('eventsPast') eventsPast: EventListComponent;
  @ViewChild('eventsMy') eventsMy: EventListComponent;
  @ViewChild('myTab') myTabComponent: NzTabSetComponent;

  public filterSettingsPast: FilterSettings;
  public filterSettingsUpcoming: FilterSettings;
  public filterSettingsMyEvents: FilterSettings;
  public pastEvents = false;
  public myEvents = false;
  scrollPositioin: any;
  //avatarUrl?: string;
  currentTabIndex$: number = 0;
  recordCount: number = 0;
  max = 9;

  events: Event[];

  constructor(
    private profileService: ProfileService,
    private titleEventsEmitter: TitleEventsEmitter,
    public router: Router, private viewportScroller: ViewportScroller,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super();

    this.filterSettingsMyEvents = new FilterSettings();
    this.filterSettingsMyEvents.gender = 0;
    this.filterSettingsMyEvents.distance = 0;
    this.filterSettingsMyEvents.profileId = this.currentProfile.id;
    this.filterSettingsMyEvents.updateDates(moment().subtract('d', 10000).set('hour', 0).toDate(), moment().add('d', 10000).set('hour', 23).set('minute', 59).toDate());
    this.filterSettingsMyEvents.createdBy = true;

    this.filterSettingsPast = new FilterSettings();
    this.filterSettingsPast.forPublic = true;
    this.filterSettingsPast.gender = 0;
    this.filterSettingsPast.distance = 0;
    this.filterSettingsPast.profileId = this.currentProfile.id;
    this.filterSettingsPast.updateDates(moment().subtract('d', 10000).set('hour', 0).toDate(), moment(new Date()).toDate());
    this.filterSettingsPast.past = true;

    this.filterSettingsUpcoming = new FilterSettings();
    this.filterSettingsUpcoming.forPublic = true;
    this.filterSettingsUpcoming.gender = 0;
    this.filterSettingsUpcoming.distance = 0;
    this.filterSettingsUpcoming.profileId = this.currentProfile.id;
    this.filterSettingsUpcoming.updateDates(moment(new Date()).set('hour', 0).toDate(), moment().add('d', 10000).set('hour', 23).set('minute', 59).toDate());

    router.events.pipe(filter((event: RouterEvent,): event is Scroll => event instanceof Scroll)
    ).subscribe(e => {
      this.scrollPositioin = e.position;
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.max = this.getRecordCount();

    this.profileService.Get(this.currentProfile.id).subscribe(data => { //update from api
      this.currentProfile = data;
      localStorage.setItem('currentProfile', JSON.stringify(data));
    });
    this.filterSettingsMyEvents.profileId = this.currentProfile.id;
    this.titleEventsEmitter.broadcastEventTitleChanged("Members");
    this.currentProfile = this.authenticationService.getCurrentProfile();

    const storedTabIndex = localStorage.getItem('activeTabIndex');
    if (storedTabIndex) {
      this.currentTabIndex$ = parseInt(storedTabIndex, 10);
    }

    this.setLoading(false);
  }

  private getRecordCount(): number {
    const recordCount = parseInt(sessionStorage.getItem(RECORD_COUNT_KEY), 10);
    return recordCount || (this.isMobile ? 8 : 9);
  }



  ngAfterViewInit() {
    const activeTab = this.myTabComponent.tabs.get(this.currentTabIndex$);

    const recordCount = parseInt(sessionStorage.getItem(RECORD_COUNT_KEY), 10);
    if (!isNaN(recordCount) && recordCount > 0) {
      this.max = recordCount;
      this.changeDetectorRef.detectChanges();
      console.log(recordCount, 'recor...rec')
    }
    activeTab.nzClick.emit();
  }

  ngOnDestroy(): void {
    localStorage.setItem('activeTabIndex', this.currentTabIndex$.toString());
    sessionStorage.setItem(RECORD_COUNT_KEY, this.recordCount.toString());

  }

  onDataLoaded(recordCount) {
    this.recordCount = recordCount;
    if (this.scrollPositioin) {
      setTimeout(() => {
        this.viewportScroller.scrollToPosition(this.scrollPositioin);
        sessionStorage.removeItem(RECORD_COUNT_KEY);
      }, 0)
    }
  }

  onTabChange(tabIndex) {
    this.currentTabIndex$ = tabIndex;
    localStorage.setItem('activeTabIndex', this.currentTabIndex$.toString());
  }

  onFilterUpcomingUpdated($event) {
    if ($event != null) {
      //this.gridSettings.pageIndex = 0;
      this.filterSettingsUpcoming = $event;
      console.log($event, 'filtered event..');


      this.eventsUpcoming.load();
      //this.loadData();
    }
  }
  loadPast() {

    this.eventsPast.dontLoad = false;
    this.eventsPast.load();
  }

  loadMy() {
    console.log('on the wood and inside')
    this.eventsMy.dontLoad = false;
    this.eventsMy.load();
  }

  onFilterPastUpdated($event) {
    if ($event != null) {
      //this.gridSettings.pageIndex = 0;
      this.filterSettingsPast = $event;
      this.eventsPast.load();
      //this.loadData();
    }
  }

  createEventNav() {
    this.nav("events/add");
  }

  switch() {

  }
}
