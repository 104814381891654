import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { Profile } from '../../Profile';
import { ProfileService } from '../../../core/services/profile.service';

export class FilterSettings {
  public logType: number;
  public profileId: number;
  public keyword: string;
  public gender: number;
  public distance: number;
  public dateType: number;
  public month: number;
  public forProfile: boolean;
  public forPublic: boolean;
  public createdBy: boolean;
  public showApproved: boolean;
  public showOnlyUnapproved: boolean;
  public startDate: Date;
  public endDate: Date;
  public dateRange: Date[];
  public past: boolean;


  public profile: Profile;

  //public filterDateTypes = [];
  //public filterGameStrategyTypes = [];
  //public filterGameFinishStates = [];
  public filterProfiles;
  public interests: string[];

  constructor() {
    this.logType = 0;
    this.profileId = 0;
    this.keyword = "";
    this.forProfile = false;
    this.showApproved = false;
    this.showOnlyUnapproved = false;
    this.month = 0;
    this.distance = 0;
    this.gender = 0;

    this.dateType = 2;
    this.updateDates(moment(new Date()).set('hour', 0).toDate(), moment(new Date()).set('hour', 23).set('minute', 59).toDate())
    this.interests = [];
    //this.filterDateTypes = FilterSettings.getDateTypes();
    //this.filterGameStrategyTypes = FilterSettings.getGameStrategyTypes();
    //this.filterGameFinishStates = FilterSettings.getGameFinishStates();
  }

  public updateDates(start: Date, end: Date) {
    this.startDate = start;
    this.endDate = end;
    this.dateRange = [this.startDate, this.endDate];
  }

  public static getDateTypes() {
    let types = [];
    types.push({ key: 1, title: 'Minute' });
    types.push({ key: 2, title: 'Hour' });
    types.push({ key: 3, title: 'Day' });
    return types;
  }

  public getMomentDateTypeText(): string {
    switch (this.dateType) {
      case 1:
        return 'minutes';
      case 2:
        return 'hours';
      case 3:
        return 'days';
    }
    return "";
  }

  public getMomentDateTypeTextSingular(): string {
    switch (this.dateType) {
      case 1:
        return 'minutes';
      case 2:
        return 'hours';
      case 3:
        return 'days';
    }
    return "";
  }

  public getMomentFormatText(): string {
    switch (this.dateType) {
      case 1:
        return 'DD/MM/YYYY HH:mm';
      case 2:
        return 'DD/MM/YYYY HH:00';
      case 3:
        return 'DD/MM/YYYY';
    }
    return "";
  }

  public loadProfiles(subscriptions: Subscription[], profileService: ProfileService) {
    subscriptions.push(profileService.GetAll("", 0, 10, "", 0).subscribe(data => {
      if (data != null) {
        var e = new Profile();
        e.id = 0;
        e.firstName = "All Profiles";
        this.filterProfiles = [e].concat(data);
        this.profileId = 0;
        this.profile = this.filterProfiles.filter(x => parseInt(x.id) == this.profileId)[0];
      }
    }));
  }

  //public loadStrategies(subscriptions: Subscription[], gameService: GameService) {
  //   let fs = new FilterSettings();
  //   subscriptions.push(gameService.GetSystemStat(fs, 16).subscribe(data => {
  //      if (data != null) {
  //         let stat = data[0];

  //         this.filterGameStrategyTypes = stat.currentStrategies.slice(0, 16);
  //         this.filterGameStrategyTypes = ([{ key: 0, value: 'All' }]).concat(this.filterGameStrategyTypes);
  //         //this.gameStrategiesCount = stat.currentStrategies.length;
  //         console.log(this.filterGameStrategyTypes);
  //         this.gameStrategy = 0;//{ key: this.filterGameStrategyTypes[0].key, value: this.filterGameStrategyTypes[0].value };

  //         //let successCount = this.items.filter(x => x.finishState == 1).length;
  //         //let failCount = this.items.filter(x => x.finishState != 1).length;
  //         //this.summaryText = successCount + "/" + this.items.length + " (" + Math.round((successCount / this.items.length) * 100) + "%)";
  //         //this.loadChart();
  //      }
  //   }));
  //}
}
