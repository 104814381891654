import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Profile } from '../../../models/Profile';
import { Router } from '@angular/router';
import { GlobalService } from '../../../core/services/global.service';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { BaseComponent } from '../../shared/base-component/base-component.component';
import { ProfileService } from '../../../core/services/profile.service';

@Component({
  selector: 'app-registration-reg-11-registered',
  styleUrls: ['./reg-11-registered.component.css'],
  templateUrl: './reg-11-registered.component.html',
})
export class Reg11RegisteredComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() step: number;
  

  public profiles: Profile[];

  constructor(
    router: Router,
    globalService: GlobalService,
    private profileService: ProfileService,
    authenticationService: AuthenticationService,
    //private contactService: ContactService
  ) {
    super();
  }

  ngOnInit() {
    this.setLoading(false);
    this.profileService.Get(this.currentProfile.id).subscribe(data => {
        this.currentProfile = data;
      localStorage.setItem('currentProfile', JSON.stringify(data));
      this.profileService.SendCompletionEmail(this.currentProfile).subscribe(data => { console.log(data); });
});
    if (this.currentProfile.status == 2) this.nav("/");
    //this.contactService.GetAll("", 0, 10, "Created", 1).subscribe(data => {
    //  this.contacts = data;
    //})
  }
}
