import { Event } from "./Event";
import { Profile } from "./Profile";

export class NewsItem {
  id: number;
  tribeId: number;
  title: string;
  description: string;
  imageFilename: string;
  link: string;
  status: number;
  itemType: number;
  eventId: number;
  profileId: number;
  updated: Date;
  created: Date;

  profile: Profile[];
  event: Event[];

  statusText: string;
}
