import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient } from "@angular/common/http";
import { AuthenticationService } from './authentication.service';
import { Configuration } from '../../app.constants';
import { Tribe } from '../../models/Tribe';
import { DashboardFilters } from '../../models/shared/DashboardFilters';
import { Dashboard } from '../../models/Dashboard';

@Injectable()
export class DashboardService {
  private isBrowser: boolean;
  private actionUrl: string;

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    configuration: Configuration,
    private authenticationService: AuthenticationService
  ) {
    this.actionUrl = configuration.Server + 'v1/Dashboard/';
    
  }


  public GetData = (filters: DashboardFilters): Observable<Dashboard> => {
    return this.http.post<Dashboard>(this.actionUrl + 'data', JSON.stringify(filters), this.authenticationService.getHttpOptions());
  }
 
}
