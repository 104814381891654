import { QuestionAnswer } from "./QuestionAnswer";
import { ProfileInterest } from "./ProfileInterest";
import { Configuration } from "../app.constants";
import { Tribe } from "./Tribe";

export class Profile {
  id: number;
  tribeId: number;
  title: string;
  firstName: string;
  surname: string;
  fullName: string;
  emailAddress: string;
  phoneNumber: string;
  password: string;
  imageUrl: string;
  location: string;
  latitude: number;
  longitude: number;
  postCode: string;
  gender: number;
  status: number;
  emailConfirmed: boolean;
  messageNotifications: boolean;
  generalNotifications: boolean;
  textNotifications: boolean;
  lastLogin: Date;
  token: string;
  invitedBy: number;
  dob: Date;
  stripeCustomerID: string;
  subscriptionStatus: number;
  subscriptionExpiry: Date;
  updated: Date;
  created: Date;
  userLevel: number;

  salutation: string;
  questionAnswerText: string;

  questionAnswer: QuestionAnswer[];
  profileInterest: ProfileInterest[];
  invitedByProfile: Profile;
  tribe: Tribe;
  invitedBySalutation: string;
  hasCompletedAnswerQuestions: boolean;

  //Hacking
  total: number;

  constructor() {
    this.fullName = `${this.firstName} ${this.surname}`;
  }

  public static getImageUrl(configuration: Configuration, p: Profile, size: number) {
    if (p.imageUrl) {
      let path = p.imageUrl;
      switch (size) {
        case 1200: path = "1200/1200x900.jpg"; break;
        case 800: path = "800/800x600.jpg"; break;
        case 256: path = "256/256x192.jpg"; break;
      }

      return configuration.CDNUrl + '/profile/' + p.id + '/' + path;// + '?random=' + Math.random();// + '?t=' + parseInt((Math.random() * 1000).toString()
    } else {
      return './assets/img/avatar.png';
    }
  }
}


