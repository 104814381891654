import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TitleEventsEmitter } from '../../../core/events/title.events';
import { BaseComponent } from '../../shared/base-component/base-component.component';

@Component({
  templateUrl: 'reset-password.component.html',
  //styleUrls: ['resetPassword.component.css']
})

export class ResetPasswordComponent extends BaseComponent implements OnInit, OnDestroy {

  resetForm!: UntypedFormGroup;
  resetConfirmForm!: UntypedFormGroup;



  public model: any = {};
  public message: string = "";
  public messageReset: string = "";
  public tokenParamsTimeEncrypted: string = "";

  constructor(
    private route: ActivatedRoute,
    private titleEventsEmitter: TitleEventsEmitter,
    private fb: UntypedFormBuilder
  ) {
    super();

    this.resetForm = this.fb.group({
      emailAddress: [null, [Validators.required, Validators.email]],
      //password: [null, [Validators.required]],
      //remember: [true]
    });
    this.resetConfirmForm = this.fb.group({
      password: [null, [Validators.required]],
      checkPassword: [null, [Validators.required, this.confirmationValidator]],
    });
  }

  ngOnInit() {
    this.titleEventsEmitter.broadcastEventTitleChanged("Reset Password");

    let token = this.route.snapshot.queryParamMap.get('t');
    this.tokenParamsTimeEncrypted = token;

    this.setLoading(false);
  }

  sendEmail() {
    this.setLoading(true);
    this.authenticationService.sendResetEmail(this.model.emailAddress)
      .subscribe(
        data => {
          this.setLoading(false);

          this.resetForm.reset();
          //.controls['emailAddress'].setValue('');
          //for (const i in this.resetForm.controls) {
          //  this.resetForm.controls[i].markAsUntouched();
          //  this.resetForm.controls[i].updateValueAndValidity();
          //}

          this.notify('success', 'You have been sent a password reset email.');
          this.message = "You have been sent a password reset email."
        },
        error => {
          if (error.status == 404) {
            this.message = "An account with this email address does not exist";
          } else {
            this.message = "Error sending email. Please try again.";
          }
          this.setLoading(false);
        });
  }

  reset() {
    this.setLoading(true);
    if (this.model.password != this.model.checkPassword) {
      this.messageReset = "Please ensure both passwords match";
    } else {
      this.authenticationService.resetPassword(this.tokenParamsTimeEncrypted, this.model.password)
        .subscribe(
          data => {
            this.router.navigate(['/login']);
          },
          error => {
            if (error.status == 404) {
              this.messageReset = "Account not found";
            } else {
              this.message = "Error resetting password";
            }
            this.setLoading(false);
          });
    }
  }

  updateConfirmValidator(): void {
    /** wait for refresh value */
    Promise.resolve().then(() => this.resetConfirmForm.controls.checkPassword.updateValueAndValidity());
  }

  confirmationValidator = (control: UntypedFormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.resetConfirmForm.controls.password.value) {
      return { confirm: true, error: true };
    }
    return {};
  };

}
