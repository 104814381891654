import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Profile } from '../../../models/Profile';
import { ProfileService } from '../../../core/services/profile.service';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { BaseComponent } from '../../shared/base-component/base-component.component';
import { GlobalService } from '../../../core/services/global.service';
import { AuthenticationService } from '../../../core/services/authentication.service';

@Component({
  selector: 'app-registration-reg-8-interests',
  styleUrls: ['./reg-8-interests.component.css'],
  templateUrl: './reg-8-interests.component.html',
})
export class Reg8InterestsComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() step: number;

  constructor(
    private profileService: ProfileService,
  ) {
    super();
  }

  ngOnInit() {
    this.currentProfile = this.authenticationService.getCurrentProfile();

    localStorage.setItem('currentProfile', JSON.stringify(this.currentProfile));
    if (this.currentProfile.emailConfirmed != true) this.nav("registration/3");
    if (this.currentProfile.status == 2) { this.nav("/"); }
    this.profileService.Get(this.currentProfile.id).subscribe(data => { //update from api
      this.currentProfile = data;
      localStorage.setItem('currentProfile', JSON.stringify(data));
      this.setLoading(false);
    });
  }

  onProfileUpdated($event) {

    localStorage.setItem('currentProfile', JSON.stringify($event));
    this.currentProfile = $event;
   
  }

  submit() {
    this.nav("/registration/7");
  }

  //onProfileUpdated($event) {
  //  localStorage.setItem('currentProfile', JSON.stringify($event));
  //  this.currentProfile = $event;
  //  console.log("Interests Updated")
  //}

}
