import { Component, OnInit, OnDestroy } from '@angular/core';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { GridSettings } from '../../../models/shared/grids/gridSettings';
import { LogService } from '../../../core/services/log.service';
import { Log } from '../../../models/Log';
import { BaseComponent } from '../../shared/base-component/base-component.component';
import { Router } from '@angular/router';
import { GlobalService } from '../../../core/services/global.service';
import { AuthenticationService } from '../../../core/services/authentication.service';

@Component({
  selector: 'admin-logs',
  styleUrls: ['./logs.component.css'],
  templateUrl: './logs.component.html',
})
export class AdminLogsComponent extends BaseComponent implements OnInit, OnDestroy {
  public items: Log[];
  //public profilesDisplay: Profile[];

  gridSettings: GridSettings;

  total = 1;

  constructor(
    router: Router,
    globalService: GlobalService,
    authenticationService: AuthenticationService,
    private logService: LogService
  ) {
    super();

    this.gridSettings = new GridSettings();
    this.gridSettings.pageSize = 50;
    this.gridSettings.pageIndex = 0;
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.logService.GetAll("", this.gridSettings.pageIndex, this.gridSettings.pageSize, this.gridSettings.sortField, this.gridSettings.sortAsc ? 1 : -1).subscribe(data => {
      this.items = data;
      this.isLoading = false;
    })
  }

  //reset(): void {
  //  this.search();
  //}

  //search(): void {
  //  this.visible = false;
  //  //this.listOfDisplayData = this.listOfData.filter((item: DataItem) => item.name.indexOf(this.searchValue) !== -1);
  //}

  onQueryParamsChange(params: NzTableQueryParams): void {
   
    const { pageSize, pageIndex, sort, filter } = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;
    this.gridSettings.pageIndex = pageIndex;
    this.gridSettings.pageSize = pageSize;
    this.gridSettings.sortField = sortField;
    this.gridSettings.sortAsc = sortOrder == "ascend";
    this.loadData();
  }
}

//interface DataItem {
//  name: string;
//  age: number;
//  address: string;
//}
