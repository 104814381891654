import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient } from "@angular/common/http";
import { AuthenticationService } from './authentication.service';
import { Configuration } from '../../app.constants';
import { Notification } from '../../models/notification';
import * as signalR from '@microsoft/signalr';          // import signalR
import { Profile } from '../../models/Profile';

@Injectable()
export class NotificationService {
  private isBrowser: boolean;
  private actionUrl: string;

  public currentProfile: Profile;

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    configuration: Configuration,
    private authenticationService: AuthenticationService
  ) {
    this.actionUrl = configuration.Server + 'v1/Notification/';
    //http.get<WeatherForecast[]>(baseUrl + 'weatherforecast').subscribe(result => {
    //   this.forecasts = result;
    //}, error => console.error(error));
  }

  public GetAll = (keyword: string, row: number, pageSize: number, sortField: string, sortOrder: number): Observable<Notification[]> => {
    let url = this.actionUrl + 'all?' + '&keyword=' + keyword + '&row=' + row + '&pageSize=' + pageSize + '&sortField=' + sortField + '&sortOrder=' + sortOrder;
    return this.http.post<Notification[]>(url, '', this.authenticationService.getHttpOptions());
  }
  public GetAllCount = (profileId: number, unread: boolean): Observable<number> => {
    return this.http.post<number>(this.actionUrl + 'allcount?profileId=' + profileId + '&unread=' + unread, '', this.authenticationService.getHttpOptions());
  }
  public GetForItem = (itemId: number, type: number, profileId: number): Observable<Notification[]> => {
    return this.http.post<Notification[]>(this.actionUrl + 'forItem?itemId=' + itemId + '&type=' + type + '&profileId=' + profileId, '', this.authenticationService.getHttpOptions());
  }
  public getImageForEvent = (itemId: number): Observable<string> => {
    return this.http.post<string>(this.actionUrl + 'getImageURL?itemId=' + itemId, '', this.authenticationService.getHttpOptions());
  }
  public Get = (id: number): Observable<Notification> => {
    return this.http.post<Notification>(this.actionUrl + id, "", this.authenticationService.getHttpOptions());
  }
  public Add = (item: Notification): Observable<Notification> => {
    return this.http.post<Notification>(this.actionUrl, JSON.stringify(item), this.authenticationService.getHttpOptions());
  }
  public Update = (item: Notification): Observable<Notification> => {
    return this.http.put<Notification>(this.actionUrl, JSON.stringify(item), this.authenticationService.getHttpOptions());
  }
  public UpdateGlobal = (item: Notification): Observable<Notification> => {
    return this.http.put<Notification>(this.actionUrl+"global", JSON.stringify(item), this.authenticationService.getHttpOptions());
  }
  public Delete = (id: number): Observable<any> => {
    return this.http.delete(this.actionUrl + id, this.authenticationService.getHttpOptions());
  }

  //public GetByDetails = (MessageType: number, GlobalObjectType: number, GlobalObjectId: number, ToProfileId: number, FromProfileId: number): Observable<Notification[]> => {
  //  let url = this.actionUrl + 'byDetails?' + 'MessageType=' + MessageType + '&GlobalObjectType=' + GlobalObjectType + '&GlobalObjectId=' + GlobalObjectId + '&ToProfileId=' + ToProfileId + '&FromProfileId=' + FromProfileId;
  //  return this.http.post<Notification[]>(url, '', this.authenticationService.getHttpOptions());
  //}

  public GetInbox = (ProfileId: number, count: number): Observable<Notification[]> => {
    let url = this.actionUrl + 'inbox?profileId=' + ProfileId + '&count=' + count;
    return this.http.post<Notification[]>(url, '', this.authenticationService.getHttpOptions());
  }

  


  

 

 
  

 
}
