import { Component, Input, OnChanges, OnInit, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { Interest } from '../../../models/Interest';
import { Router } from '@angular/router';
import { InterestService } from '../../../core/services/interest.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { BaseComponent } from '../base-component/base-component.component';
import { FilterSettings } from '../../../models/shared/grids/filterSettings';

@Component({
    selector: 'interests',
    templateUrl: './interests.component.html',
    styleUrls: ['./interests.component.css']
})
export class InterestsComponent extends BaseComponent implements OnInit, OnChanges {
    @Input() selected: string[];
    @Input() small: boolean = false;
    @Output() onUpdated: EventEmitter<any> = new EventEmitter<any>();

    public subscriptions: Subscription[] = [];
    public interests: Interest[];
  filterSettings: FilterSettings;
    constructor(
        router: Router,
        private interestService: InterestService,
     

    ) {
      super();
      this.filterSettings = new FilterSettings();
    }

    ngOnInit() {
        //this.contactService.GetAll("", 0, 10, "Created", 1).subscribe(data => {
        //  this.contacts = data;
      //})
      this.interestService.GetForFilterSettings(this.filterSettings, 0, 1000, "ViewOrder", 1).subscribe(data => {
            this.interests = data;
        });
    }

    ngOnChanges() {
        //if (this.filterSettings) {
        //  this.filterSettings.distance = this.filterSettings.distance.toString();
        //  this.filterSettings.gender = this.filterSettings.gender.toString();
        //}
    }

    hasInterest(interestTitle: string) {
        return this.selected.find(x => x == interestTitle) != null;
    }

    handleChange(checked: boolean, interest: any): void {
        if (checked) {
          this.selected.push(interest.title);
          //this.msgService.info('Interest "' + interest.title + '" added');
        } else {
          this.selected = this.selected.filter(x => x != interest.title);
          //this.msgService.info('Interest "' + interest.title + '" Removed');
        }
        //console.log(this.selected);
        this.onUpdated.next(this.selected);
    }
}
