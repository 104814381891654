import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from "@angular/router";
import { AuthenticationService } from '../../core/services/authentication.service';
import { Profile } from '../../models/Profile';
import { BaseComponent } from '../shared/base-component/base-component.component';
import { GlobalService } from '../../core/services/global.service';
import { LoginEventsEmitter } from '../../core/events/login.events';
//import { } from "googlemaps";
//declare var google: any;

@Component({
  selector: 'logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent extends BaseComponent implements OnInit, OnDestroy {
  //@ViewChild('gmap', null) gmapElement: any;

  public currentProfile: Profile;
  //public currentAccount: Account;

  constructor(
    authenticationService: AuthenticationService,
    router: Router,
    globalService: GlobalService,
    private loginEventsEmitter: LoginEventsEmitter
    //private titleEventsEmitter: TitleEventsEmitter
  ) {
    super();
    //this.currentProfile = this.authenticationService.getCurrentProfile();
    //this.currentAccount = this.authenticationService.getCurrentAccount();
  }

  ngOnInit() {
    //this.titleEventsEmitter.broadcastEventTitleChanged("Logout");
   
   

      localStorage.removeItem('currentProfile');
      this.currentProfile = null;
      this.authenticationService.logout();
      this.loginEventsEmitter.broadcastEventProfileLoginChanged(null);
      this.router.navigate(['/login']);
    
    

    //let location = new google.maps.LatLng(+this.currentAccount.latitude, +this.currentAccount.longitude);

    //var mapProp = {
    //    center: location,
    //    zoom: 15,
    //    mapTypeId: google.maps.MapTypeId.ROADMAP
    //};
    //this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);

    //this.marker = new google.maps.Marker({
    //    position: location,
    //    map: this.map,
    //    title: this.currentAccount.displayAddress
    //});
  }
}
