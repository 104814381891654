import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Profile } from '../../../models/Profile';
import { ProfileService } from '../../../core/services/profile.service';
import { Router } from '@angular/router';
import { UntypedFormGroup } from '@angular/forms';
import { Question } from '../../../models/Question';
import { QuestionService } from '../../../core/services/question.service';
import { BaseComponent } from '../../shared/base-component/base-component.component';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { GlobalService } from '../../../core/services/global.service';

@Component({
  selector: 'app-registration-reg-7-bitMore',
  styleUrls: ['./reg-7-bitMore.component.css'],
  templateUrl: './reg-7-bitMore.component.html',
})
export class Reg7BitMoreComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() form: UntypedFormGroup;
  @Input() step: number;
  

  public questions: Question[];
  myGroup: UntypedFormGroup;

  constructor(
    router: Router,
    globalService: GlobalService,
    authenticationService: AuthenticationService,
    private profileService: ProfileService,
    public questionService: QuestionService
  ) {
    super();
  }

  ngOnInit() {
    this.setLoading(false);
    this.currentProfile = this.authenticationService.getCurrentProfile();

    localStorage.setItem('currentProfile', JSON.stringify(this.currentProfile));
  }

  submit() {

    if (this.currentProfile.emailConfirmed != true) this.nav("registration/3");
    if (this.currentProfile.status == 2) { this.nav("/"); }
    this.profileService.Update(this.currentProfile).subscribe(data => {
      this.currentProfile = data;
      localStorage.setItem('currentProfile', JSON.stringify(data));
    
    });

    this.nav("/registration/8");
  }
}
