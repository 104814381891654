



import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Inject, Injectable, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { interval, Observable, Observer, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { EventService } from '../../../core/services/event.service';
import { MediaService } from '../../../core/services/media.service';
import { Event } from '../../../models/Event';
import { EventProfile } from '../../../models/EventProfile';
import { Media } from '../../../models/Media';
import { FilterSettings } from '../../../models/shared/grids/filterSettings';
import { Uploads } from '../../../models/shared/upload/Uploads';
import { BaseComponent } from '../../shared/base-component/base-component.component';
import { InviteListComponent } from '../../shared/event/invite-list/invite-list.component';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ProfileService } from '../../../core/services/profile.service';
import { Profile } from '../../../models/Profile';
import { Form, FormBuilder, FormGroup, NgForm, ValidationErrors } from '@angular/forms';
import { AfterViewInit } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';




@Injectable()
export class CustomDateParserFormatter {
  parse(value: string): NgbDateStruct {
    if (!value)
      return null
    let parts = value.split('/');
    return { year: +parts[0], month: +parts[1], day: +parts[2] } as NgbDateStruct
  }
  format(date: NgbDateStruct): string {

    return date ? ('0' + date.day).slice(-2) + "/" + ('0' + date.month).slice(-2) + "/" + date.year : null
  }
}



@Component({
  selector: 'event-edit',
  templateUrl: './event-edit.component.html',
  styleUrls: ['./event-edit.component.css'],
  //animations: [fadeInAnimation],
  //host: { '[@fadeInAnimation]': '' }
  //animations: [fadeInAnimation],
  //host: { '[@fadeInAnimation]': '' }
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ]
})

export class EventEditComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

  model: NgbDateStruct;
  @ViewChild('eventDate') eventDate: ElementRef;
  @ViewChild('addEventForm', { read: NgForm }) addEventForm: any;

  /* @ViewChild('locationSearch') searchElementRef: ElementRef;*/
  @ViewChild('inviteList') inviteList: InviteListComponent;

  @ViewChild('search') searchElementRef: ElementRef;

  public invitedCheck: boolean;
  public acceptedCheck: boolean;
  public declinedCheck: boolean;
  eventId = null;
  item: Event;
  public mediaItems: Media[];
  public invites: EventProfile[];
  public registeredInterest: EventProfile[];
  public invited: EventProfile[];
  public invite: EventProfile;
  public profileCheck: Profile;
  //model: NgbDateStruct;
  private geoCoder;
  model2: NgbDateStruct = { year: 2021, month: 1, day: 1 };
  model1: string;
  isModalInviteVisible = false;
  isModalInviteOkLoading = false;
  hasImage = false;

  date = null;
  ref: string;
  publicClass = 'not-active';
  privateClass = 'active';

  isPhysical: boolean = true;

  disabled = false;
  addressSearch: string;

  filterSettingsInvites: FilterSettings = new FilterSettings();

  mainImageList: NzUploadFile[] = [
    {
      uid: '-1',
      name: 'artandcraft.jpg',
      status: 'done',
      url: '/assets/img/events/artandcraft.jpg'
    },
    {
      uid: '-2',
      name: 'ballet.jpg',
      status: 'done',
      url: '/assets/img/events/ballet.jpg'
    },
    {
      uid: '-3',
      name: 'bikes.jpg',
      status: 'done',
      url: '/assets/img/events/bikes.jpg'
    },
    {
      uid: '-4',
      name: 'boardgames.jpg',
      status: 'done',
      url: '/assets/img/events/boardgames.jpg'
    },
    {
      uid: '-5',
      name: 'cooking.jpg',
      status: 'done',
      url: '/assets/img/events/cooking.jpg'
    },
    {
      uid: '-6',
      name: 'cards.jpg',
      status: 'done',
      url: '/assets/img/events/cards.jpg'
    },
    {
      uid: '-7',
      name: 'countryhousehotel.jpg',
      status: 'done',
      url: '/assets/img/events/countryhousehotel.jpg'
    },
    {
      uid: '-8',
      name: 'dancing.jpg',
      status: 'done',
      url: '/assets/img/events/dancing.jpg'
    },
    {
      uid: '-9',
      name: 'drinksparty.jpg',
      status: 'done',
      url: '/assets/img/events/drinksparty.jpg'
    },
    {
      uid: '-10',
      name: 'festival.jpg',
      status: 'done',
      url: '/assets/img/events/festival.jpg'
    },
    {
      uid: '-11',
      name: 'kitchensupper.jpg',
      status: 'done',
      url: '/assets/img/events/kitchensupper.jpg'
    },
    {
      uid: '-12',
      name: 'literaryfestival.jpg',
      status: 'done',
      url: '/assets/img/events/literaryfestival.jpg'
    },
    {
      uid: '-13',
      name: 'livemusic.jpg',
      status: 'done',
      url: '/assets/img/events/livemusic.jpg'
    },
    {
      uid: '-14',
      name: 'movies.jpg',
      status: 'done',
      url: '/assets/img/events/movies.jpg'
    },
    {
      uid: '-15',
      name: 'picnic.jpg',
      status: 'done',
      url: '/assets/img/events/picnic.jpg'
    },
    {
      uid: '-16',
      name: 'quiznight.jpg',
      status: 'done',
      url: '/assets/img/events/quiznight.jpg'
    },
    {
      uid: '-17',
      name: 'sportingevents.jpg',
      status: 'done',
      url: '/assets/img/events/sportingevents.jpg'
    },
    {
      uid: '-18',
      name: 'tennis.jpg',
      status: 'done',
      url: '/assets/img/events/tennis.jpg'
    },
    {
      uid: '-19',
      name: 'walks.jpg',
      status: 'done',
      url: '/assets/img/events/walks.jpg'
    },
    {
      uid: '-20',
      name: 'winetasting.jpg',
      status: 'done',
      url: '/assets/img/events/winetasting.jpg'
    },
    {
      uid: '-21',
      name: 'yoga.jpg',
      status: 'done',
      url: '/assets/img/events/yoga.jpg'
    }

  ];
  previewImage: string | undefined = '';
  previewVisible = false;

  newDate: string;
  autocomplete: any;
  validDate: boolean;
  formReady: boolean = false;
  apiLoaded: Observable<unknown>;


  constructor(
    private route: ActivatedRoute,
    private eventService: EventService,
    private profileService: ProfileService,
    private mediaService: MediaService,
    private http: HttpClient,
    private ngZone: NgZone,
    private ngbCalendar: NgbCalendar,


  ) {
    super();
    this.apiLoaded = http.jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyC1DqlD-BYXykoikrHP1tshzCz0idHjjmM&libraries=places', 'callback')
      .pipe(
        map(() => true),
        catchError(() => of(false)),
      );
    this.currentProfile = this.authenticationService.getCurrentProfile();
  }

  createForm() {


  }

  ngOnInit() {
    super.ngOnInit();



    console.log("ee", this.apiLoaded);
    this.route.params.subscribe(params => {
      this.eventId = +params['eId'];

      if (!isNaN(this.eventId) && this.eventId) {
        this.loadItem();
      } else {
        this.item = new Event();
        this.item.id = 0;
        this.item.sizeLimit = 0;
        this.mediaItems = [];
        this.ref = Math.random().toString().replace(".", "");
        this.item.tribeId = this.currentProfile.tribeId;
        this.item.eventDate = moment().add('d', 1).toDate();
        // this.onSliderUpdate([this.startDateMinutes, this.endDateMinutes]);
        this.item.startDate = moment().add('d', 1).toDate();
        this.item.endDate = moment().add('d', 1).toDate();
        this.item.startDate.setHours(9);
        this.item.endDate.setHours(23);
        this.item.startDate.setMinutes(0);
        this.item.endDate.setMinutes(0);
        this.item.createdByProfile = this.currentProfile;
        //this.item.eventDate = new Date("1900-01-01 18:00");
        //this.minutesValue1 = 540;
        //this.minutesValue2 = 1080;

        this.item.privacyStatus = 1;
        this.item.eventProfile = this.invites;
        this.setLoading(false);
        console.log("ddd", this.addEventForm);
        if (this.item) {
          this.bindLocationSearch();
          this.addEventForm.valueChanges.subscribe(selectedValue => {

            Object.keys(this.addEventForm.form.controls).forEach(control => {

              //console.log(control, " - ", this.addEventForm.form.get(control));
            });
            this.formReady = true;
            //console.log("a", )
            //  console.log("a", selectedValue.form.controls)

            // console.log("b", this.addEventForm)    //shows the old first name
            //setTimeout(() => {
            //  console.log("c", this.addEventForm)   //shows the latest first name
            //})

          })
        }
      }
    });

  }

  ngAfterViewInit() {



  }

  updateForm() {
    if (!this.isPhysical) {
      this.addEventForm.form.get('inputAddress1').status = 'VALID';
      this.addEventForm.form.get('inputPostcode').status = 'VALID';
      this.addEventForm.form.updateValueAndValidity();
    } else {
      this.addEventForm.form.get('inputAddress1').updateValueAndValidity();
      this.addEventForm.form.get('inputPostcode').updateValueAndValidity();
      //this.addEventForm.form.get('inputAddress1').status = 'INVALID';
      //this.addEventForm.form.get('inputPostcode').status = 'INVALID';

    }

  }

  loadItem() {
    this.eventService.Get(+this.eventId).subscribe(data => { //update from api
      this.item = data;
      if (this.item.eventProfile.filter(t => t.inviteStatus == 2).length > 0) { this.invitedCheck = true }
      if (this.item.eventProfile.filter(t => t.inviteStatus == 1).length > 0) { this.acceptedCheck = true }
      if (this.item.eventProfile.filter(t => t.inviteStatus == 3).length > 0) { this.declinedCheck = true }
      this.newDate = moment(this.item.eventDate).format('yyyy-MM-DD');

      this.registeredInterest = data.eventProfile.filter(t => t.inviteStatus == 4);
      this.invited = data.eventProfile.filter(t => t.inviteStatus != 4);
      this.item.eventProfile.forEach(f => {
        if (f.profileId != null) {
          this.profileService.Get(f.profileId).subscribe(result => {
            this.profileCheck = null;
            this.profileCheck = result;
          });
          if (this.profileCheck != null) { this.item.eventProfile = this.item.eventProfile.filter(g => g != f); }
        } else {
          this.item.eventProfile = this.item.eventProfile.filter(g => g != f);
        }
      });

      let adminList: number[] = [20, 627,];
      if (this.item.createdBy != this.currentProfile.id && this.currentProfile.userLevel != 3) {
        this.nav("/");
      }

      //Media
      this.mediaService.GetByMediaType(4, this.eventId).subscribe(mediaItems => { //update from api
        this.mediaItems = mediaItems;
      });

      //Main Image
      this.mainImageList.forEach(f => {
        f.selected = (this.item.imageUrl && this.item.imageUrl == f.url);
      });
      this.hasImage = true;
      if (this.item.imageUrl?.length > 0 && !this.mainImageList.find(x => x.selected)) this.mainImageList.push({
        uid: '-6',
        name: this.item.imageUrl,
        status: 'done',
        url: this.globalService.getEventImageUrl(this.item, 256),
        selected: true
      });

      //Details
      this.isPhysical = this.item.locationType == 2;



      this.item.endDate = moment(this.item.endDate).toDate();
      this.item.startDate = moment(this.item.startDate).toDate();

      this.model2.day = +moment(this.item.eventDate).format("DD");
      this.model2.month = +moment(this.item.eventDate).format("MM");
      this.model2.year = +moment(this.item.eventDate).format("YYYY");
      this.setLoading(false);
      if (this.addEventForm) {
        console.log(this.addEventForm);
        this.addEventForm.valueChanges.subscribe(selectedValue => {
          this.formReady = true;
        })
      }
      this.bindLocationSearch();
    });

    this.validDate = true;
  }

  checkTimes() {
    this.item.startDate.setMonth(1);
    this.item.startDate.setFullYear(2000)
    this.item.endDate.setMonth(1);
    this.item.endDate.setFullYear(2000)
    this.item.startDate.setDate(new Date().getDate());
    this.item.endDate.setDate(new Date().getDate());
    if (this.item.startDate >= this.item.endDate) {
      this.msgService.error('Start time must be earlier than end time');


      this.item.startDate.setHours(this.item.endDate.getHours() - 1);


    }
    console.log(this.item.startDate, " - ", this.item.endDate);
    this.item.endDate = moment(this.item.endDate).toDate();
    this.item.startDate = moment(this.item.startDate).toDate();
  }

  setLatAndLong(callback: any): void {
    this.submit();
    //const request = {
    //  query: this.addressSearch,
    //  fields: ["name", "geometry"],
    //};

    //const map = new google.maps.Map(document.createElement('div') as HTMLElement);
    //const service = new google.maps.places.PlacesService(map);
    //service.findPlaceFromQuery(
    //  request,
    //  (
    //    results: google.maps.places.PlaceResult[] | null,
    //    status: google.maps.places.PlacesServiceStatus
    //  ) => {
    //    if (status === google.maps.places.PlacesServiceStatus.OK && results && results.length > 0) {
    //      this.item.latitude = results[0].geometry.location.lat().toString();
    //      this.item.longitude = results[0].geometry.location.lng().toString();
    //      this.item.location = results[0].formatted_address ? results[0].formatted_address : this.addressSearch;
    //      this.submit();
    //    } else {
    //      this.addressSearch = undefined;

    //    }
    //  }
    //);
  }

  googleAddressSearch() {


  }

  bindLocationSearch() {

    this.autocomplete = new google.maps.places.SearchBox(
      this.searchElementRef.nativeElement
    );
    this.autocomplete.addListener('places_changed', () => {

      if (this.autocomplete.getPlaces()) {
        /*    this.ngZone.run(() => {*/
        const place: google.maps.places.PlaceResult = this.autocomplete.getPlaces()[0];
        console.log(this.autocomplete.getPlaces()[0]);
        this.item.addressFirstLine = "";
        if (place) {
          console.log(place);

          this.item.addressFirstLine = "";

          this.item.addressSecondLine = "";

          this.item.addressTown = "";

          this.item.addressCounty = "";

          this.item.postcode = "";

          this.item.location = "";




          if (place.types.find(y => y == 'establishment')) {
            this.item.addressFirstLine = place.name + ", ";
          }


          if (place.address_components.find(x => x.types.find(y => y == 'premise'))) {
            this.item.addressFirstLine += place.address_components.find(x => x.types.find(y => y == 'premise')).long_name + ", ";
          }
          if (place.address_components.find(x => x.types.find(y => y == 'street_number'))) {
            this.item.addressFirstLine += place.address_components.find(x => x.types.find(y => y == 'street_number')).long_name + " ";
          }
          if (place.address_components.find(x => x.types.find(y => y == 'route'))) {
            this.item.addressFirstLine += place.address_components.find(x => x.types.find(y => y == 'route')).long_name;
          }
          if (place.address_components.find(x => x.types.find(y => y == 'locality'))) {
            this.item.addressSecondLine = place.address_components.find(x => x.types.find(y => y == 'locality') != null)?.long_name;
          }
          if (place.address_components.find(x => x.types.find(y => y == 'postal_town'))) {
            this.item.addressTown = place.address_components.find(x => x.types.find(y => y == 'postal_town') != null)?.long_name;
          }
          if (place.address_components.find(x => x.types.find(y => y == 'administrative_area_level_2'))) {
            this.item.addressCounty = place.address_components.find(x => x.types.find(y => y == 'administrative_area_level_2') != null)?.long_name;
          }
          if (place.address_components.find(x => x.types.find(y => y == 'postal_code'))) {
            this.item.postcode = place.address_components.find(x => x.types.find(y => y == 'postal_code') != null)?.long_name;
          }
          if (place.formatted_address) {
            this.item.location = place.formatted_address;
          }
          this.item.latitude = place.geometry.location.lat().toString();
          this.item.longitude = place.geometry.location.lng().toString();

          this.searchElementRef.nativeElement.focus();
        }
      }
    });
    /* });*/

  }

  validateWeight(type) {


    if (type == "f") {
      if (this.item.sizeFemaleLimit > this.item.sizeLimit) {
        this.item.sizeFemaleLimit = this.item.sizeLimit;

      }
      this.item.sizeMaleLimit = this.item.sizeLimit - this.item.sizeFemaleLimit;
    }
    if (type == "m") {
      if (this.item.sizeMaleLimit > this.item.sizeLimit) {
        this.item.sizeMaleLimit = this.item.sizeLimit;
      }
      this.item.sizeFemaleLimit = this.item.sizeLimit - this.item.sizeMaleLimit;
    }
    if (type == "s") {
      //if (this.item.sizeFemaleLimit > this.item.sizeLimit) {
      //  this.item.sizeFemaleLimit = this.item.sizeLimit;
      //}
      //if (this.item.sizeMaleLimit > this.item.sizeLimit) {
      //  this.item.sizeFemaleLimit = this.item.sizeLimit;
      //}
      //this.item.sizeFemaleLimit = this.item.sizeLimit - this.item.sizeMaleLimit;
      //this.item.sizeMaleLimit = this.item.sizeLimit - this.item.sizeFemaleLimit;
      if (this.item.sizeLimit != 0) {
        if ((!!((this.item.sizeLimit / 2) % 1)) == true) {

          this.item.sizeFemaleLimit = Math.floor(this.item.sizeLimit / 2);
          this.item.sizeMaleLimit = this.item.sizeLimit - this.item.sizeFemaleLimit;
        } else {
          this.item.sizeMaleLimit = this.item.sizeLimit / 2;
          this.item.sizeFemaleLimit = this.item.sizeLimit / 2;
        }
      } else {
        this.item.genderWeight = false;
      }
    }

  }

  setPrivacyStatus(privacyStatus) {
    this.item.privacyStatus = privacyStatus;
  }

  onChange(): void {
    if (moment(this.newDate, 'YYYY-MM-DD', true).isValid()) {
      this.validDate = true;
      this.item.eventDate = moment(this.newDate, 'YYYY-MM-DD', true).utc().add('hour', 12).toDate();

    } else {
      this.validDate = false;
    }
  }

  submit() {
    this.setLoading(true);

    //set start/end times if null
    //if (this.item.startDate == null || this.item.endDate == null) {
    //this.on([this.startDateMinutes, this.endDateMinutes]);
    // }

    let selectedMainImage = this.mainImageList.find(x => x.selected);
    if (selectedMainImage && selectedMainImage.url.indexOf('data') < 0) this.item.imageUrl = selectedMainImage.url;

    this.item.locationType = this.isPhysical ? 2 : 1;
    let d = moment(this.item.eventDate).utc();
    d.set({
      hour: 16
    });
    this.item.eventDate = d.toDate();
    //this.onSliderUpdate([this.startDateMinutes, this.endDateMinutes]);

    this.item.startDate = moment(this.item.startDate).toDate();
    this.item.endDate = moment(this.item.endDate).toDate();

    if (this.item.id > 0) {
      this.eventService.Update(this.item).subscribe(data => {
        this.item = data;
        this.processImages();
      });
    } else {
      this.item.createdBy = this.currentProfile.id;
      this.item.status = 1;
      this.eventService.Add(this.item).subscribe(data => {
        this.item = data;
        this.processImages();
      });
    }
  }

  //onSliderUpdate(a: any[]) {
  //  //slider returns array of two ints (minutes 0-1425)
  //  this.startDateMinutes = a[0];
  //  this.endDateMinutes = a[1];

  //  let eventDate = moment(this.item.eventDate);

  //  let hour = Math.floor(this.startDateMinutes / 60);
  //  let min = this.startDateMinutes - hour * 60;
  //  let momentStartDate = moment([eventDate.year(), eventDate.month(), eventDate.date(), hour, min, 0, 0]).utc();
  //  this.item.startDate = momentStartDate.toDate();
  //  this.sliderStartDateText = momentStartDate.format('HH:mm');

  //  console.log("times", momentStartDate.toDate(), "-", momentStartDate.format('HH:mm'));

  //  hour = Math.floor(this.endDateMinutes / 60);
  //  min = this.endDateMinutes - hour * 60;
  //  let momentEndDate = moment([eventDate.year(), eventDate.month(), eventDate.date(), hour, min, 0, 0]).utc();
  //  this.item.endDate = momentEndDate.toDate();
  //  this.sliderEndDateText = momentEndDate.format('HH:mm');
  //}

  sliderFormatter(value) {
    var hour = Math.floor(value / 60);
    var min = value - hour * 60;
    return ("0" + hour).slice(-2) + ":" + ("0" + min).slice(-2);
  }


  checkAddress(): void {





    if ((this.item.sizeLimit > 0 && (!this.item.eventProfile || this.item.eventProfile == null)) || this.item.sizeLimit == 0 || (this.item.sizeLimit > 0 && this.item.eventProfile && this.item.eventProfile.filter(x => x.inviteStatus == 1 || x.inviteStatus == 2).length >= 0 && this.item.eventProfile.filter(x => x.inviteStatus == 1 || x.inviteStatus == 2).length <= this.item.sizeLimit)) {

      if (this.item.locationType == 2 && !this.addressSearch) {
        this.addressSearch = [this.item.addressFirstLine, this.item.addressSecondLine, this.item.addressTown, this.item.addressCounty, this.item.postcode]
          .filter(value => value)
          .join(', ');
        this.setLatAndLong(this.submit.bind(this));
      } else {
        this.submit();
      }
    } else {

      this.notify("error", "Event size limit is less than number of invites. Please increase event size limit or remove some invites.")
    }


  }

  delete() {
    this.setLoading(true);
    this.eventService.Delete(this.item.id).subscribe(data => {
      this.setLoading(false);
      this.nav('/events');

    });
  }

  processImages() {
    let selectedMainImage = this.mainImageList.find(x => x.selected);
    if (selectedMainImage && selectedMainImage.url?.indexOf("data") > -1) {
      Uploads.uploadBlob(this.http, selectedMainImage.url, '/v1/Event/uploadPhoto/' + this.item.id).subscribe(data => {
        this.item = (<any>data).body;
        this.updateItems(this.item.id);
      });
    } else {
      this.updateItems(this.item.id);
    }
  }

  updateItems(eventId: number) {
    if (this.mediaItems?.length > 0) {
      let count = this.mediaItems.length;
      this.mediaItems.forEach(x => {
        if ((<any>x).deleted && x.id > 0) {
          this.mediaService.Delete(x.id).subscribe(data3 => {
            count--;

          });
        } else if (!(<any>x).deleted && x.filename.indexOf("data:") > -1) {
          x.mediaTypeId = eventId;
          Uploads.uploadBlob(this.http, x.filename, '/v1/Media/uploadFile/' + 4 + '/' + eventId + '/' + this.currentProfile.id).subscribe(data => {
            x = (<any>data).body;
            count--;

          });
        } else {

        }
      });
    } else {

    }
    this.onFinishSaving();
  }

  onFinishSaving() {
    this.mediaItems = this.mediaItems.filter(x => !(<any>x).deleted);
    this.setLoading(false);
    this.msgService.info('Event Updated');
    this.nav("/events/" + this.item.id);
  }

  onProfileUpdated() {
  }

  showModalInvite(): void {
    this.isModalInviteVisible = true;
    this.inviteList.load();

  }
  onInvitesChanged($event) {
    this.item.eventProfile = $event;
    this.invited = this.item.eventProfile.filter(t => t.inviteStatus != 4);
    //this.item.eventProfile = $event;
  }
  onInviteRemoved($event) {
    this.item.eventProfile = this.item.eventProfile.filter(x => x.profileId != +$event);
    this.invited = this.item.eventProfile.filter(t => t.inviteStatus != 4);
  }
  onInviteAdded($event) {
    if (this.item.genderWeight == true) {
      if (this.item.eventProfile.find(t => t.profileId == +$event).profile.gender == 1 || this.item.eventProfile.find(t => t.profileId == +$event).profile.gender == 2) {
        if (this.item.eventProfile.find(t => t.profileId == +$event).profile.gender == 2) {
          if (this.item.eventProfile.filter(p => p.profile.gender == this.item.eventProfile.find(t => t.profileId == +$event).profile.gender && (p.inviteStatus != 4 && p.inviteStatus != 3)).length < this.item.sizeFemaleLimit) {
            this.item.eventProfile.find(t => t.profileId == +$event).inviteStatus = 2;
          } else {
            console.log(this.item.eventProfile.filter(p => p.profile.gender == this.item.eventProfile.find(t => t.profileId == +$event).profile.gender && (p.inviteStatus != 4 && p.inviteStatus != 3)).length);
            console.log(this.item.sizeFemaleLimit);
            this.msgService.info('No female spaces left in event');
          }
        }
        if (this.item.eventProfile.find(t => t.profileId == +$event).profile.gender == 1) {
          if (this.item.eventProfile.filter(p => p.profile.gender == this.item.eventProfile.find(t => t.profileId == +$event).profile.gender).length < this.item.sizeMaleLimit) {
            this.item.eventProfile.find(t => t.profileId == +$event).inviteStatus = 2;
          } else {
            this.msgService.info('No male spaces left in event');
          }
        }

      } else {
        this.item.eventProfile.find(t => t.profileId == +$event).inviteStatus = 2;

      }
    } else {
      if (this.invited.filter(t => t.inviteStatus != 3).length < this.item.sizeLimit || this.item.sizeLimit == 0) {

        this.item.eventProfile.find(t => t.profileId == +$event).inviteStatus = 2;

      } else {

        this.msgService.info('No spaces left in event');
      }
    }
    this.invited = this.item.eventProfile.filter(t => t.inviteStatus != 4);
    this.registeredInterest = this.item.eventProfile.filter(t => t.inviteStatus == 4);


  }

  handleModalInviteOk(): void {
    this.isModalInviteOkLoading = true;
    this.item.eventProfile = this.inviteList.eventProfiles.map(x => x);
    this.invited = this.item.eventProfile.filter(t => t.inviteStatus != 4);

    setTimeout(() => {
      this.isModalInviteVisible = false;
      this.isModalInviteOkLoading = false;
    }, 500);
  }
  handleModalInviteCancel(): void {
    this.isModalInviteVisible = false;
  }

  selectMainImage(file) {
    this.mainImageList.forEach(f => f.selected = false);
    file.selected = true;
    this.hasImage = true;
  }

  getImageUrl = (p: Media, size: number): string => {
    if (p?.filename?.indexOf("data:") > -1) return p.filename;
    return this.globalService.getMediaUrl(p, size);
  }

  transformFile = (file: NzUploadFile) => {

    return new Observable((observer: Observer<Blob>) => {
      Uploads.convertToBlob(file).then(blobSrc => {
        this.item.imageUrl = "";
        this.mainImageList.forEach(f => f.selected = false);
        this.mainImageList = this.mainImageList.splice(0, 21);
        this.mainImageList.push({
          uid: '-6',
          name: 'custom.jpg',
          status: 'uploading',
          url: blobSrc as any,
          selected: true
        });
      });
    });
  }

  beforeUpload = (file: NzUploadFile): boolean => {
    //var fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file.originFileObj! as Blob));
    return true;
  };

  handleChange(info: any): void {
    this.hasImage = true;
  }

  handlePreview = async (file: NzUploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await Uploads.getBase64Promise(file.originFileObj!);
    }
    this.previewImage = file.url || file.preview;
    this.previewVisible = true;
  };

}
