import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Event } from '../../../../models/Event';
import { BaseComponent } from '../../base-component/base-component.component';

@Component({
  selector: 'event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.css']
})
export class EventCardComponent extends BaseComponent implements OnInit {
  @Input() event: Event;
  @Input() subHeading: string;
  @Input() size: string = '';

  headingClass: string;
  mainMargin: string;

  constructor(
  ) {
    super();
  }

  ngOnInit() {
    this.headingClass = this.subHeading?.length > 0 ? 'no-margin' : '';
    this.mainMargin = this.subHeading?.length > 0 ? 'mb-2 mt-2' : '';
  }

  go(event) {
    this.nav('/events/' + event.id);
  }

  getImageUrl = (m: Event, size = 256): string => { return this.globalService.getEventImageUrl(m, size); }
  getEventDateSummary = (m: Event): string => { return Event.getEventDateSummary(m); }
  getEventTimeSummary = (m: Event): string => { return Event.getEventTimeSummary(m); }

}
