import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ProfileService } from '../../../core/services/profile.service';
import { Profile } from '../../../models/Profile';
import { BaseComponent } from '../../shared/base-component/base-component.component';

@Component({
  selector: 'app-registration-reg-3-verification',
  styleUrls: ['./reg-3-verification.component.css'],
  templateUrl: './reg-3-verification.component.html',
})
export class Reg3VerificationComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() step: number;
  

  public profiles: Profile[];

  constructor(
    private profileService: ProfileService
    //private contactService: ContactService
  ) {
    super();
  }

  ngOnInit() {
    //this.contactService.GetAll("", 0, 10, "Created", 1).subscribe(data => {
    //  this.contacts = data;
    //})
    this.currentProfile = this.authenticationService.getCurrentProfile();

    localStorage.setItem('currentProfile', JSON.stringify(this.currentProfile));
    if (this.currentProfile.status == 2) { this.nav("/"); }

   
    
      this.setLoading(false);
  }

  resend() {
    this.profileService.SendVerificationEmail(this.currentProfile).subscribe(data2 => {
      this.notify("success", "Email resent");
    });
  }

}
