import { Profile } from "./Profile";

export class EventProfile {
  id: number;
  eventId: number;
  profileId: number;
  inviteStatus: number;
  created: Date;

  profile: Profile;

  constructor() {
  }
  static create(profileId: number, eventId: number, inviteStatus: number, created: Date, profile: Profile) {
    let m = new EventProfile()
    m.eventId = eventId;
    m.profileId = profileId;
    m.inviteStatus = inviteStatus;
    m.created = created;
    m.profile = profile;
    return (m);
  }
}
