import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Profile } from '../../models/Profile';

@Injectable()
export class LoginEventsEmitter {
    private currentProfile = new Subject<Profile>();
    currentProfile$ = this.currentProfile.asObservable();

    broadcastEventProfileLoginChanged(user: any) {
        this.currentProfile.next(user);
    }
}
