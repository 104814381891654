import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared/base-component/base-component.component';
import { ActivatedRoute } from '@angular/router';
import { NewsItemService } from 'src/app/core/services/news-item.service';
import { FilterSettings } from 'src/app/models/shared/grids/filterSettings';
import { GridSettings } from 'src/app/models/shared/grids/gridSettings';
import { NewsItem } from 'src/app/models/NewsItem';

@Component({
  selector: 'comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent extends BaseComponent implements OnInit {
  gridSettings: GridSettings;
  filterSettings: FilterSettings;
  items: NewsItem[];
  newComment: string = '';
  hasMoreItems: boolean = true;
  isNavigating: boolean = false;
  slideDirection: 'left' | 'right' | null = null;
  remainingChars: number = 200;

  // ... other methods


  constructor(
    private newsItemService: NewsItemService,
    private route: ActivatedRoute,
  ) {
    super();

    this.gridSettings = new GridSettings();
    this.gridSettings.sortField = "Created";
    this.gridSettings.sortAsc = false;
    this.gridSettings.pageSize = 3;
    this.gridSettings.pageIndex = 0;
    this.filterSettings = new FilterSettings();
  }

  ngOnInit() {
    this.load();
  }

  onCommentInput() {
    this.remainingChars = 200 - this.newComment.length;
  }

  onItemDeleted(item: NewsItem) {
    this.load();
  }

  load() {
    this.setLoading(true);
    this.newsItemService.GetForFilterSettings(
      this.filterSettings,
      this.gridSettings.pageIndex,
      this.gridSettings.pageSize,
      this.gridSettings.sortField,
      this.gridSettings.sortAsc ? 1 : -1,
      2
    ).subscribe(data => {
      this.items = data.filter(x => x.status == 1);
      this.hasMoreItems = this.items.length === this.gridSettings.pageSize;
      this.setLoading(false);
      this.isNavigating = false;
      this.slideDirection = null;
    });
  }

  nextPage() {
    if (this.hasMoreItems && !this.isNavigating) {
      this.isNavigating = true;
      this.slideDirection = 'left';
      setTimeout(() => {
        this.gridSettings.pageIndex++;
        this.load();
      }, 300); // Adjust this timing to match your transition duration
    }
  }

  previousPage() {
    if (this.gridSettings.pageIndex > 0 && !this.isNavigating) {
      this.isNavigating = true;
      this.slideDirection = 'right';
      setTimeout(() => {
        this.gridSettings.pageIndex--;
        this.load();
      }, 300); // Adjust this timing to match your transition duration
    }
  }

  postComment() {
    this.setLoading(true)
    let item = new NewsItem()
    item.description = this.newComment;
    item.title = "[Comments]";
    item.profileId = this.currentProfile.id;
    item.tribeId = this.currentProfile.tribeId;
    item.created = new Date();
    item.status = 1
    item.itemType = 2;
    this.newsItemService.Add(item).subscribe(data => {
      this.newComment = '';
      this.setLoading(false);
      this.gridSettings.pageIndex = 0; // Reset to first page
      this.load();
    });
  }
}