import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient } from "@angular/common/http";
import { AuthenticationService } from './authentication.service';
import { Configuration } from '../../app.constants';
import { Media } from '../../models/Media';
import { GridSettings } from '../../models/shared/grids/gridSettings';
import { FilterSettings } from '../../models/shared/grids/filterSettings';

@Injectable()
export class MediaService {
  private isBrowser: boolean;
  private actionUrl: string;

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    configuration: Configuration,
    private authenticationService: AuthenticationService
  ) {
    this.actionUrl = configuration.Server + 'v1/Media/';
    //http.get<WeatherForecast[]>(baseUrl + 'weatherforecast').subscribe(result => {
    //   this.forecasts = result;
    //}, error => console.error(error));
  }

  public GetAll = (mediaType: number, mediaTypeId: number, keyword: string, row: number, pageSize: number, sortField: string, sortOrder: number, additionalMediaType: number, showPrivate: boolean): Observable<Media[]> => {


    let url = this.actionUrl + 'all?' + '&mediaType=' + mediaType + '&mediaTypeId=' + mediaTypeId + '&keyword=' + keyword + '&row=' + row + '&pageSize=' + pageSize + '&sortField=' + sortField + '&sortOrder=' + sortOrder + '&additionalMediaType=' + additionalMediaType + '&showPrivate=' + showPrivate;
    return this.http.post<Media[]>(url, '', this.authenticationService.getHttpOptions());
  }

  public GetForSettings = (filterSettings: FilterSettings, mediaType: number, mediaTypeId: number, keyword: string, gridSettings: GridSettings, additionalMediaType: number, showPrivate?: boolean): Observable<Media[]> => {
    //let url = this.actionUrl + 'all?' + '&mediaType=' + mediaType + '&mediaTypeId=' + mediaTypeId + '&keyword=' + keyword + '&row=' + gridSettings.pageIndex + '&pageSize=' + gridSettings.pageSize + '&sortField=' + gridSettings.sortField + '&sortOrder=' + (gridSettings.sortAsc ? 1 : -1);

    if (showPrivate) { return this.GetAll(mediaType, mediaTypeId, keyword, gridSettings.pageIndex, gridSettings.pageSize, gridSettings.sortField, (gridSettings.sortAsc ? 1 : -1), additionalMediaType, showPrivate); } else {
      return this.GetAll(mediaType, mediaTypeId, keyword, gridSettings.pageIndex, gridSettings.pageSize, gridSettings.sortField, (gridSettings.sortAsc ? 1 : -1), additionalMediaType, false);
    }
    //return this.http.post<Media[]>(url, '', this.authenticationService.getHttpOptions());
  }

  public GetForFilterSettings = (filterSettings: FilterSettings, row: number, pageSize: number, sortField: string, sortOrder: number, ): Observable<Media[]> => {
    let url = this.actionUrl + 'forFilterSettings?row=' + row + '&pageSize=' + pageSize + '&sortField=' + sortField + '&sortOrder=' + sortOrder;
    return this.http.post<Media[]>(url, JSON.stringify(filterSettings), this.authenticationService.getHttpOptions());
  }

  public GetByMediaType = (mediaType: number, mediaTypeId: number): Observable<Media[]> => {
    return this.GetAll(mediaType, mediaTypeId, "", 0, 1000, "Created", 1, 0, true);
    //let url = this.actionUrl + 'byMediaType?' + '&mediaType=' + mediaType + '&mediaTypeId=' + mediaTypeId;
    //return this.http.post<Media[]>(url, '', this.authenticationService.getHttpOptions());
  }

  public GetAllCount = (keyword: string): Observable<number> => {
    return this.http.post<number>(this.actionUrl + 'allcount?keyword=' + keyword, '', this.authenticationService.getHttpOptions());
  }
  public Get = (id: number): Observable<Media> => {
    return this.http.post<Media>(this.actionUrl + id, "", this.authenticationService.getHttpOptions());
  }
  public Add = (item: Media): Observable<Media> => {
    return this.http.post<Media>(this.actionUrl, JSON.stringify(item), this.authenticationService.getHttpOptions());
  }
  public Update = (item: Media): Observable<Media> => {
    return this.http.put<Media>(this.actionUrl, JSON.stringify(item), this.authenticationService.getHttpOptions());
  }
  public Delete = (id: number): Observable<any> => {
    return this.http.delete(this.actionUrl + id, this.authenticationService.getHttpOptions());
  }


}
