import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Profile } from '../../../../models/Profile';
import { BaseComponent } from '../../base-component/base-component.component';

@Component({
  selector: 'profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.css']
})
export class ProfileCardComponent extends BaseComponent implements OnInit {
  @Input() profile: Profile;
  @Input() subHeading: string;
  @Input() size: string = '';
  @Input() allowDelete: boolean = false;
  @Input() allowInvite: boolean = false;

  @Output() onDeleted = new EventEmitter<number>();
  @Output() onInvited = new EventEmitter<number>();

  headingClass: string;
  mainMargin: string;
  invitePadding: string;

  constructor(
  ) {
    super();
  }

  ngOnInit() {
    this.headingClass = this.subHeading?.length > 0 ? 'no-margin' : '';
    this.mainMargin = this.subHeading?.length > 0 ? 'mb-2 mt-2' : '';
    this.invitePadding = this.allowDelete ? 'fullWidth' : '';
  }

  getImageUrl = (p: Profile, size: number): string => { return this.globalService.getProfileImageUrl(p, size); }

  delete(id) {

    this.onDeleted.emit(id);
  }
  invite(id) {

    this.onInvited.emit(id);
  }

  go() {
    this.nav('/members/' + this.profile.id);
  }

}
