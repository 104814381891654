import { Component, ViewChild, ElementRef, Output, Input, EventEmitter, Renderer2, TemplateRef, ViewContainerRef, ContentChild, OnInit, HostListener } from '@angular/core';
import { Participant, RemoteTrack, RemoteAudioTrack, RemoteVideoTrack, RemoteParticipant, RemoteTrackPublication } from 'twilio-video';
import { BaseComponent } from '../../base-component/base-component.component';
import { ProfileService } from '../../../../core/services/profile.service';
import { Profile } from '../../../../models/Profile';
import { VideoCameraComponent } from '../camera/camera.component';
import { NgxMasonryComponent } from 'ngx-masonry';
import { interval } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-participants',
  styleUrls: ['./participants.component.css'],
  templateUrl: './participants.component.html',
})
export class VideoParticipantsComponent extends BaseComponent implements OnInit {

  @HostListener('window:unload', ['$event'])
  unloadHandler(event) {
    this.onLeaveRoom();
  }
  @HostListener('window:resize', ['$event'])
  resizeHandler(event) {
    this.resizeVideos();
  }

  @ViewChild('camera') camera: VideoCameraComponent;
  @ViewChild('tracks') tracksRef: ElementRef;

  @ViewChild('videoContainer', { read: ViewContainerRef }) videoContainer: ViewContainerRef;
  @ViewChild('participantTemplate', { read: TemplateRef }) participantTemplate: TemplateRef<any>;
  @ViewChild('participantsContainer', { read: ViewContainerRef }) participantsContainer: ViewContainerRef;

  @Output('participantsChanged') participantsChanged = new EventEmitter<boolean>();
  @Output('leaveRoom') leaveRoom = new EventEmitter<boolean>();
  @Input('activeRoomName') activeRoomName: string;
  @Input('showMe') boolean = true;
  @Input('profiles') profiles: Profile[];

  get participantCount() {
    return !!this.participants ? this.participants.size : 0;
  }

  get isAlone() {
    return this.participantCount === 0;
  }

  private participants: Map<Participant.SID, RemoteParticipant>;
  private dominantSpeaker: RemoteParticipant;

  constructor(
    private readonly renderer: Renderer2,
    private profileService: ProfileService
  ) {
    super();
    this.isLoading = true;
  }

  ngOnInit() {

  }

  clear() {
    if (this.participants) {
      this.participants.clear();
    }
  }

  resizeVideos(): void {
    const margin = 2;
    const container = this.videoContainer.element.nativeElement;
    const videos = container.querySelectorAll('.video');
    const videosCount = videos.length;
    if (videosCount < 1 || !container) {
      return;
    }

    const width = container.clientWidth - margin * 2;
    const height = container.clientHeight - margin * 2;
    const minSize = width > height * 1.25 ? height * 1.25 : width;

    let cols = 1;
    while (true) {
      const videoWidth = (minSize - margin * 2 * cols) * (100 / cols / 100);
      const videoHeight = 0.75 * videoWidth;
      const maxOnCol = Math.floor(width / videoWidth);
      const rowsNeeded = Math.ceil(videosCount / maxOnCol);
      if (rowsNeeded * videoHeight > height) {
        cols++;
      } else {
        break;
      }
    }

    const videoWidth = (minSize - margin * 2 * cols) * (100 / cols / 100);
    const videoHeight = 0.75 * videoWidth;

    videos.forEach(video => {
      video.style.width = `${videoWidth}px`;
      video.style.height = `${videoHeight}px`;
      video.style.margin = `${margin}px`;
    });
  }

  resizeVideosWithDelay(): void {
    setTimeout(() => (this.resizeVideos()), 250);
  }

  initialize(participants: Map<Participant.SID, RemoteParticipant>) {
    this.resizeVideosWithDelay();
    this.participants = participants;
    if (this.participants) {
      //console.log(this.participants);
      this.participants.forEach(participant => this.registerParticipantEvents(participant));
    }

    ////Test
    //this.profileService.GetAll("", 0, 12, "Created", -1).subscribe(data => {
    //  data.forEach(p => {
    //    const view = this.participantTemplate.createEmbeddedView({ $implicit: p });
    //    view.detectChanges();
    //    var img = document.createElement('img');
    //    img.src = '/assets/img/no-image.png';
    //    this.renderer.appendChild(view.rootNodes[0].querySelector('.p-element'), img);
    //    this.participantsContainer.insert(view);
    //  });
    //});

    this.isLoading = false;

    //interval(1000).pipe(take(1)).subscribe(x => {
    //  this.masonry.reloadItems();
    //  this.masonry.layout();
    //});
  }

  add(participant: RemoteParticipant) {
    this.resizeVideosWithDelay();
    if (this.participants && participant) {
      this.participants.set(participant.sid, participant);
      this.registerParticipantEvents(participant);
    }
  }

  removeVideo(profileId: string): void {
    const container = this.participantsContainer.element.nativeElement.parentElement;
    let profileTBR = container.querySelector(`[profileId="${profileId}"]`);
    this.resizeVideosWithDelay();
    if (profileTBR) {
      container.removeChild(profileTBR);
    }
  }

  remove(participant: RemoteParticipant) {
    this.removeVideo(participant.identity)
    if (this.participants && this.participants.has(participant.sid)) {
      this.participants.delete(participant.sid);
    }
  }

  loudest(participant: RemoteParticipant) {
    this.dominantSpeaker = participant;
  }

  onLeaveRoom() {
    this.leaveRoom.emit(true);
  }

  private registerParticipantEvents(participant: RemoteParticipant) {
    if (participant) {
      participant.tracks.forEach(publication => this.subscribe(publication));
      participant.on('trackPublished', publication => this.subscribe(publication));
      participant.on('trackUnpublished',
        publication => {
          if (publication && publication.track) {
            this.detachRemoteTrack(publication.track);
          }
        });
    }
  }

  private subscribe(publication: RemoteTrackPublication | any) {
    if (publication && publication.on) {
      publication.on('subscribed', track => this.attachRemoteTrack(track));
      publication.on('unsubscribed', track => this.detachRemoteTrack(track));
    }
  }

  private attachRemoteTrack(track: RemoteTrack) {
    if (this.isAttachable(track)) {
      const element = track.attach();
      this.renderer.data.id = track.sid;
      //this.renderer.setStyle(element, 'width', '95%');
      //this.renderer.setStyle(element, 'margin-left', '2.5%');
      //this.renderer.appendChild(this.listRef.nativeElement, element);
      if (track.kind == 'video') {
        // console.log('attaching video', track, this.participants);
        let participant = null;
        this.participants.forEach(p => {
          //console.log(p);
          if (p.videoTracks.has(track.sid)) participant = p;
        });
        //this.participants.values.apply(y => y.find(x => x.value.videoTracks.has(track.sid)));
        //console.log('p', participant);
        let profileId = participant.identity;
        this.removeVideo(profileId);
        const view = this.participantTemplate.createEmbeddedView({ $implicit: this.profiles.find(x => x.id == profileId) });
        view.detectChanges();
        this.renderer.appendChild(view.rootNodes[0].querySelector('.p-element'), element);
        //this.renderer.appendChild(view.rootNodes[0], element);
        this.participantsContainer.insert(view);
      } else {
        //console.log('attaching audio', track);
        this.renderer.appendChild(this.tracksRef.nativeElement, element);
      }

      this.participantsChanged.emit(true);
    }
  }

  private detachRemoteTrack(track: RemoteTrack) {
    if (this.isDetachable(track)) {
      track.detach().forEach(el => el.remove());
      this.participantsChanged.emit(true);
    }
  }

  private isAttachable(track: RemoteTrack): track is RemoteAudioTrack | RemoteVideoTrack {
    return !!track &&
      ((track as RemoteAudioTrack).attach !== undefined ||
        (track as RemoteVideoTrack).attach !== undefined);
  }

  private isDetachable(track: RemoteTrack): track is RemoteAudioTrack | RemoteVideoTrack {
    return !!track &&
      ((track as RemoteAudioTrack).detach !== undefined ||
        (track as RemoteVideoTrack).detach !== undefined);
  }

  onCameraInitialised($event) {
    //this.loadScene(this.selectedScene,$event);

  }

}
