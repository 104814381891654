import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient } from "@angular/common/http";
import { AuthenticationService } from './authentication.service';
import { Configuration } from '../../app.constants';
import { Question } from '../../models/Question';
import { FilterSettings } from '../../models/shared/grids/filterSettings';
import { GridSettings } from '../../models/shared/grids/gridSettings';

@Injectable()
export class QuestionService {
  private isBrowser: boolean;
  private actionUrl: string;

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    configuration: Configuration,
    private authenticationService: AuthenticationService
  ) {
    this.actionUrl = configuration.Server + 'v1/Question/';
    //http.get<WeatherForecast[]>(baseUrl + 'weatherforecast').subscribe(result => {
    //   this.forecasts = result;
    //}, error => console.error(error));
  }

  public GetAll = (keyword: string, row: number, pageSize: number, sortField: string, sortOrder: number): Observable<Question[]> => {
    let url = this.actionUrl + 'all?' + '&keyword=' + keyword + '&row=' + row + '&pageSize=' + pageSize + '&sortField=' + sortField + '&sortOrder=' + sortOrder;
    return this.http.post<Question[]>(url, '', this.authenticationService.getHttpOptions());
  }
  public GetForFilterSettings = (filterSettings: FilterSettings, row: number, pageSize: number, sortField: string, sortOrder: number): Observable<Question[]> => {
    let url = this.actionUrl + 'forFilterSettings?row=' + row + '&pageSize=' + pageSize + '&sortField=' + sortField + '&sortOrder=' + sortOrder;
    return this.http.post<Question[]>(url, JSON.stringify(filterSettings), this.authenticationService.getHttpOptions());
  }
  public GetForSettings = (filterSettings: FilterSettings, gridSettings: GridSettings): Observable<Question[]> => {
    return this.GetForFilterSettings(filterSettings, gridSettings.pageIndex, gridSettings.pageSize, gridSettings.sortField, (gridSettings.sortAsc ? 1 : -1));
  }
  public GetForAdmin = (): Observable<Question[]> => {
    return this.http.post<Question[]>(this.actionUrl + 'forAdmin', '', this.authenticationService.getHttpOptions());
  }
  public GetAllCount = (keyword: string): Observable<number> => {
    return this.http.post<number>(this.actionUrl + 'allcount?keyword=' + keyword, '', this.authenticationService.getHttpOptions());
  }
  public Get = (id: number): Observable<Question> => {
    return this.http.post<Question>(this.actionUrl + id, "", this.authenticationService.getHttpOptions());
  }
  public Add = (item: Question): Observable<Question> => {
    return this.http.post<Question>(this.actionUrl, JSON.stringify(item), this.authenticationService.getHttpOptions());
  }
  public Update = (item: Question): Observable<Question> => {
    return this.http.put<Question>(this.actionUrl, JSON.stringify(item), this.authenticationService.getHttpOptions());
  }
  public Delete = (id: number): Observable<any> => {
    return this.http.delete(this.actionUrl + id, this.authenticationService.getHttpOptions());
  }
}
