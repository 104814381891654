import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Profile } from '../../../models/Profile';
import { ProfileService } from '../../../core/services/profile.service';
import { Router } from '@angular/router';

import { Question } from '../../../models/Question';
import { UntypedFormGroup } from '@angular/forms';

import { QuestionService } from '../../../core/services/question.service';
import { BaseComponent } from '../../shared/base-component/base-component.component';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { GlobalService } from '../../../core/services/global.service';

@Component({
  selector: 'app-registration-reg-6-aboutYou',
  styleUrls: ['./reg-6-aboutYou.component.css'],
  templateUrl: './reg-6-aboutYou.component.html',
})
export class Reg6AboutYouComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() form: UntypedFormGroup;
  @Input() step: number;
  

  public questions: Question[];
  myGroup: UntypedFormGroup;

  constructor(
    router: Router,
    globalService: GlobalService,
    authenticationService: AuthenticationService,

    private profileService: ProfileService,
    public questionService: QuestionService
  ) {
    super();
    
  }

  ngOnInit() {

    this.currentProfile = this.authenticationService.getCurrentProfile();

    localStorage.setItem('currentProfile', JSON.stringify(this.currentProfile));
    if (this.currentProfile.emailConfirmed != true) this.nav("registration/3");
    if (this.currentProfile.status == 2) { this.nav("/"); }
    this.setLoading(false);
    let mProfile: Profile = this.authenticationService.getCurrentProfile();

    if (mProfile.status != null) {
      //if (!this.authService.allowAccess(2)) {
      // not logged in so redirect to login page with the return url
      this.profileService.Get(mProfile.id).subscribe(data => {
        this.currentProfile = data;
        localStorage.setItem('currentProfile', JSON.stringify(data));
        console.log(this.currentProfile);
      });
      
    }
  }

  handleChange(inputId: any) {
   
  }

  submit() {

    this.profileService.Update(this.currentProfile).subscribe(data => {
      this.currentProfile = data;
      localStorage.setItem('currentProfile', JSON.stringify(data));
      this.nav("/registration/6");
    });
   
  }
}
