import { Component, Input, Output, OnChanges, OnInit, EventEmitter } from '@angular/core';
import { Profile } from '../../../../models/Profile';
import { Event } from '../../../../models/Event';
import { FilterSettings } from '../../../../models/shared/grids/filterSettings';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

import { NzMessageService } from 'ng-zorro-antd/message';
import { GlobalService } from '../../../../core/services/global.service';
import { QuestionAnswer } from '../../../../models/QuestionAnswer';
import { EventService } from '../../../../core/services/event.service';
import { GridSettings } from '../../../../models/shared/grids/gridSettings';

import { animations } from "ack-angular-fx";
import * as moment from 'moment';
import { BaseComponent } from '../../base-component/base-component.component';
import { NguCarouselConfig } from '@ngu/carousel';

import { Scroll } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { filter } from 'rxjs/operators';
import { Event as RouterEvent } from '@angular/router';

@Component({
  selector: 'event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.css'],
  animations: animations
})
export class EventListComponent extends BaseComponent implements OnInit {
  @Output() onDataLoaded = new EventEmitter<number>();

  @Input() filterSettings: FilterSettings;
  @Input() max: number;
  @Input() pillIcons: boolean;
  @Input() size: string = "";
  @Input() includeAdd: boolean = false;
  @Input() forProfile: boolean;
  @Input() dontLoad: boolean = false;
  @Input() showLoadMore: boolean = false;
  @Input() skinny: boolean = false;

  @Input() createdBy: boolean;
  public originalLoadSetting: boolean;
  public events: Event[];
  gridSettings: GridSettings;
  resultLength = 0;
  scrollPositioin: any;

  //public carouselTile: NguCarouselConfig = {
  //  grid: { xs: 1, sm: 2, md: 3, lg: 3, all: 0 },
  //  slide: 1,
  //  speed: 500,
  //  point: {
  //    visible: true
  //  },
  //  interval: {
  //    timing: 3000,
  //    initialDelay: 1000
  //  },
  //  load: 2,
  //  loop: true,
  //  touch: true,
  //  easing: 'cubic-bezier(0, 0, 0.2, 1)'
  //};

  constructor(
    private eventService: EventService,
    public router: Router
  ) {
    super();
    this.isLoading = false;
    this.gridSettings = new GridSettings();
    this.gridSettings.pageSize = this.max ?? 18;
    this.gridSettings.pageIndex = 0;
    this.gridSettings.sortField = "EventDate";
    this.gridSettings.sortAsc = true;

  }

  ngOnInit() {
    //this.contactService.GetAll("", 0, 10, "Created", 1).subscribe(data => {
    //  this.contacts = data;
    //})
    if (this.filterSettings.startDate < moment().subtract('d', 1).toDate()) {
      this.gridSettings.sortAsc = false;
    }
    if (this.pillIcons != true) { this.pillIcons = false; }
    this.originalLoadSetting = this.dontLoad;
    console.log(this.max, 'max..max')
    this.load();
  }

  ngOnChanges() {
    // if (this.dontLoad != this.originalLoadSetting && this.dontLoad == false) {
    //   this.load()
    // }
  }

  load() {
    console.log('load...', this.skinny)
    if (!this.isLoading && this.gridSettings && this.dontLoad == false) {
      this.isLoading = true;
      this.gridSettings.pageSize = this.max ?? 18;

      if (this.skinny == true) {
        this.eventService.GetForFilterSettingsSkinny(this.filterSettings, this.gridSettings.pageIndex, this.gridSettings.pageSize, this.gridSettings.sortField, this.gridSettings.sortAsc ? 1 : -1)
          .subscribe(data => {
            this.resultLength = data.length;
            this.events = data;
            console.log(data);
            this.isLoading = false;
            this.onDataLoaded.emit(this.resultLength);
            const total = this.events[0].total;
            this.updateCanLoadMore(total);
          });
      } else {
        this.eventService.GetForFilterSettings(this.filterSettings, this.gridSettings.pageIndex, this.gridSettings.pageSize, this.gridSettings.sortField, this.gridSettings.sortAsc ? 1 : -1)
          .subscribe(data => {
            console.log(data, 'loaded data')
            this.resultLength = data.model.length;
            this.events = data.model;
            this.isLoading = false;
            this.onDataLoaded.emit(this.resultLength);
            const total = data.total;
            this.updateCanLoadMore(total);
          });
      }
    }
  }

  loadMore() {
    if (!this.gridSettings.pageIndex)
      this.gridSettings.pageIndex = 1;
    this.gridSettings.pageIndex += 1;
    if (this.skinny == true) {
      this.eventService.GetForFilterSettingsSkinny(this.filterSettings, this.gridSettings.pageIndex, this.gridSettings.pageSize, this.gridSettings.sortField, this.gridSettings.sortAsc ? 1 : -1)
        .subscribe(data => {
          this.resultLength = data.length;
          this.events = this.events.concat(data);
          this.onDataLoaded.emit(this.events.length);
          const total = this.events[0].total;
          this.updateCanLoadMore(total);

        });
    } else {
      this.eventService.GetForFilterSettings(this.filterSettings, this.gridSettings.pageIndex, this.gridSettings.pageSize, this.gridSettings.sortField, this.gridSettings.sortAsc ? 1 : -1)
        .subscribe(data => {
          this.resultLength = data.model.length;
          this.events = this.events.concat(data.model);
          this.onDataLoaded.emit(this.events.length);
          const total = this.events[0].total;
          this.updateCanLoadMore(total);

        });
    }
  }

  updateCanLoadMore(totalRecord: number) {
    console.log(this.events, 'canload..')
    if (this.events.length < totalRecord) {
      this.showLoadMore = true
    } else this.showLoadMore = false;
  }

  getImageUrl = (m: Event): string => { return this.globalService.getEventImageUrl(m, 800); }
  getEventDateSummary = (m: Event): string => { return Event.getEventDateSummary(m); }
  getEventTimeSummary = (m: Event): string => { return Event.getEventTimeSummary(m); }

  //public getImageUrl(p: Profile, size: number) {
  //  if (p.imageUrl) {
  //    let path = p.imageUrl;
  //    switch (size) {
  //      case 1200: path = "1200/1200x900.jpg"; break;
  //      case 800: path = "800/800x600.jpg"; break;
  //      case 256: path = "256/256x192.jpg"; break;
  //    }

  //    return this.configuration.CDNUrl + '/profile/' + p.id + '/' + path;// + '?random=' + Math.random();// + '?t=' + parseInt((Math.random() * 1000).toString()
  //  } else {
  //    return '';
  //  }
  //}

  getTimeAgo = (d: Date): string => {
    return moment(d).fromNow();
  }

}
