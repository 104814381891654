import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProfileService } from '../../../core/services/profile.service';
import { MediaService } from '../../../core/services/media.service';
import { BaseComponent } from '../../shared/base-component/base-component.component';
import { QuestionService } from '../../../core/services/question.service';
import { TitleEventsEmitter } from '../../../core/events/title.events';
import { MessageService } from '../../../core/services/message.service';
import { HttpClient } from '@angular/common/http';
import { NewsItemService } from '../../../core/services/news-item.service';
import { NewsItem } from '../../../models/NewsItem';



@Component({
  selector: 'event',
  templateUrl: './news-item.component.html',
  styleUrls: ['./news-item.component.css'],
  //animations: [fadeInAnimation],
  //host: { '[@fadeInAnimation]': '' }
})

export class NewsItemComponent extends BaseComponent implements OnInit, OnDestroy {

  isExpanded = false;
  isModalInviteVisible = false;
  isModalInviteOkLoading = false;
  chatMode: number = 0;
  item: NewsItem;

  newsId = null;



  constructor(
    private route: ActivatedRoute,
    private profileService: ProfileService,
    private newsService: NewsItemService,

    private mediaService: MediaService,
    private questionService: QuestionService,
    private messageService: MessageService,
    private titleEventsEmitter: TitleEventsEmitter,
    private http: HttpClient,
  ) {
    super();



  }

  ngOnInit() {
    super.ngOnInit();


    this.route.params.subscribe(params => {
      this.newsId = +params['id'];

      if (this.newsId > 0) {
        this.load();
      }
    });

  }

  load() {

    this.newsService.Get(+this.newsId).subscribe(data => { //update from api
      this.item = data;
      this.setLoading(false);
    });
  }

  getFullUrl(link: string): string {
    if (!link) return '';
    if (link.startsWith('http://') || link.startsWith('https://')) {
      return link;
    }
    return `https://${link}`;
  }



  getImageUrl = (m: NewsItem): string => { return this.globalService.getNewsItemUrl(m, 800); }


}
