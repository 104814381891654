import { Component, OnInit, OnDestroy } from '@angular/core';
import { Event } from '../../../models/Event';
import { EventService } from '../../../core/services/event.service';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { GridSettings } from '../../../models/shared/grids/gridSettings';
import { Router } from '@angular/router';
import { GlobalService } from '../../../core/services/global.service';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { BaseComponent } from '../../shared/base-component/base-component.component';
import * as moment from 'moment';
import { FilterSettings } from '../../../models/shared/grids/filterSettings';

@Component({
  selector: 'admin-events',
  styleUrls: ['./events.component.css'],
  templateUrl: './events.component.html',
})
export class AdminEventsComponent extends BaseComponent implements OnInit, OnDestroy {

  public events: Event[];
  public eventsDisplay: Event[];

  gridSettings: GridSettings;
  public filterSettings: FilterSettings = new FilterSettings();
  total = 1;
  public filterSettingsUpcoming: FilterSettings;
  constructor(
    private eventService: EventService
  ) {
    super();
    this.filterSettings = new FilterSettings();
    this.gridSettings = new GridSettings();
    this.gridSettings.pageSize = 20;
    this.gridSettings.pageIndex = 0;
    this.gridSettings.sortField = "Created";
    this.gridSettings.sortAsc = false;

    this.filterSettingsUpcoming = new FilterSettings();
    this.filterSettingsUpcoming.forPublic = true;
    this.filterSettingsUpcoming.gender = 0;
    this.filterSettingsUpcoming.distance = 0;
    this.filterSettingsUpcoming.profileId = this.currentProfile.id;
    this.filterSettingsUpcoming.updateDates(moment(new Date()).set('hour', 0).toDate(), moment().add('d', 10000).set('hour', 23).set('minute', 59).toDate());
  }

  ngOnInit() {
    this.loadData();
  }

  onFilterUpcomingUpdated($event) {
    if ($event != null) {
      this.filterSettings = $event;
      this.loadData();
    }
  }

  loadData() {
    const pageSize = this.gridSettings.pageSize;
    if (this.currentProfile.userLevel == 2) {
      this.eventService.GetForFilterSettings(this.filterSettings, this.gridSettings.pageIndex, pageSize, this.gridSettings.sortField, this.gridSettings.sortAsc ? 1 : -1).subscribe(data => {

        this.events = data.model;
        this.eventsDisplay = data.model;
        this.total = data.total;
        this.setLoading(false);
      })
    }
    if (this.currentProfile.userLevel == 3) {
      this.eventService.GetForAdmin(this.filterSettings.keyword, this.gridSettings.pageIndex, pageSize, this.gridSettings.sortField, this.gridSettings.sortAsc ? 1 : -1).subscribe(data => {
        console.log(data, 'new data....')
        this.events = data.model;
        this.eventsDisplay = data.model;
        this.total = data.total;

        this.setLoading(false);
      })
    }
  }

  //reset(): void {
  //  this.search();
  //}

  //search(): void {
  //  this.visible = false;
  //  //this.listOfDisplayData = this.listOfData.filter((item: DataItem) => item.name.indexOf(this.searchValue) !== -1);
  //}

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort, filter } = params;
    console.log(pageSize, pageIndex, sort, filter, 'Hello world!')

    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) ? currentSort.key : "created";
    const sortOrder = (currentSort && currentSort.value) ? currentSort.value : 'descend';
    this.gridSettings.pageIndex = pageIndex;
    this.gridSettings.pageSize = pageSize;
    this.gridSettings.sortField = this.globalService.capitalize(sortField);
    this.gridSettings.sortAsc = sortOrder == "ascend";
    this.loadData();
  }

  setStatus(p, status) {
    this.setLoading(true);
    p.status = status;
    this.eventService.Update(p).subscribe(data => {
      p = data;
      this.setLoading(false);
    });
  }

  delete(p) {
    this.setLoading(true);
    this.eventService.Delete(p.id).subscribe(data => {
      this.loadData();
    });
  }

  cancelDelete(): void {
    //this.nzMessageService.info('Cancelled delete.');
  }

  getTimeAgo = (d: Date): string => { return moment(d).fromNow(); }

}

