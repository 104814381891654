import { Component, OnInit } from '@angular/core';
import { LogService } from '../../core/services/log.service';
import { LogTypes } from '../../models/enums/LogTypes';
import { BaseComponent } from '../shared/base-component/base-component.component';
import { Router } from '@angular/router';
import { GlobalService } from '../../core/services/global.service';
import { AuthenticationService } from '../../core/services/authentication.service';

@Component({
    selector: 'admin',
    styleUrls: ['./admin.component.css'],
    templateUrl: './admin.component.html',
})
export class AdminComponent extends BaseComponent implements OnInit {
    constructor(
        router: Router,
        globalService: GlobalService,
        authenticationService: AuthenticationService,
        private logService: LogService
    ) {
        super();
    }

    ngOnInit() {
        this.logService.Log("Viewed home page", +(LogTypes.View_Admin)).subscribe(data => { });
    }
}
