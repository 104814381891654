import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Configuration } from '../../../../app.constants';
import { TitleEventsEmitter } from '../../../../core/events/title.events';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { MediaService } from '../../../../core/services/media.service';
import { ProfileService } from '../../../../core/services/profile.service';
import { QuestionService } from '../../../../core/services/question.service';
import { BaseComponent } from '../../../shared/base-component/base-component.component';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

//declare var Stripe: any;

@Component({
  selector: 'cancel-subscription-component',
  templateUrl: 'cancel-subscription.component.html',
  styleUrls: ['cancel-subscription.component.css']
})
export class CancelSubscriptionComponent extends BaseComponent implements OnInit {

  public subscriptions: any[] = [];
  moment: any = moment;

  constructor(
    private profileService: ProfileService,
    private configuration: Configuration,
    //private alertService: AlertService,
    public questionService: QuestionService,
    private mediaService: MediaService,
    //private loaderService: LoaderService,
    //private ngProgress: NgProgress,
    //private titleEventsEmitter: TitleEventsEmitter,
    private titleEventsEmitter: TitleEventsEmitter,
    authenticationService: AuthenticationService,
    private msg: NzMessageService,
    private http: HttpClient,
    private route: ActivatedRoute
  ) {
    super();
    //this.currentProfile = new Profile();
    //this.form = fb.group({
    //    newPassword: ['', Validators.required],
    //    confirmPassword: ['', Validators.required]
    //}, {
    //        validator: PasswordValidation.MatchPassword // your validation method
    //    })
  }

  ngOnInit() {
    super.ngOnInit();
   
    this.profileService
      .Get(this.currentProfile.id)
      .subscribe(
        data => {
          this.currentProfile = data;
          localStorage.setItem('currentProfile', JSON.stringify(data));
          if (this.currentProfile.status == 1) {
            this.router.navigate(['/']);
          }
        },
        error => console.log(error),
        () => { }
    );
    //if (this.currentProfile.subscriptionExpiry <= new Date() || this.currentProfile.subscriptionStatus!=2) {
    //  this.router.navigate(['/account/subscription']);
    //}
    this.titleEventsEmitter.broadcastEventTitleChanged("Manage Subscriptions");
    this.initStripe("");

  }

  ngAfterViewInit() {

    this.setLoading(false); 

  }



  doTask(id: string) {

    this.http.get<any>("/v1/payments/get-subscriptions?customerId=" + this.currentProfile.stripeCustomerID).subscribe(data => {
      this.subscriptions = data;

      this.subscriptions = this.subscriptions.filter(r=>r.status='active');
      this.setLoading(false);
    })

    //this.http.get<any>("/v1/payments/transfer").subscribe(data => {
    //  this.subscriptions = data;
    //  console.log(this.subscriptions);
    //  this.setLoading(false);
    //})

    //this.http.get<any>("/v1/payments/get-subs-list").subscribe(data => {
    //  this.subscriptions = data;
    //  console.log(this.subscriptions);
    //  this.setLoading(false);
    //})



  }


  initStripe(id: string) {
   
    this.http.get<any>("/v1/payments/get-subscriptions?customerId=" + this.currentProfile.stripeCustomerID).subscribe(data => {
      this.subscriptions = data;

     // this.subscriptions = this.subscriptions.filter(r=>r.status='active');
      this.setLoading(false);
    })
  }
  cancel(id: string) {
    this.http.get<any>("/v1/payments/cancel-subscription?subscriptionId=" + id + "&profileId=" + this.currentProfile.id).subscribe(data => {
      
      this.notify("success", "Your subscription will expire on " + moment(data.currentPeriodEnd).format('Do MMMM YYYY'), "Subscription Cancelled");
  
      
      this.setLoading(false);
    }, (error => {
      this.notify("error", "Subscription not cancelled. Please contact support.");
    }))
  }

  

}
