import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from "rxjs";
import { AuthenticationService } from '../../../core/services/authentication.service';

import { GlobalService } from '../../../core/services/global.service';
import { Profile } from '../../../models/Profile';

@Component({
  templateUrl: 'auto-login.component.html',
  //styleUrls: ['resetPassword.component.css']
})

export class ActionAutoLoginComponent implements OnInit, OnDestroy {

  public SchoolId: string = "";
  public message: string = "";
  public returnUrl: string = "";
  public profile: Profile = new Profile();
  public subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private globalService: GlobalService
    //private alertService: AlertService,
    //private ngProgress: NgProgress,
    //private loginEventsEmitter: LoginEventsEmitter
  ) {
    //console.log(route.params);
  }

  ngOnInit() {
    //this.authenticationService.logout();
    this.route.params.subscribe(params => {

      this.globalService.startProgress();

      let token = this.route.snapshot.queryParamMap.get('t');
      //console.log(token);

      this.authenticationService.authenticateProfileNew(token)
        .subscribe(
          data => {
            let m: Profile = JSON.parse(<string>localStorage.getItem('currentProfile'));

            //let mSchool: School = this.authenticationService.getCurrentSchool();
            //console.log('log c ' + JSON.stringify(mSchool));
            if (m && m.id > 0) {
              //console.log('login School', m);
              this.globalService.authCommand('Login');
              this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';
              //this.loginEventsEmitter.broadcastEventSchoolLoginChanged(mSchool);
              this.router.navigate([this.returnUrl]);
            }
          },
          error => {
            if (error.status == 404) {
              this.message = "Email or password not found"
            } else {
              this.message = "Error logging in"
            }
            //this.alertService.error(this.message);
          });

      this.globalService.completeProgress(null);
      //this.SchoolId = params['id'];
      //console.log(params['id']);
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => { subscription.unsubscribe(); });
    this.subscriptions = [];
  }

  public getCurrentProfile(): any {
    if (localStorage.getItem('currentProfile')) {
      let c: Profile = new Profile();
      c = JSON.parse(<string>localStorage.getItem('currentProfile'));
      return c;
    }
    return null;
    //return JSON.parse(JSON.stringify(""));
  }

}
