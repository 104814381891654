import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared/base-component/base-component.component';
import { ProfileService } from '../../../core/services/profile.service';


//declare var Stripe: any;

@Component({
  selector: 'refresh-profile-component',
  templateUrl: 'refresh-profile.component.html',
  styleUrls: ['refresh-profile.component.css']
})
export class RefreshProfileComponent extends BaseComponent implements OnInit {



  constructor(
    private profileService: ProfileService,



  ) {
    super();
    //this.currentProfile = new Profile();
    //this.form = fb.group({
    //    newPassword: ['', Validators.required],
    //    confirmPassword: ['', Validators.required]
    //}, {
    //        validator: PasswordValidation.MatchPassword // your validation method
    //    })
  }

  ngOnInit() {
    if (this.currentProfile) {
      this.profileService
        .Get(this.currentProfile.id)
        .subscribe(
          data => {
            localStorage.setItem('currentProfile', JSON.stringify(data));

            this.router.navigate(['/']);



          },
          error => console.log(error),
          () => { }
        );

    } else {
      this.router.navigate(['/login']);
    }
     


  }



}
