import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient } from "@angular/common/http";
import { AuthenticationService } from './authentication.service';
import { Configuration } from '../../app.constants';
import { Tribe } from '../../models/Tribe';

@Injectable()
export class TribeService {
  private isBrowser: boolean;
  private actionUrl: string;

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    configuration: Configuration,
    private authenticationService: AuthenticationService
  ) {
    this.actionUrl = configuration.Server + 'v1/Tribe/';
    //http.get<WeatherForecast[]>(baseUrl + 'weatherforecast').subscribe(result => {
    //   this.forecasts = result;
    //}, error => console.error(error));
  }

  public GetAll = (keyword: string, row: number, pageSize: number, sortField: string, sortOrder: number): Observable<Tribe[]> => {
    let url = this.actionUrl + 'all?' + '&keyword=' + keyword + '&row=' + row + '&pageSize=' + pageSize + '&sortField=' + sortField + '&sortOrder=' + sortOrder;
    return this.http.post<Tribe[]>(url, '', this.authenticationService.getHttpOptions());
  }
  public GetAllCount = (keyword: string): Observable<number> => {
    return this.http.post<number>(this.actionUrl + 'allcount?keyword=' + keyword, '', this.authenticationService.getHttpOptions());
  }
  public Get = (id: number): Observable<Tribe> => {
    return this.http.post<Tribe>(this.actionUrl + id, "", this.authenticationService.getHttpOptions());
  }
  public HasMembers = (id: number): Observable<Tribe> => {
    return this.http.post<Tribe>(this.actionUrl + 'hasMembers?tribeId=' + id, "", this.authenticationService.getHttpOptions());
  }
  public Add = (item: Tribe): Observable<Tribe> => {
    return this.http.post<Tribe>(this.actionUrl, JSON.stringify(item), this.authenticationService.getHttpOptions());
  }
  public Update = (item: Tribe): Observable<Tribe> => {
    return this.http.put<Tribe>(this.actionUrl, JSON.stringify(item), this.authenticationService.getHttpOptions());
  }
  public Delete = (id: number): Observable<any> => {
    return this.http.delete(this.actionUrl + id, this.authenticationService.getHttpOptions());
  }
}
