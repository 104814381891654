import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, of, throwError } from "rxjs";
import { tap, catchError, map } from "rxjs/operators";
import { Profile } from "../../models/Profile";

export interface AuthResponse {
    token: string;
    profile: Profile;
}


@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private currentProfileSubject: BehaviorSubject<Profile | null>;
    public currentProfile$: Observable<Profile | null>;
    private readonly TOKEN_KEY = 'auth_token';
    private readonly PROFILE_KEY = 'currentProfile';

    constructor(
        private http: HttpClient,
        private router: Router
    ) {
        this.currentProfileSubject = new BehaviorSubject<Profile | null>(this.loadStoredProfile());
        this.currentProfile$ = this.currentProfileSubject.asObservable();
    }

    private loadStoredProfile(): Profile | null {
        const storedProfile = localStorage.getItem(this.PROFILE_KEY);
        return storedProfile ? JSON.parse(storedProfile) : null;
    }

    public get currentProfileValue(): Profile | null {
        return this.currentProfileSubject.value;
    }

    public get isAuthenticated(): boolean {
        return !!this.getToken();
    }

    login(email: string, password: string): Observable<Profile> {
        return this.http.post<Profile>('/v1/Auth', { emailAddress: email, password })
            .pipe(
                tap(response => {
                    this.setToken(response.token);
                    this.setProfile(response);
                    this.currentProfileSubject.next(response);
                }),
                catchError(error => {
                    let errorMessage = 'An error occurred during login';
                    if (error.status === 404) {
                        errorMessage = 'Email or password not found';
                    } else if (error.status === 401) {
                        errorMessage = 'Invalid credentials';
                    }
                    return throwError(() => new Error(errorMessage));
                })
            );
    }

    autoAuth(id: number): Observable<any> {
        localStorage.removeItem(this.TOKEN_KEY);
        localStorage.removeItem(this.PROFILE_KEY);
        return this.http.post<Profile>('/v1/Auth/autoAuth/' + id, "", this.getHttpOptions()).pipe(map(response => {
            this.setToken(response.token);
            this.setProfile(response);
            this.currentProfileSubject.next(response);
            return of(response);
        }));
    }

    logout(): void {
        localStorage.removeItem(this.TOKEN_KEY);
        localStorage.removeItem(this.PROFILE_KEY);
        this.currentProfileSubject.next(null);
        this.router.navigate(['/login']);
    }

    getToken(): string | null {
        return localStorage.getItem(this.TOKEN_KEY);
    }

    private setToken(token: string): void {
        localStorage.setItem(this.TOKEN_KEY, token);
    }

    private setProfile(profile: Profile): void {
        localStorage.setItem(this.PROFILE_KEY, JSON.stringify(profile));
    }

    public getHttpOptions(tokenParamsTimeEncrypted: string = "") {
        const headerItems = new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'TokenParamsTimeEncrypted': tokenParamsTimeEncrypted
        });

        return { headers: headerItems };
    }

}