import { Component, OnInit, ViewChild, } from '@angular/core';
import { Profile } from '../../models/Profile';
import { FilterSettings } from '../../models/shared/grids/filterSettings';
import { GridSettings } from '../../models/shared/grids/gridSettings';
import { ProfileService } from '../../core/services/profile.service';
import { QuestionService } from '../../core/services/question.service';
import { Question } from '../../models/Question';
import { TitleEventsEmitter } from '../../core/events/title.events';
import { animations } from "ack-angular-fx";
import { BaseComponent } from '../shared/base-component/base-component.component';
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import * as moment from 'moment';
import { Observable } from 'rxjs/internal/Observable';
import { Subscription } from 'rxjs';
import { interval } from 'rxjs';
import { CommentModalComponent } from '../news/comment/comment-modal.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NewsItemsComponent } from '../shared/news-items/news-items.component';


//export const homeTransition = trigger('homeTransition', [
//  transition(':enter', [
//    query('.anim-block', style({ opacity: 0 })),
//    query('.anim-block', stagger(300, [
//      style({ transform: 'translateY(100px)' }),
//      animate('1s cubic-bezier(.75,-0.48,.26,1.52)', style({ transform: 'translateY(0px)', opacity: 1 })),
//    ])),
//  ]),
//  transition(':leave', [
//    query('.anim-block', stagger(300, [
//      style({ transform: 'translateY(0px)', opacity: 1 }),
//      animate('1s cubic-bezier(.75,-0.48,.26,1.52)', style({ transform: 'translateY(100px)', opacity: 0 })),
//    ])),
//  ])
//]);

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [
    animations,
    //homeTransition
    //  //fadeInOnEnterAnimation,
    //  //fadeOutOnLeaveAnimation,
    //  homeTransition,
    //  //fadeInOnEnterAnimation,
    //  //fadeOutOnLeaveAnimation
  ]
  //animations: [homeTransition],
  //host: {
  //  '[@homeTransition]': ''
  //}
})
export class HomeComponent extends BaseComponent implements OnInit {
  filterSettings: FilterSettings;
  filterSettingsUpcomingEvents: FilterSettings;
  gridSettings: GridSettings;
  mySubscription: Subscription;

  profiles: Profile[];
  questions: Question[];
  questionId: number = 0;
  bannerShown: boolean = false;
  inviteForm!: UntypedFormGroup;

  @ViewChild('newItem') newItem: NewsItemsComponent

  constructor(
    private profileService: ProfileService,
    private questionService: QuestionService,
    private titleEventsEmitter: TitleEventsEmitter,
    private fb: UntypedFormBuilder,
    private modalService: NzModalService
  ) {
    super();

    this.mySubscription = interval(10000).subscribe((x => {
      this.doStuff();
    }));




    this.filterSettings = new FilterSettings();
    this.filterSettings.gender = 0;
    this.filterSettings.distance = 0;

    this.filterSettingsUpcomingEvents = new FilterSettings();
    this.filterSettingsUpcomingEvents.gender = 0;
    this.filterSettingsUpcomingEvents.distance = 0;

    this.filterSettingsUpcomingEvents.updateDates(moment(new Date()).set('hour', 0).toDate(), moment().add('d', 10000).set('hour', 23).set('minute', 59).toDate());
    this.filterSettingsUpcomingEvents.forPublic = true;

    this.gridSettings = new GridSettings();
    this.gridSettings.pageSize = 8;
    this.gridSettings.pageIndex = 0;
    this.gridSettings.sortField = "LastLogin";
    this.gridSettings.sortAsc = false;

    //this.form = fb.group({
    //    newPassword: ['', Validators.required],
    //    confirmPassword: ['', Validators.required]
    //}, {
    //        validator: PasswordValidation.MatchPassword // your validation method
    //    })
  }



  ngOnInit() {
    console.log(this.currentProfile, 'currnepro...')
    if (localStorage.getItem("bannerShown") != "true") {
      this.bannerShown = false;
    } else {
      this.bannerShown = true;
    }
    if (this.currentProfile) {
      this.filterSettingsUpcomingEvents.profileId = this.currentProfile.id;
      this.profileService.Get(this.currentProfile.id).subscribe(data => { //update from api
        this.currentProfile = data;
        localStorage.setItem('currentProfile', JSON.stringify(data));
      });
      this.setLoading(true);
      this.titleEventsEmitter.broadcastEventTitleChanged("Home");

      if (this.currentProfile) {
        this.filterSettings.profileId = this.currentProfile.id;
        this.questionService.GetForFilterSettings(this.filterSettings, 0, 100, "ViewOrder", 1).subscribe(questions => {
          this.questions = questions;
          this.questionId = this.questions[Math.floor(this.questions.length * Math.random())].id;
          this.loadData();
        });
      } else {
        this.setLoading(false);
      }
    }

  }

  doStuff() {
    if (localStorage.getItem("bannerShown") != "true") {
      localStorage.setItem("bannerShown", "true")
    } else {

    }
    this.mySubscription.unsubscribe();
  }

  loadData() {
    this.setLoading(true);
    this.filterSettings.profileId = this.currentProfile.id;
    this.filterSettings.showApproved = true;
    this.profileService.GetForFilterSettingsPublic(this.filterSettings, this.gridSettings.pageIndex, this.gridSettings.pageSize, this.gridSettings.sortField, this.gridSettings.sortAsc ? 1 : -1).subscribe(data => {

      this.profiles = data;
      console.log(this.profiles);
      //this.profiles = this.profiles.filter(r => r.subscriptionStatus == 2);
      this.setLoading(false);


    });
  }

  onFilterUpdated($event) {
    if ($event != null) {
      this.filterSettings = $event;
      this.loadData();
    }
  }

  onQuestionUpdated() {
    //if ($event != null) {
    //  this.filterSettings = $event;
    //  this.loadData();
    //}
  }

  openCommentModal(): void {
    const modal = this.modalService.create({
      nzTitle: 'Add New Comment',
      nzContent: CommentModalComponent,
      nzFooter: null,
      nzWidth: 800
    });

    modal.afterClose.subscribe(result => {
      this.newItem.load();
      if (result) {

        // this.handleImageUploads(result.images);
      }
    });
  }

  handleImageUploads(images: any[]): void {
    // Implement your image upload logic here
    // This could involve sending the files to a server or storing them locally
    console.log('Uploading images:', images);
  }

}
