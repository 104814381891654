import { Profile } from "./Profile";

export class Media {

  id: number;
  tribeId: number;
  title: string;
  description: string;
  location: string;
  filename: string;
  fileExtension: string;
  ref: string;
  mediaType: number;
  mediaTypeId: number;
  viewOrder: number;
  status: number;
  createdBy: number;
  updated: Date;
  created: Date;

  createdByProfile: Profile;

}
