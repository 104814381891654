import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProfileService } from '../../core/services/profile.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../core/services/authentication.service';
import { Profile } from '../../models/Profile';
import { LoginEventsEmitter } from '../../core/events/login.events';
import { BaseComponent } from '../shared/base-component/base-component.component';
import { GlobalService } from '../../core/services/global.service';

@Component({
  selector: 'app-invite',
  styleUrls: ['./invite.component.css'],
  templateUrl: './invite.component.html',
})
export class InviteComponent extends BaseComponent implements OnInit, OnDestroy {
  //public profile: Profile = new Profile();
  returnUrl: string;
  validateForm!: UntypedFormGroup;
  inviteCount: number;



  constructor(
    router: Router,
    globalService: GlobalService,
    private route: ActivatedRoute,
    private profileService: ProfileService,
    private fb: UntypedFormBuilder,
    authenticationService: AuthenticationService,
    //authenticationService: AuthenticationService,
    //private alertService: AlertService,
    //private ngProgress: NgProgress,
    private loginEventsEmitter: LoginEventsEmitter
  ) {
    super();
  }

  ngOnInit() {
    this.validateForm = this.fb.group({
      emailAddress: [null, [Validators.required]],
  
 
    });
    this.profileService.GetInviteCount(this.currentProfile.id).subscribe(data => {
      this.inviteCount = 8 - data;


    

    });
    
  }

  submitForm(): void {
    //  this.authenticationService.login(this.validateForm.controls['emailAddress'].value, this.validateForm.controls['password'].value).subscribe(
    //    data => {
    //      let mProfile: Profile = this.authenticationService.getCurrentProfile();
    //      if (mProfile != null && mProfile.token) {
    //        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    //        this.loginEventsEmitter.broadcastEventProfileLoginChanged(mProfile);
    //        this.router.navigate([this.returnUrl]);
    //      }
    //    },
    //    error => {

    //    });
    //}




    ////for (const i in this.validateForm.controls) {
    ////  this.validateForm.controls[i].markAsDirty();
    ////  this.validateForm.controls[i].updateValueAndValidity();
    ////}
  }

  emailAddressExists(emailAddress: any) {
    if (emailAddress) {
      //console.log(emailAddress.model);
      return false;
    } else {
     
      return false;
    }
  }

  onEmailAddressChanged($event) {
   
  }


}
