import { GlobalService } from "../../../core/services/global.service";
import { OnInit, Component, OnDestroy, EventEmitter, Output } from "@angular/core";
import { Router, NavigationStart, NavigationEnd, NavigationCancel } from "@angular/router";
import { AuthenticationService } from "../../../core/services/authentication.service";
import { interval, Subscription } from "rxjs";
import { take } from "rxjs/operators";
import * as moment from 'moment';
import { Profile } from "../../../models/Profile";
import { animations } from "ack-angular-fx";
import { AppInjector } from "../../../core/services/app-injector.service";
import { NzMessageService } from "ng-zorro-antd/message";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ANALYTICS } from '../../../analyticsConstants';
import { AuthService } from "../../../core/services/auth2.service";


@Component({
  template: '',
  animations: animations
})
export abstract class BaseComponent implements OnInit, OnDestroy {
  @Output() onLoaded = new EventEmitter<any>();

  public subscriptions: Subscription[] = [];
  public currentProfile: Profile;

  public isLoading: boolean;
  public isNavigating: boolean;
  public isMobile: boolean;

  public router: Router;
  public globalService: GlobalService;
  public authenticationService: AuthenticationService;
  public authService: AuthService;
  public msgService: NzMessageService;
  private notificationService: NzNotificationService;
  public gaService: GoogleAnalyticsService
  public analytics = ANALYTICS


  constructor(
  ) {
    this.router = AppInjector.injector.get(Router);
    this.globalService = AppInjector.injector.get(GlobalService);
    this.authenticationService = AppInjector.injector.get(AuthenticationService);
    this.msgService = AppInjector.injector.get(NzMessageService);
    this.notificationService = AppInjector.injector.get(NzNotificationService);
    this.gaService = AppInjector.injector.get(GoogleAnalyticsService);
    this.authService = AppInjector.injector.get(AuthService)


    this.registerNavHandler();
    this.currentProfile = this.authService.currentProfileValue;

    this.isLoading = true;
  }

  ngOnInit() {
    this.onResize();
    window.onresize = () => this.onResize();

  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => { subscription.unsubscribe(); });
    this.subscriptions = [];
  }

  registerNavHandler() {
    this.subscriptions.push(
      this.globalService.navigating.subscribe(event => {
        if (event === true) {
          this.setNavigating(true);
        } else {
        }
      })
    );

    this.subscriptions.push(
      this.router.events
        .subscribe((event) => {
          if (event instanceof NavigationStart) {
            this.setNavigating(true);
            //this.isNavigating = true;
          }
          else if (
            event instanceof NavigationEnd ||
            event instanceof NavigationCancel
          ) {
            this.setNavigating(false);
          }
        })
    );
  }

  setLoading(isLoading: boolean, msg: string = null) {
    this.isLoading = isLoading;
    if (this.isLoading) {
      this.globalService.startProgress();
    } else {
      this.onLoaded.next();
      this.globalService.completeProgress(msg);
    }
  }

  setNavigating(isNavigating: boolean) {
    if (this.currentProfile && ((this.currentProfile.subscriptionStatus != 2) || moment(this.currentProfile.subscriptionExpiry, 'MM/D/YYYY').isAfter(moment(Date.now(), 'MM/D/YYYY')))) {
    }
    this.isNavigating = isNavigating;
  }

  nav(url) {
    if (this.router.url != url) {
      this.globalService.startNavigating();
      interval(250).pipe(take(1)).subscribe(x => {
        //if (this.currentProfile && ((this.currentProfile.subscriptionStatus != 2) || moment(this.currentProfile.subscriptionExpiry, 'MM/D/YYYY').isAfter(moment(Date.now(), 'MM/D/YYYY')))) {
        //  if (this.currentProfile.userLevel != 3) {
        //    if (this.router.url.toString().indexOf("subscription") < 0) {
        //      this.router.navigate(['/account/subscription']);
        //    }
        //  }
        //} else {
        //  this.router.navigateByUrl(url);//, state);
        //}
        this.router.navigateByUrl(url);//, state);
      });
    }
  }

  onResize() {
    this.isMobile = window.innerWidth <= 767;

  }

  //success/info/warning/error
  notify(type: string, msg: string, title: string = ""): void {

    this.notificationService.create(type, (title?.length > 0 ? title : this.globalService.capitalize(type)), msg);
  }

}
