import { Component, OnInit, OnDestroy, Input, ViewChild, Injectable, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ProfileService } from '../../core/services/profile.service';
import { AuthenticationService } from '../../core/services/authentication.service';
import { Profile } from '../../models/Profile';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Configuration } from '../../app.constants';
import { UntypedFormGroup } from '@angular/forms';
import { Location } from '@angular/common'
import { QuestionService } from '../../core/services/question.service';
import { ProfileQuestionsComponent } from '../shared/profile/profile-questions/profile-questions.component';
import { TitleEventsEmitter } from '../../core/events/title.events';
import { MediaService } from '../../core/services/media.service';
import { Media } from '../../models/Media';
import { HttpRequest, HttpClient, HttpResponse, HttpEventType } from '@angular/common/http';
import { filter } from 'rxjs/operators';
import { BaseComponent } from '../shared/base-component/base-component.component';
import { Uploads } from '../../models/shared/upload/Uploads';
import { NgbCalendar, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ImageCroppedEvent, base64ToFile } from 'ngx-image-cropper';

@Injectable()
export class CustomDateParserFormatter {
  parse(value: string): NgbDateStruct {
    if (!value)
      return null
    let parts = value.split('/');
    return { year: +parts[0], month: +parts[1], day: +parts[2] } as NgbDateStruct

  }
  format(date: NgbDateStruct): string {

    return date ? ('0' + date.day).slice(-2) + "/" + ('0' + date.month).slice(-2) + "/" + date.year : null
  }
}




@Component({
  selector: 'account-component',
  templateUrl: 'account.component.html',
  styleUrls: ['account.component.css'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ]
})

export class AccountComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild("questions") questions: ProfileQuestionsComponent
  @ViewChild('FileSelectInputDialog') FileSelectInputDialog: ElementRef;
  @Input() step: number;
  @Input() questionsGroup: UntypedFormGroup;
  isNotified: boolean = false;
  public sub: any;
  public polarData: any;
  public header: string;
  public model: any = {};
  public mediaItems: Media[];
  isVisible = false;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  addon: string = "?ht=" + (Math.floor(Math.random() * 99999999));

  optionList = [
    { label: 'He/Him', value: 1 },
    { label: 'She/Her', value: 2 },
    { label: 'They/Them', value: 3 },
    { label: 'Ze/Hir', value: 4 }
  ];
  selectedValue = { label: 'He/Him', value: 1 };

  fileList: NzUploadFile[] = [
    {
      uid: '-1',
      name: 'image.png',
      status: 'done',
      url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
    },
    {
      uid: '-2',
      name: 'image.png',
      status: 'done',
      url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
    },
    {
      uid: '-3',
      name: 'image.png',
      status: 'done',
      url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
    },
    {
      uid: '-4',
      name: 'image.png',
      status: 'done',
      url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
    },
    {
      uid: '-5',
      name: 'image.png',
      status: 'error'
    }
  ];
  previewImage: string | undefined = '';
  previewVisible = false;
  
  profileId = null;
  generalNotifications: boolean;
  messageNotifications: boolean;
  textNotifications: boolean;
  model2: NgbDateStruct = { year: 2021, month: 1, day: 1 };
  model1: string;



  constructor(
    private profileService: ProfileService,
    private configuration: Configuration,
    //private alertService: AlertService,
    public questionService: QuestionService,
    private mediaService: MediaService,
    //private loaderService: LoaderService,
    //private ngProgress: NgProgress,
    //private titleEventsEmitter: TitleEventsEmitter,
    private titleEventsEmitter: TitleEventsEmitter,
    authenticationService: AuthenticationService,
    private msg: NzMessageService,
    private http: HttpClient,
    private location: Location,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super();
    //this.currentProfile = new Profile();
    //this.form = fb.group({
    //    newPassword: ['', Validators.required],
    //    confirmPassword: ['', Validators.required]
    //}, {
    //        validator: PasswordValidation.MatchPassword // your validation method
    //    })
  }

  ngOnInit() {
    super.ngOnInit();
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();


    this.model2 = { year: +yyyy, month: +mm, day: +dd };
    this.model2.day = +moment(this.currentProfile.dob).format("DD");
    this.model2.month = +moment(this.currentProfile.dob).format("MM");
    this.model2.year = +moment(this.currentProfile.dob).format("YYYY");
    this.titleEventsEmitter.broadcastEventTitleChanged("My Account");

   
    this.generalNotifications = this.currentProfile.generalNotifications;
    this.messageNotifications = this.currentProfile.messageNotifications;
    this.textNotifications = this.currentProfile.textNotifications;
    this.mediaItems = null;
    this.profileId = this.currentProfile.id

    if (this.profileId != null) {
      this.mediaService.GetByMediaType(1, this.profileId).subscribe(data5 => { //update from api

        this.mediaItems = data5;
        
        this.setLoading(false);
      });
    }
  }

  ngAfterViewInit() {
    for (var option of this.optionList) {
      if (option.value == this.currentProfile.gender) {
        this.selectedValue = option;
      }
    }
  }

  public goBack() {
    window.history.go(-1);
  }

  public OpenAddFilesDialog() {
    const e: HTMLElement = this.FileSelectInputDialog.nativeElement;
    e.click();
  }

  public refreshProfile() {
    //this.ngProgress.start();

    this.profileService
      .Get(this.currentProfile.id)
      .subscribe(
        data => {
          localStorage.setItem('currentProfile', JSON.stringify(data));
          this.currentProfile = data;
          //this.loaderService.hide();
          //this.ngProgress.complete();
        },
        error => console.log(error),
        () => { }
      );
    this.setLoading(false);
  }

  public updateProfile() {
    //this.ngProgress.start();
    this.profileService
      .Update(this.currentProfile)
      .subscribe(
        data => {
          //this.alertService.success("Your password was updated successfully");
          localStorage.setItem('currentProfile', JSON.stringify(data));
          //this.ngProgress.complete();
        },
        error => {
        });
  }

  public updatePassword() {
    //this.ngProgress.start();
    //if (this.model.newPassword != this.model.confirmPassword) {
    //    this.alertService.error("Please ensure both password match");
    //}
    //else {
    //    this.authenticationService.updatePassword(this.profile.profileID, this.model.oldPassword, this.model.newPassword)
    //        .subscribe(
    //        data => {
    //            this.alertService.success("Your password was updated successfully");
    //            this.loaderService.hide();
    //            this.model = {};
    //        },
    //        error => {
    //            if (error.status == 404) {
    //                this.alertService.error("Account not found");
    //            } else if (error.status == 401) {
    //                this.alertService.error("The old password is incorrect.");
    //            }
    //            else {
    //                this.alertService.error("Error reseting password");
    //            }
    //            this.ngProgress.complete();
    //        });
    //}
  }

  beforeUpload = (file: NzUploadFile, _fileList: NzUploadFile[]) => {
    //return new Observable((observer: Observer<boolean>) => {
    //  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    //  if (!isJpgOrPng) {
    //    this.msg.error('You can only upload JPG file!');
    //    observer.complete();
    //    return;
    //  }
    //  const isLt2M = file.size! / 1024 / 1024 < 2;
    //  if (!isLt2M) {
    //    this.msg.error('Image must smaller than 2MB!');
    //    observer.complete();
    //    return;
    //  }
    //  observer.next(isJpgOrPng && isLt2M);
    //  observer.complete();
    //});
  };

  handleChange(info: { file: NzUploadFile }): void {
    //this.imageChangedEvent = info.file;
    this.setLoading(true);
    this.isLoading = true;


    switch (info.file.status) {
      case 'uploading':
        if (!this.isNotified) {
          this.msg.loading('Uploading image, please wait...');
          this.isNotified = true;
        }
        break;
      case 'done':
        // Get this url from response in real world.
        //this.getBase64(info.file!.originFileObj!, (img: string) => {
        //  this.isLoading = false;
        //  this.avatarUrl = img;
        //});
        this.msg.success('Image Uploaded!!');
        this.currentProfile.imageUrl = null;
        this.isNotified = false;
        this.refreshProfile();
        break;
      case 'error':
        this.msg.error('Network error');
        this.setLoading(false);
        this.isNotified = false;
        this.isLoading = false;
        break;
    }

  }

  onChange(result: NgbDateStruct): void {

    this.currentProfile.dob = new Date(result.year + "-" + result.month + "-" + result.day + " " + ("0" + 0).slice(-2) + ":" + ("0" + 0).slice(-2));

  }

  getImageUrl = (p: Profile, size: number): string => { return this.globalService.getProfileImageUrl(p, size) + this.addon;  }

  handlePreview = async (file: NzUploadFile) => {
    //if (!file.url && !file.preview) {
    //  file.preview = await this.getBase64(file.originFileObj!);
    //}
    //this.previewImage = file.url || file.preview;
    //this.previewVisible = true;
  };

  //public delete() {
  //    this.router.navigate(['/profile/account/delete-confirm']);
  //}


  selectChange(value: string): void {
    this.currentProfile.gender = this.selectedValue.value;

  }

  submit() {
    this.setLoading(true);

    this.profileService.Update(this.currentProfile).subscribe(data => {
      this.currentProfile = data;
      localStorage.setItem('currentProfile', JSON.stringify(data));

      //this.globalService.completeProgress("Profile Updated");



      this.updateItems(this.currentProfile.id);
      //let updatedMediaItems = this.mediaItems.filter(x => x.filename.indexOf("original.jpg") > -1)
      //  this.mediaService.GetByMediaType(1, this.profileId).subscribe(data8 => { //update from api
      //    let existingMediaItems = data8;

      //    console.log(updatedMediaItems);
      //    console.log("ex", existingMediaItems);
      //    existingMediaItems.forEach(f => {

      //      if (updatedMediaItems.find(g => f)) {
      //        console.log(updatedMediaItems.indexOf(f));   
      //        //this.mediaService.Delete(f.id);
      //      }
      //    });
      //  });
      //  let newItems = this.mediaItems.filter(x => x.filename.indexOf("data:") > -1)
      //  if (this.mediaItems.length > 0) {
      //    this.setLoading(false);
      //  }

    });
  }

  savePreferences($event) {

    this.setLoading(true);

    this.currentProfile.generalNotifications = this.generalNotifications;
    this.currentProfile.textNotifications = this.textNotifications;
    this.currentProfile.messageNotifications = this.messageNotifications;
    console.log(this.currentProfile);
    console.log(this.generalNotifications);
    this.profileService.Update(this.currentProfile).subscribe(data => {
      this.currentProfile = data;
     
     localStorage.setItem('currentProfile', JSON.stringify(data));
     this.setLoading(false);
    });
  }

  processImages() {
    //let selectedMainImage = this.mainImageList.find(x => x.selected);
    //if (selectedMainImage && selectedMainImage.url?.indexOf("data") > -1) {
    //  Uploads.uploadBlob(this.http, selectedMainImage.url, '/v1/Event/uploadPhoto/' + this.item.id).subscribe(data => {
    //    this.item = (<any>data).body;
    //    this.updateItems(this.item.id);
    //  });
    //} else {
    //  this.updateItems(this.item.id);
    //}
  }

  updateItems(eventId: number) {
    if (this.mediaItems?.length > 0) {
      let count = this.mediaItems.length;
      this.mediaItems.forEach(x => {
        if ((<any>x).deleted && x.id > 0) {
          this.mediaService.Delete(x.id).subscribe(data3 => {
            count--;

          });
        } else if (!(<any>x).deleted && x.filename.indexOf("data:") > -1) {
          x.mediaTypeId = eventId;
          Uploads.uploadBlob(this.http, x.filename, '/v1/Media/uploadFile/' + 1 + '/' + eventId + '/' + this.currentProfile.id).subscribe(data => {
            x = (<any>data).body;
            count--;

          });
        }
      });
    }
    this.onFinishSaving();
  }

  onFinishSaving() {
    this.mediaItems = this.mediaItems.filter(x => !(<any>x).deleted);
    this.setLoading(false);
    this.msgService.info('Profile Saved', { nzDuration: 10000 });
    this.nav("/account");
  }


  goToSubscription() {
    this.router.navigateByUrl('/account/subscription');
  }




  //uploadNewItems(items: Media[], eventId: number) {
  //  let count = items.length;

  //  items.forEach(x => {
  //    //x.id = null;
  //    x.mediaTypeId = eventId;

  //    Uploads.uploadBlob(this.http, x.filename, '/v1/Media/uploadFile/' + 1 + '/' + eventId).subscribe(data => {
  //      count--;
  //      if (count == 0) {


  //        this.nav("/account");
  //      }
  //    });

  //  })
  //}

  onProfileUpdated($event) {
    localStorage.setItem('currentProfile', JSON.stringify($event));
    this.currentProfile = $event;
  }

  fileChangeEvent(event: any): void {
    this.isVisible = true;
    this.imageChangedEvent = event;
    console.log(event);
    this.setLoading(false);

  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    // Preview

  }
  imageLoaded(image?: HTMLImageElement) {
   
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    this.msg.error('Invalid file type. Must be JPG or PNG.');
  }

  handleOk(): void {
    this.setLoading(true);
    let fileToUpload = base64ToFile(this.croppedImage);
    const formData = new FormData();
    formData.append('file', fileToUpload, "avatar.jpg");

    this.http.post('/v1/Profile/uploadPhoto/' + this.currentProfile.id, formData, { reportProgress: true, observe: 'events' })
      .subscribe(event => {

        if (event.type === HttpEventType.Response) {

          this.isVisible = false;
          this.setLoading(false);
          this.msg.success('Image Uploaded!!');
          this.currentProfile.imageUrl = null;
          this.isNotified = false;
          this.refreshProfile();
        }
      });

  }
  cancelSubscription() {
    this.router.navigateByUrl('/account/manage-subscription');
  }

  handleCancel(): void {

    this.isVisible = false;
  }
}

//export class PasswordValidation {
//    static MatchPassword(AC: AbstractControl) {
//        let newPassword = AC.get('newPassword').value; // to get value in input tag
//        let confirmPassword = AC.get('confirmPassword').value; // to get value in input tag
//        if (newPassword != confirmPassword) {
//            AC.get('confirmPassword').setErrors({ MatchPassword: true })
//        } else {
//            return null
//        }
//    }
//}
