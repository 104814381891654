import { Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';
import { Profile } from '../../../../models/Profile';
import { interval } from 'rxjs';
import { Router } from '@angular/router';
import { ProfileService } from '../../../../core/services/profile.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { GlobalService } from '../../../../core/services/global.service';
import { QuestionAnswer } from '../../../../models/QuestionAnswer';
import { Question } from '../../../../models/Question';
import { BaseComponent } from '../../base-component/base-component.component';
import { zoomInOnEnterAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';
import { take } from 'rxjs/operators';
import { AuthenticationService } from '../../../../core/services/authentication.service';

@Component({
  selector: 'profile-list',
  templateUrl: './profile-list.component.html',
  styleUrls: ['./profile-list.component.css'],
  animations: [
    zoomInOnEnterAnimation(),
    fadeOutOnLeaveAnimation()
  ]
})
export class ProfileListComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() profiles: Profile[];
  @Input() questions: Question[];
  @Input() questionId: number = 0;

  isChangingQuestion: boolean = false;

  constructor(
    private profileService: ProfileService,
    private nzMessageService: NzMessageService,
  ) {
    super();
  }

  ngOnInit() {
   /* this.profiles = this.profiles.filter(x => x.status == 2)*/
    this.bindQuestion();
   
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (changes['questionId'] && changes['questionId'].previousValue != changes['questionId'].currentValue) {
      this.isChangingQuestion = true;
      interval(250).pipe(take(1)).subscribe(x => {
        this.bindQuestion();
        this.isChangingQuestion = false;
      });
    } else {
      this.bindQuestion();
    }
  }

  bindQuestion() {
    if (this.questions && this.profiles) {
      if (this.questionId == 0) {
        this.questionId = this.questions[Math.floor(this.questions.length * Math.random())].id;
      }
      this.profiles.forEach(p => this.populateRandomAnswer(p, this.questionId));
    }
  }

  populateRandomAnswer(p: Profile, questionId: number): void {
    const a: QuestionAnswer = p.questionAnswer.find(x => (questionId == 0 || x.questionId == questionId));
    p.questionAnswerText = a?.answer;
  }

  getImageUrl = (p: Profile): string => { return this.globalService.getProfileImageUrl(p, 256); }
}
