import { Component, OnInit, OnDestroy } from '@angular/core';
import { GridSettings } from '../../../models/shared/grids/gridSettings';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NewsItem } from '../../../models/NewsItem';
import { NewsItemService } from '../../../core/services/news-item.service';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Configuration } from '../../../app.constants';
import { GlobalService } from '../../../core/services/global.service';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { BaseComponent } from '../../shared/base-component/base-component.component';
import { Router } from '@angular/router';
import { Uploads } from '../../../models/shared/upload/Uploads';
import { FilterSettings } from '../../../models/shared/grids/filterSettings';

@Component({
  selector: 'admin-news-items',
  styleUrls: ['./news-items.component.css'],
  templateUrl: './news-items.component.html',
})
export class AdminNewsItemsComponent extends BaseComponent implements OnInit, OnDestroy {
  public items: NewsItem[];
  public item: NewsItem;
  filterSettings: FilterSettings;
  gridSettings: GridSettings;
  selectedValue = { label: "Active", value: 1 };
  incrementer = 0;
  total = 1;
  optionList = [
    { label: 'Active', value: 1 },
    { label: 'Inactive', value: 2 }
  ];
  addForm!: UntypedFormGroup;
  editForm!: UntypedFormGroup;
  public oldItem: NewsItem = null;
  previewImage: string | undefined = '';
  previewVisible = false;

  constructor(
    router: Router,
    globalService: GlobalService,
    authenticationService: AuthenticationService,
    private newsItemService: NewsItemService,
    private msg: NzMessageService,
    private configuration: Configuration,
    private fb: UntypedFormBuilder,
    private nzMessageService: NzMessageService
  ) {
    super();

    this.gridSettings = new GridSettings();
    this.gridSettings.sortField = "Created";
    this.gridSettings.sortAsc = false;
    this.gridSettings.pageSize = 50;
    this.gridSettings.pageIndex = 0;
    this.filterSettings = new FilterSettings();
  }

  ngOnInit() {
    this.currentProfile = this.authenticationService.getCurrentProfile();
    this.load();
  }

  load() {
    this.newsItemService.GetForFilterSettings(this.filterSettings, this.gridSettings.pageIndex, this.gridSettings.pageSize, this.gridSettings.sortField, this.gridSettings.sortAsc ? 1 : -1).subscribe(data => {
      this.items = data;
      this.setLoading(false);

      if (!this.item) {
        this.addForm = this.fb.group({
          formLayout: ['inline'],
          title: [null, [Validators.required]]
        });
      } else {
        this.item = this.items.find(x => x.id == this.item.id);
        this.oldItem = this.item;
        if (this.item.status == 1) {
          this.selectedValue = { label: 'Active', value: 1 };
        } else {
          this.selectedValue = { label: 'Inactive', value: 2 };
        }
        this.editForm = this.fb.group({
          //formLayout: ['inline'],
          title: [this.item.title, [Validators.required]],
          description: [this.item.description, [Validators.required]],
          link: [this.item.link, [Validators.required]],
          status: [this.item.status, [Validators.required]]
        });
        //if (this.oldItem != null) {
        // 
        //}

      }
      //console.log("BU", JSON.stringify(this.item));
    });
  }


  edit(id: number) {
    this.setLoading(true);

    this.item = this.items.find(x => x.id == id);
    this.load();
  }

  drop(event: CdkDragDrop<string[]>): void {
    //console.log(this.items, event.previousIndex, event.currentIndex);
    //console.log(this.items.map(x => x.title));

    //let remaining = this.items.length;
    //for (let i = 0; i < this.items.length; i++) {
    //  if (i == event.previousIndex) {
    //    this.items[i].viewOrder = event.currentIndex;
    //  } else if (i == event.currentIndex) {
    //    this.items[i].viewOrder = event.previousIndex;
    //  } else {
    //    this.items[i].viewOrder = i;
    //  }
    //  this.interestService.Update(this.items[i]).subscribe(data => {
    //    remaining--;
    //    if (remaining == 0) {
    //      this.loadData();
    //      this.nzMessageService.info('Updated order.');
    //    }
    //  });
    //}

    //moveItemInArray(this.listOfData, event.previousIndex, event.currentIndex);
  }

  add(): void {
    this.setLoading(true);

    //console.log(this.addForm.controls['title'].value);
    let item = new NewsItem();
    item.profileId = this.currentProfile.id;
    item.status = 2;
    item.itemType = 1;
    item.title = this.addForm.controls['title'].value;
    this.newsItemService.Add(item).subscribe(data => {
      this.load();
    });
    //for (const i in this.addForm.controls) {
    //  this.addForm.controls[i].markAsDirty();
    //  this.addForm.controls[i].updateValueAndValidity();
    //}
  }

  delete(item: NewsItem) {
    this.setLoading(true);

    this.newsItemService.Delete(item.id).subscribe(data => {
      this.nzMessageService.info('Deleted news item.');
      this.load();
    });
  }

  cancelDelete(): void {
    this.nzMessageService.info('Cancelled delete.');
  }

  checkHTTP() {
    if (this.item.link.indexOf("http://") == -1 && this.item.link.indexOf("https://") == -1) {
      this.item.link = "http://" + this.item.link
    }
  }

  save() {
    this.setLoading(true);

    this.newsItemService.Update(this.item).subscribe(data => {
      this.item = null;
      this.load();
    });
  }

  handlePreview = async (file: NzUploadFile) => {

    if (!file.url && !file.preview) {
      file.preview = await Uploads.getBase64Promise(file.originFileObj!);
    }
    this.previewImage = file.url || file.preview;
    this.previewVisible = true;
  };

  //getMediaUrl = (m: Media): string => { return this.globalService.getMediaUrl(m, 256); }

  beforeUpload() {
    //this.oldItem = this.item;
    //console.log("BU", JSON.stringify(this.item));
    // this.save();
  }

  handleChange(info: { file: NzUploadFile }): void {
    this.incrementer += 1;

    this.oldItem = this.item;

    //this.newsItemService.Update(this.item).subscribe(data => {
    //  this.item = data;
    //  this.setLoading(true);
    //  console.log("HC", JSON.stringify(this.item));
    switch (info.file.status) {
      case 'uploading':
        this.isLoading = true;
        break;
      case 'done':


        // Get this url from response in real world.
        //this.getBase64(info.file!.originFileObj!, (img: string) => {
        //  this.isLoading = false;
        //  this.avatarUrl = img;
        //});
        //this.isLoading = false;
        this.item.description = this.oldItem.description;
        this.item.title = this.oldItem.title;
        this.item.status = this.oldItem.status;
        this.item.link = this.oldItem.link;
        this.item.imageFilename = "image." + info.file.name.split(".")[1];
        this.oldItem = null;
        this.newsItemService.Update(this.item).subscribe(data => {
          this.item = data;
          this.load();
        });


        break;
      case 'error':
        this.msg.error('Network error');
        this.setLoading(false);
        break;
    }
    /*});*/

  }

  getImageUrl = (m: NewsItem, size: number): string => { return (this.globalService.getNewsItemUrl(m, size)).toString() + "?t=" + this.incrementer; }

  updateStatus(value): void {
    this.item.status = value;
  }


  setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
    if (this.isLoading) {
      this.globalService.startProgress();
    } else {
      this.globalService.completeProgress(null);
    }
  }
}

//interface DataItem {
//  name: string;
//  age: number;
//  address: string;
//}
