import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient } from "@angular/common/http";
import { AuthenticationService } from './authentication.service';
import { Configuration } from '../../app.constants';
import { Message } from '../../models/Message';
import * as signalR from '@microsoft/signalr';          // import signalR
import { Profile } from '../../models/Profile';

@Injectable()
export class MessageService {
  private isBrowser: boolean;
  private actionUrl: string;

  public currentProfile: Profile;

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    configuration: Configuration,
    private authenticationService: AuthenticationService
  ) {
    this.actionUrl = configuration.Server + 'v1/Message/';
    //http.get<WeatherForecast[]>(baseUrl + 'weatherforecast').subscribe(result => {
    //   this.forecasts = result;
    //}, error => console.error(error));
  }

  public GetAll = (keyword: string, row: number, pageSize: number, sortField: string, sortOrder: number): Observable<Message[]> => {
    let url = this.actionUrl + 'all?' + '&keyword=' + keyword + '&row=' + row + '&pageSize=' + pageSize + '&sortField=' + sortField + '&sortOrder=' + sortOrder;
    return this.http.post<Message[]>(url, '', this.authenticationService.getHttpOptions());
  }
  public GetAllCount = (keyword: string): Observable<number> => {
    return this.http.post<number>(this.actionUrl + 'allcount?keyword=' + keyword, '', this.authenticationService.getHttpOptions());
  }
  public Get = (id: number): Observable<Message> => {
    return this.http.post<Message>(this.actionUrl + id, "", this.authenticationService.getHttpOptions());
  }
  public Add = (item: Message): Observable<Message> => {
    return this.http.post<Message>(this.actionUrl, JSON.stringify(item), this.authenticationService.getHttpOptions());
  }
  public Update = (item: Message): Observable<Message> => {
    return this.http.put<Message>(this.actionUrl, JSON.stringify(item), this.authenticationService.getHttpOptions());
  }
  public Delete = (id: number): Observable<any> => {
    return this.http.delete(this.actionUrl + id, this.authenticationService.getHttpOptions());
  }

  public GetByDetails = (MessageType: number, GlobalObjectType: number, GlobalObjectId: number, ToProfileId: number, FromProfileId: number): Observable<Message[]> => {
    let url = this.actionUrl + 'byDetails?' + 'MessageType=' + MessageType + '&GlobalObjectType=' + GlobalObjectType + '&GlobalObjectId=' + GlobalObjectId + '&ToProfileId=' + ToProfileId + '&FromProfileId=' + FromProfileId;
    return this.http.post<Message[]>(url, '', this.authenticationService.getHttpOptions());
  }

  public GetUnreadCount = (ProfileId: number): Observable<number> => {
    let url = this.actionUrl + 'unreadCount?' + 'ProfileId=' + ProfileId ;
    return this.http.post<number>(url, '', this.authenticationService.getHttpOptions());
  }

  public GetInbox = (ProfileId: number): Observable<Message[]> => {
    let url = this.actionUrl + 'inbox?profileId=' + ProfileId;
    return this.http.post<Message[]>(url, '', this.authenticationService.getHttpOptions());
  }

  public GetThread = (toProfileId: number, fromProfileId: number): Observable<Message[]> => {
    let url = this.actionUrl + 'thread?toProfileId=' + toProfileId + '&fromProfileId=' + fromProfileId;
    return this.http.post<Message[]>(url, '', this.authenticationService.getHttpOptions());
  }

  public GetInboxDistinct = (ProfileId: number, pageIndex: number, pageSize: number, excludingProfileIds: number[]): Observable<Message[]> => {
    let url = this.actionUrl + 'inboxDistinct?profileId=' + ProfileId + '&pageIndex=' + pageIndex + '&pageSize=' + pageSize + '&excludingProfileIds=' + excludingProfileIds;
    return this.http.post<Message[]>(url, '', this.authenticationService.getHttpOptions());
  }

  public _messageHubConnection: signalR.HubConnection;
  public _eventHubConnection: signalR.HubConnection;


  public async initEventHubConnection2(eventId: number) {
    if (this._eventHubConnection) {
      this._eventHubConnection.stop();
    }
    //if (this._eventHubConnection == null) {
    this._eventHubConnection = new signalR.HubConnectionBuilder()
      .withUrl("/message-socket?user=" + "e" + eventId)
      .withAutomaticReconnect()
      .build();
    await this._eventHubConnection
      .start()
      .then(function () {
        console.log('connected');
      }).catch(function (err) {
        return console.log(err.toString());
      });
    // }
  }

  public async initMessageHubConnection2(profileId: number) {
    if (this._messageHubConnection == null) {
      //  this._messageHubConnection.stop();
      //}
      this._messageHubConnection = new signalR.HubConnectionBuilder()
        .withAutomaticReconnect()
        .withUrl("/message-socket?user=" + profileId)
        .build();
      await this._messageHubConnection
        .start()
        .then(function () {
         
        }).catch(function (err) {
          return console.error(err.toString());
        });
    }
  }

  ////Call client methods from hub to update User
  //this._hubConnection.on('UpdateInbox', (data) => {
  //  //var users = JSON.parse(onlineuser);
  //  //this.onlineUser = [];
  //  //for (var key in users) {
  //  //  if (users.hasOwnProperty(key)) {
  //  //    if (key !== this.loggedUsername) {
  //  //      this.onlineUser.push({
  //  //        userName: key,
  //  //        connection: users[key]
  //  //      });
  //  //    }
  //  //  }
  //  //}
  //  console.log('update', data);
  //});

  ////Call client methods from hub to update User
  //this._hubConnection.on('ReceiveMessage', (message: Message) => {
  //  //this.chatUsername = message.senderid;
  //  //this.chatLog();
  //  console.log('Msg', message)
  //});

  //Start Connection

  sendInboxUpdates(connection: signalR.HubConnection, updateProfileIds: number[]) {
    connection.invoke('SendInboxUpdates', updateProfileIds);
  }

  sendMessage(connection: signalR.HubConnection, message) {
    //Send Message
    //if (message != '') {
    //  this.message = new Message();
    //  this.message.senderid = this.loggedUsername;
    //  this.message.receiverid = this.chatUsername;
    //  this.message.message = message;
    //  this.message.messagestatus = "sent";
    //  this.messages.push(this.message);
    //  this._hubConnection.invoke('SendMessage', this.message);
    //}
    connection.invoke('SendMessage', message);
    //connection.invoke('ReceiveMessage', message)
  }
}
