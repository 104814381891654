import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

import { NewsItem } from '../../../models/NewsItem';
import { NewsItemService } from '../../../core/services/news-item.service';
import { GridSettings } from '../../../models/shared/grids/gridSettings';
import { NguCarouselConfig } from '@ngu/carousel';
import * as moment from 'moment';
import { animations } from "ack-angular-fx";
import { BaseComponent } from '../base-component/base-component.component';
import { FilterSettings } from '../../../models/shared/grids/filterSettings';

@Component({
  selector: 'news-items',
  templateUrl: './news-items.component.html',
  styleUrls: ['news-items.component.css'],
  animations: animations
})

export class NewsItemsComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() count: number = 10;
  @Input() itemType: number = 1;
  public items: NewsItem[];
  filterSettings: FilterSettings;
  gridSettings: GridSettings;

  public carouselTile: NguCarouselConfig = {
    grid: { xs: 1, sm: 2, md: 3, lg: 3, all: 0 },
    slide: 1,
    speed: 500,
    point: {
      visible: true
    },
    interval: {
      timing: 3000,
      initialDelay: 1000
    },
    velocity: 0,
    load: 2,
    loop: true,
    touch: true,
    easing: 'cubic-bezier(0, 0, 0.2, 1)'
  };

  constructor(
    private newsItemService: NewsItemService,
  ) {
    super();

    this.gridSettings = new GridSettings();
    this.gridSettings.sortField = "Created";
    this.gridSettings.sortAsc = false;
    this.gridSettings.pageSize = 50;
    this.gridSettings.pageIndex = 0;
    this.filterSettings = new FilterSettings();
    this.filterSettings.profileId = this.currentProfile.id;
    this.isLoading = true;
  }

  ngOnInit() {
    this.load();
  }

  ngOnChanges(changes: SimpleChanges) {
    //console.log('mc changes');
    //this.load();
    //this.doSomething(changes.categoryId.currentValue);
    // You can also use categoryId.previousValue and
    // categoryId.firstChange for comparing old and new values
  }

  load() {
    this.newsItemService.GetForFilterSettings(this.filterSettings, this.gridSettings.pageIndex, this.gridSettings.pageSize, this.gridSettings.sortField, this.gridSettings.sortAsc ? 1 : -1, this.itemType).subscribe(data => {
      this.items = data.filter(x => x.status == 1);
      this.isLoading = false;
    })
  }

  //onCarouselTileLoad(j) {
  //  // console.log(this.carouselTiles[j]);
  //  //const len = this.carouselTiles[j].length;
  //  //if (len <= 30) {
  //  //    for (let i = len; i < len + 15; i++) {
  //  //        this.carouselTiles[j].push(
  //  //            this.imgags[Math.floor(Math.random() * this.imgags.length)]
  //  //        );
  //  //    }
  //  //}
  //}

  getImageUrl = (m: NewsItem, size: number): string => { return this.globalService.getNewsItemUrl(m, size); }

  getTimeAgo = (d: Date): string => { return moment(d).fromNow(); }

}
