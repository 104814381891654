import { Directive, ElementRef, EventEmitter, Input, OnInit, OnDestroy, Output, Renderer2 } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IntersectionStatus, fromIntersectionObserver } from './from-intersection-observer';

//import { fromIntersectionObserver, IntersectionStatus } from './from-intersection-observer';

@Directive({
    selector: '[intersectionObserverAnimate]'
})
export class IntersectionObserverAnimateDirective implements OnInit, OnDestroy {
    @Input() intersectionDebounce = 0;
    @Input() intersectionRootMargin = '0px';
    @Input() intersectionRoot: HTMLElement;
    @Input() intersectionThreshold: number | number[];

    @Output() visibilityChange = new EventEmitter<any>();

    private destroy$ = new Subject();

    constructor(
        private element: ElementRef,
        private renderer: Renderer2
    ) { }

    ngOnInit() {
        let isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
        let iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        let iPad = /iPad/.test(navigator.userAgent) && !window.MSStream;
        //if (isSafari && iOS) {
        //   alert("You are using Safari on iOS!");
        //} else if (isSafari) {
        //   alert("You are using Safari.");
        //}

        const element = this.element.nativeElement;
        if (!iPad) {
            this.renderer.addClass(this.element.nativeElement, "animate-from-bottom");

            const config = {
                root: this.intersectionRoot,
                rootMargin: this.intersectionRootMargin,
                threshold: this.intersectionThreshold
            };

            fromIntersectionObserver(
                element,
                config,
                this.intersectionDebounce
            ).pipe(
                takeUntil(this.destroy$)
            ).subscribe((status) => {
                //this.visibilityChange.emit([element, status]);
                //let el: ElementRef = $event[0];
                //let is: IntersectionStatus = $event[1]
                //console.log(el, is);
                if (status == IntersectionStatus.Visible) {
                    this.renderer.addClass(element, 'visible');
                    //console.log('adding visible');
                } else if (status == IntersectionStatus.Pending) {
                    this.renderer.removeClass(element, 'visible');
                    //console.log('adding visible');
                }
                //this.visibilityStatus[index] = status;
            });
        } else {
            this.renderer.addClass(element, 'visible');
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
    }
}
