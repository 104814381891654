import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../core/services/authentication.service';
import { TitleEventsEmitter } from '../../core/events/title.events';
import { BaseComponent } from '../shared/base-component/base-component.component';
import { GlobalService } from '../../core/services/global.service';
import { ProfileService } from '../../core/services/profile.service';

@Component({
  selector: 'app-registration',
  styleUrls: ['./registration.component.css'],
  templateUrl: './registration.component.html',
})
export class RegistrationComponent extends BaseComponent implements OnInit, OnDestroy {

  public step: number = 1;

  constructor(
    private route: ActivatedRoute,
    private titleEventsEmitter: TitleEventsEmitter,
  private profileService: ProfileService
  ) {
    super();
  }

  ngOnInit() {



    this.titleEventsEmitter.broadcastEventTitleChanged("Registration");


    
    this.profileService
      .Get(this.currentProfile.id)
          .subscribe(
            data => {
              localStorage.setItem('currentProfile', JSON.stringify(data));
              if (this.currentProfile.status == 2) {
                this.router.navigate(['/']);
              }
            
             
            },
            error => console.log(error),
            () => { }
          );





    this.route.params.subscribe(params => {
      if (params['step'] && params['step'].length > 0) {
        this.currentProfile = this.authenticationService.getCurrentProfile();
       
        if (this.currentProfile) {
          this.step = parseInt(params['step']);
        } else {
          this.msgService.error('Invalid invitation');
          this.nav('/');
        }
      }
    });


  }

}
